import React, { useEffect, useRef, useState } from 'react'
import './Bankreport.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import close from "../../imgs/close.png";
import { Co2Sharp, Face6TwoTone, FaxTwoTone } from '@mui/icons-material';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Billprint from '../Billprint/Billprint';
import { useNavigate } from 'react-router-dom';
import Status from "../../imgs/statusmob.jpeg";
import * as XLSX from 'xlsx';
import { transliterate } from 'transliteration';

const Pregister = (props) => {
  const [input, setInput] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10), bankname: "", branchreport: "" });
  const inputRefs = useRef([]);
  const [DataSource, setDataSource] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [print, setprint] = useState(false);
  const cellRefs = useRef([]);
  const [value, setValue] = useState('');
  const notify = (msg) => toast(msg);
  const notifyerror = (msg) => toast.error(msg);
  const [customerData, setCustomerData] = useState([]);
  const [mobileusers, setMobileusers] = useState([]);
  const navigate = useNavigate();
  const refprint = useRef(null);
  const refprintclose = useRef(null);
  const [rtype, setRtype] = useState("1");
  const [showother, setShowother] = useState("Yes");
  const [wait, setWait] = useState(false);
  const currentDate = new Date();


  const handlertype = () => {
    if (rtype === "1") {
      setRtype("2");
    }
    else {
      setRtype("1");
    }
  }

  const handleprintclose = () => {
    refprintclose.current.click();
  }

  const handlePrint = () => {
    refprint.current.click();
  }

  const handleOtheractive = (event) => {
    var checked = event.target.checked;

    if (event.target.checked) {
      setShowother("Yes");
    }
    else {
      setShowother("No");
    }
  }

  useEffect(() => {
    const currentDate = new Date();
    const dayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let firstDayOfMonth;
    let lastDayOfMonth;

    if (localStorage.getItem("dur") === '10') {
      if (dayOfMonth >= 1 && dayOfMonth <= 10) {
        firstDayOfMonth = 1;
        lastDayOfMonth = 10;
      } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
        firstDayOfMonth = 11;
        lastDayOfMonth = 20;
      } else if (dayOfMonth >= 21 && dayOfMonth <= 30) {
        firstDayOfMonth = 21;
        lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
      }
    }
    else if (localStorage.getItem("dur") === '15') {
      if (dayOfMonth >= 1 && dayOfMonth <= 15) {
        firstDayOfMonth = 1;
        lastDayOfMonth = 10;
      } else if (dayOfMonth >= 16 && dayOfMonth <= 30) {
        firstDayOfMonth = 16;
        lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
      }
    }


    if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
      const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
      const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');
      setInput(prevInput => ({
        ...prevInput,
        date1: formattedDate1,
        date2: formattedDate2
      }));
    }

  }, []);

  useEffect(() => {
    const currentDate1 = new Date(input.date1);
    const dayOfMonth1 = currentDate1.getDate();
    const currentMonth1 = currentDate1.getMonth();
    const currentYear1 = currentDate1.getFullYear();

    let lastDayOfMonth1;

    if (localStorage.getItem("dur") === "10") {
      if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
        lastDayOfMonth1 = 10;
      } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
        lastDayOfMonth1 = 20;
      } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
        lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
      }
    }
    else if (localStorage.getItem("dur") === "15") {
      if (dayOfMonth1 >= 1 && dayOfMonth1 <= 15) {
        lastDayOfMonth1 = 15;
      } else if (dayOfMonth1 >= 16 && dayOfMonth1 <= 30) {
        lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
      }
    }


    if (lastDayOfMonth1 !== undefined) {
      const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
      setInput(prevInput => ({
        ...prevInput,
        date2: formattedDate2
      }));
    }
    setDataSource([]);
    setTableFilter([]);
  }, [input.date1]);


  const getbankreport = () => {
    setDataSource([]);
    setWait(true);
    var mainuser2 = jsEncode.encode("mamapr", 125);
    // if (input.bankname === "") {
    //   notifyerror("बँक नाव निवडा!");
    //   setWait(false);
    //   return;
    // }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      bankname: input.bankname,
      branch: input.branchreport,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/getbankreport.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {

      if (response.data && response.data.length > 0) {
        setDataSource(response.data);
      }
      else {
        notifyerror("या कालावधीचे सदर बँकेचे सभासद उपलब्ध नाहीत!");
      }
      setWait(false);
    }).catch(err => {
      console.log(err);
      setWait(false);
    });
  }

  useEffect(() => {
    getbanknames();
  }, [])

  
  const convert = () => {
    const marathiText = 'प्रशांत माळी';
    const englishText = transliterate(marathiText);
   

  }

  async function getbanknames() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    axios.post('https://idairy.co.in/dairy/php/cust/getbankname.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
      if (response.data) {

        setMobileusers(response.data);
      }

    }).catch(err => {
      console.log(err);
    });
  }

  const showprint = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);

    if (DataSource && DataSource.length > 0) {
      localStorage.setItem("date1print", input.date1);
      localStorage.setItem("date2print", input.date2);
      localStorage.setItem("bankname", input.bankname);
      localStorage.setItem("branch", input.branchreport);
      localStorage.setItem("mainuser", localStorage.getItem("contact"));
      localStorage.setItem("mainuser2", mainuser2);
      const url = "/bankreportprint";
      window.open(url, '_blank');
    }
    else {
      notifyerror("या कालावधीचे सदर बँकेचे सभासद उपलब्ध नाहीत!");
    }
  }

  const handleCellKeyPress = (e, rowIndex) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (rowIndex + 1 < cellRefs.current.length) {
        const nextRow = cellRefs.current[rowIndex + 1];
        nextRow[0].focus();
      }
      e.target.blur(); // Remove focus from the cell
    } else if (isNaN(e.key) && e.key !== 'Backspace') {
      e.preventDefault(); // Prevent non-numeric input
    }
  };

  const exportToExcelold = () => {
    const wb = XLSX.utils.table_to_book(document.getElementById('myTableexcel'), { sheet: "Sheet JS" });
    const ws = wb.Sheets["Sheet JS"];

    // Set column widths for specific columns (adjust the width values as needed)
    ws['!cols'] = [
        { wch: 20 },  // Width of first column
        { wch: 20 },
        { wch: 20 },  // Width for column 3 (account numbers)
        { wch: 8 },
        { wch: 40 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },  // Width of column 10
        { wch: 20 },
    ];

    const range = XLSX.utils.decode_range(ws['!ref']);

    // Format column 10 (index 9) to replace '/' with '-'
    for (let R = range.s.r; R <= range.e.r; ++R) {
        const cell = ws[XLSX.utils.encode_cell({ r: R, c: 9 })];  // Column 10 (index 9)
        if (cell && typeof cell.v === 'string') {
            cell.v = cell.v.replace(/\//g, '-');  // Replace all '/' with '-'
        }
    }

    // Prepend 'A' and ensure leading zeros are preserved for column 6 (index 5)
    for (let R = range.s.r; R <= range.e.r; ++R) {
        const cell = ws[XLSX.utils.encode_cell({ r: R, c: 5 })];  // Column 6 (index 5)
        if (cell) {
            const cellValue = cell.v.toString();  // Ensure the value is treated as a string
            if (!cellValue.startsWith('A')) {  // Avoid adding 'A' multiple times if re-exported
                cell.v = 'A' + cellValue;  // Prepend 'A' while keeping the value intact
            }
            cell.t = 's';  // Set the type to string
        }
    }

    // Ensure large numbers in column 3 (index 2) are treated as strings to avoid scientific notation
    for (let R = range.s.r; R <= range.e.r; ++R) {
        const cell = ws[XLSX.utils.encode_cell({ r: R, c: 2 })];  // Column 3 (index 2)
        if (cell) {
            cell.v = cell.v.toString();  // Convert the number to a string
            cell.t = 's';  // Set the type to string
        }
    }

    // Format the header row for styling
    for (let C = 0; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        if (ws[cellAddress]) {
            ws[cellAddress].s = ws[cellAddress].s || {};
            ws[cellAddress].s.fill = {
                patternType: "solid",
                fgColor: { rgb: "ADD8E6" }  // Light blue color
            };
            ws[cellAddress].s.font = { bold: true };
        }
    }

    // Save the file
    XLSX.writeFile(wb, 'Bank Report (' + input.date1 + " ते " + input.date2 + ").xlsx");
};

const exportToExcel = () => {
  const wb = XLSX.utils.table_to_book(document.getElementById('myTableexcel'), { sheet: "Sheet JS" });
  const ws = wb.Sheets["Sheet JS"];

  // Set column widths for specific columns (adjust the width values as needed)
  ws['!cols'] = [
      { wch: 20 },  // Width of first column
      { wch: 20 },
      { wch: 20 },  // Width for column 3 (account numbers)
      { wch: 8 },
      { wch: 40 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },  // Width of column 10
      { wch: 20 },
  ];

  const range = XLSX.utils.decode_range(ws['!ref']);

  // Format column 10 (index 9) to replace '/' with '-'
  for (let R = range.s.r; R <= range.e.r; ++R) {
      const cell = ws[XLSX.utils.encode_cell({ r: R, c: 9 })];  // Column 10 (index 9)
      if (cell && typeof cell.v === 'string') {
          cell.v = cell.v.replace(/\//g, '-');  // Replace all '/' with '-'
      }
  }

  // Prepend 'A' and ensure leading zeros are preserved for column 6 (index 5)
  for (let R = range.s.r; R <= range.e.r; ++R) {
      const cell = ws[XLSX.utils.encode_cell({ r: R, c: 5 })];  // Column 6 (index 5)
      if (cell) {
          let cellValue = cell.v.toString();  // Ensure the value is treated as a string
          if (!cellValue.startsWith('A')) {  // Avoid adding 'A' multiple times
              cell.v = `'A${cellValue}`;  // Add 'A' to the original value, force text format using single quote
          }
          cell.t = 's';  // Force the type to string
      }
  }

  // Ensure large numbers in column 3 (index 2) are treated as strings to avoid scientific notation
  for (let R = range.s.r; R <= range.e.r; ++R) {
      const cell = ws[XLSX.utils.encode_cell({ r: R, c: 2 })];  // Column 3 (index 2)
      if (cell) {
          cell.v = cell.v.toString();  // Convert the number to a string
          cell.t = 's';  // Set the type to string
      }
  }

  // Format the header row for styling
  for (let C = 0; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (ws[cellAddress]) {
          ws[cellAddress].s = ws[cellAddress].s || {};
          ws[cellAddress].s.fill = {
              patternType: "solid",
              fgColor: { rgb: "ADD8E6" }  // Light blue color
          };
          ws[cellAddress].s.font = { bold: true };
      }
  }

  // Save the file
  XLSX.writeFile(wb, 'Bank Report (' + input.date1 + " ते " + input.date2 + ").xlsx");
};


  function handleEnterKey(event, currentIndex) {

    if (event.keyCode === 8) {
      if (event.target.name === "time") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          time: '',
        }));
      }
      else if (event.target.name === "type") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          type: '',
        }));
      }
    }

    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault();
      let nextIndex = null;
      if (currentIndex === 2) {
        if (input.name !== "") {
          nextIndex = currentIndex + 1;
        }
        else {
          nextIndex = currentIndex;
        }
      }
      else {
        nextIndex = currentIndex + 1;
      }
      if (inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
  }

  const handleChange = async (event) => {
    const name = event.target.name;
    let valuecurrent = "";
    valuecurrent = event.target.value;
    setInput(value => ({ ...value, [name]: valuecurrent }));
  }

  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };

  return (
    <>

      <div className="sanklanmain">
        <div className="firstrowsank">
          <img onClick={props.visibility} className='back' src={back} alt="" />
          <div className="colmain">
            <div className="firstrow2">
              <div className="rowbill">
                <div className="mb-2">
                  <span className='subheadsank'>दिनांक</span>
                  <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                </div>

                <span className='subheadbill'>ते</span>

                <div className="mb-2">
                  <span className='subheadsank'>दिनांक</span>
                  <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                </div>

                <span className='subheadbill se'> || </span>

                <div className='hori '>
                  <div className="mb-2">
                    <span className='subheadsank'>बॅंकेचे नाव</span>
                    <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} className={"form-control"} autoComplete='off' id="bankname" name="bankname" value={input.bankname} onChange={handleChange}>
                      <option key={0} value={"बँक नाव निवडा"}>बँकेचे नाव निवडा</option>
                      {mobileusers ? mobileusers.map((opt, index) =>
                        <option key={index + 1} value={opt.bname}>{opt.bname}</option>
                      ) : ""}
                    </select>
                  </div>
                  <div className="mb-2">
                    <span className='subheadsank'>शाखा नाव</span>
                    <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} className={"form-control"} autoComplete='off' id="branchreport" name="branchreport" value={input.branchreport} onChange={handleChange}>
                      <option key={0} value={"बँक शाखा निवडा"}>बँक शाखा निवडा</option>
                      {mobileusers
                        ? mobileusers
                          .filter(opt => opt.bname === input.bankname)  // Filter the branches based on the selected bank
                          .map((opt, index) => (
                            <option key={index + 1} value={opt.branch}>{opt.branch}</option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>

                <div className="mb-2">
                  {

                    wait ? (
                      <button type="submit" id='show' className="btn btn-primary mx-1 sanksmall">लोडिंग..</button>
                    ) : (
                      <button onClick={getbankreport} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रिपोर्ट पहा</button>
                    )
                  }
                  <button onClick={exportToExcel} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">एक्सेल</button>
                  <button onClick={showprint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">प्रिंट</button>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="firstrow2">
          <div class="table-containerbill">
            <table id="myTablebill" className="table table-bordered">
              <thead>
                <tr>
                <th className='thsmall' scope="col">अ.क्र</th>
                  <th className='thsmall' scope="col">खाते क्रमांक</th>
                  <th className='thlarge' scope="col">नाव</th>
                  <th className='thlarge' scope="col">बँक नाव</th>
                  <th className='thlarge' scope="col">IFSC</th>
                  <th className='thlarge' scope="col">शाखा</th>
                  <th className='thlarge' scope="col">अकाउंट खाते क्रमांक</th>
                  <th className='thlarge' scope="col">देय रक्कम</th>
                </tr>
              </thead>
              <tbody>
                {DataSource && DataSource.map((user, index) =>
                  <tr key={index}>
                    <td className='thsmallbank'>{index + 1}</td>
                    <td className='thsmallbank'>{user.kno}</td>
                    <td className='thsmallbank'>{user.cname}</td>
                    <td className='thsmallbank'>{user.bankname}</td>
                    <td className='thsmallbank'>{user.ifsc}</td>
                    <td className='thsmallbank'>{user.branch}</td>
                    <td className='thsmallbank'>{user.accno}</td>
                    <td className='thsmallbank2'>{parseFloat(user.ada).toFixed(0)}</td>

                  </tr>
                )
                }
              </tbody>
              <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                <td className="thsmallbanktotal">एकूण</td>
                <td className="thsmallbanktotal">
                  -
                </td>
                <td className="thsmallbanktotal">
                  -
                </td>
                <td className="thsmallbanktotal">
                  -
                </td>
                <td className="thsmallbanktotal">
                  -
                </td>
                <td className="thsmallbanktotal">
                  -
                </td>
                <td className="thsmallbanktotal">
                  -
                </td>
                <td className="thsmallbanktotal">
                  {DataSource.reduce((total, user) => total + parseFloat(user.ada), 0).toFixed(0)}
                </td>

              </tr>
            </table>

            
            <div hidden>

            <table id="myTableexcel" className="table table-bordered">
              <thead>
                <tr>
                  <th className='thsmall' scope="col">PYMT_PROD_TYPE_CODE</th>
                  <th className='thlarge' scope="col">PYMT_MODE</th>
                  <th className='thlarge' scope="col">DEBIT_ACC_NO</th>
                  <th className='thlarge' scope="col">BNF_NO</th>
                  <th className='thlarge' scope="col">BNF_NAME</th>
                  <th className='thlarge' scope="col">BENE_ACC_NO</th>
                  <th className='thlarge' scope="col">BENE_IFSC</th>
                  <th className='thlarge' scope="col">AMOUNT</th>
                  <th className='thlarge' scope="col">CREDIT_NARR</th>
                  <th className='thlarge' scope="col">PYMT_DATE</th>
                  <th className='thlarge' scope="col">MOBILE_NUM</th>
                  <th className='thlarge' scope="col">EMAIL_ID</th>
                  <th className='thlarge' scope="col">REMARK</th>
                  <th className='thlarge' scope="col">REF_NO</th>
                </tr>
              </thead>
              <tbody>
                {DataSource && DataSource.map((user, index) =>
                  <tr key={index}>
                    <td className='thsmallbank'>{"PAB_VENDOR"}</td>
                    <td className='thsmallbank'>{user.bankname === "ICICI BANK" ? 'FT' : "NEFT"}</td>
                    <td className='thsmallbank'>{user.dairyaccno}</td>
                    <td className='thsmallbank'>{user.kno}</td>
                    <td className='thsmallbank'>{transliterate(user.cname)}</td>
                    <td className='thsmallbank'>{"A" + user.accno}</td>
                    <td className='thsmallbank'>{user.ifsc}</td>
                    <td className='thsmallbank'>{parseFloat(user.ada).toFixed()}</td>
                    <td className='thsmallbank'>{"Test Narration"}</td>
                    <td className='thsmallbank'>{currentDate.toLocaleDateString()}</td>
                    <td className='thsmallbank2'>{"-"}</td>
                    <td className='thsmallbank2'>{"-"}</td>
                    <td className='thsmallbank2'>{"-"}</td>
                    <td className='thsmallbank2'>{"-"}</td>
                  
                  </tr>
                )
                }
              </tbody>
             
            </table>

            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default Pregister

import React, { useEffect, useRef, useState,useContext } from 'react'
import back from "../../imgs/backexam.png";
import './Khpur.css'
import { ToastContainer, toast } from 'react-toastify';
import cash from "../../imgs/cash.png";
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';
import Khpurjma from '../Khpurjma/Khpurjma';

const Khpur = (props) => {

    const inputRefs = useRef([]);
    const [newedit, setNewedit] = useState("new");
    const [input, setInput] = useState({ date: new Date().toISOString().substr(0, 10), khname: "", khcompany: "", quin: "", rate: "", amt: "", kno: "", name: "" });
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [khnames, setKhnames] = useState([]);
    const [company, setCompany] = useState([]);
    const [value, setValue] = useState('');
    const { submenu, setsubmenu, newcustsource, setNewcustsource, custlist, setCustlist } = useContext(Globalcontext);
    const [stock, setStock] = useState();
    const { sanksource, setSanksource, setHeadname } = useContext(Globalcontext);
    const [stockcompany, setStockcompany] = useState();
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [sredit, setSredit] = useState('');
    const [partyData, setPartydata] = useState([]);
    const [mastermenu, setMastermenu] = useState(true);
    const [kopen, setkopen] = useState(0);
    const today = new Date().toISOString().split('T')[0]; 
    const [transitionEnded, setTransitionEnded] = useState(true);

    function handleEnterKey(event, currentIndex) {
        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }

        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा') ||
                (event.target.name === 'time' && event.target.value === 'वेळ निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }
    }

    useEffect(() => {
        if (input.kno === "") {
            if (newedit === 'new') {
                setInput(prevInput => ({
                    ...prevInput,
                    khname: "",
                    quin: "",
                    rate: "",
                    amt: "",
                    kno: "",
                    name: ""
                }))
                setSredit("");
                setNewedit("new");
            }
            else {
                setInput(prevInput => ({
                    ...prevInput,
                    name: ""
                }))
            }
        }
        else {

            let custlisttemp = partyData;
          //  console.log(custlisttemp);
            for (let i = 0; i < custlisttemp.length; i++) {
                setInput(prevInput => ({
                    ...prevInput,
                    name: ''
                }));
                const user = custlisttemp[i];
                if (user.kno === input.kno) {
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.name,
                    }));
                    break; // Exit the loop once the condition is met
                }
            }

        }
    }, [input.kno])

    async function getkhpartylist(flag) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getkhpartylist.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data.length > 0) {
                setPartydata(response.data);
                localStorage.setItem("dlist", JSON.stringify(response.data));
            
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (input.rate && input.rate !== "" && input.quin && input.quin !== "") {
            let amt = parseFloat(parseFloat(input.rate) * parseFloat(input.quin)).toFixed(2);
            setInput(prevInput => ({
                ...prevInput,
                amt: amt,
            }))
        }
    }, [input.rate, input.quin])


    const getstockcompany = async () => {
        if (input.khcompany) {
            company.map((user, index) =>
                user.khcompany === input.khcompany && user.khcompany !== "-" ?
                    setStockcompany(user.current_stock)
                    : ""
            )
        }
        getkhcompany();
    }

    async function getkhcompany() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        setTimeout(() => {
            props.setProgress(70);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getkhcompany.php', { khname: input.khname, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                setCompany(response.data);
            }

        }).catch(err => {
            console.log(err);
        });
    }



    const savekhadyapur = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.date === "" || input.khname === "" || input.quin === "" || input.rate === "" || input.amt === "" || input.kno === "" || input.name === "") {
            notifyerror("अपूर्ण एंट्री माहिती!");
            if (inputRefs.current[0]) {
                inputRefs.current[0].focus();
            }
            return;
        }
        let rawData = {
            date: input.date,
            kno: input.kno,
            khname: input.khname,
            khcompany: input.khcompany,
            quin: input.quin,
            rate: input.rate,
            amt: input.amt,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            flag: newedit,
            sredit: sredit,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/savekhpur.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (newedit === "new") {
                if (response.data.trim() === "Saved.") {
                    notify("एंट्री सेव्ह झाली!");
                }
            }
            else {
                if (response.data.trim() === "updated.") {
                    notify("एंट्री अपडेट झाली!")
                }
            }
            setInput(prevInput => ({
                ...prevInput,
                kno: "",
                name: "",
                khname: "",
                khcompany: "",
                quin: "",
                rate: "",
                amt: "",
            }))
            setStock(null)
            setSredit("");
            setNewedit("new");
            getkhpur();
            if (inputRefs.current[0]) {
                inputRefs.current[0].focus();
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handleedit = (sr, date, kno, name, khname, khcompany, quin, rate, amt) => {

        setInput(prevInput => ({
            ...prevInput,
            name: ""
        }))
        setInput({ sr: sr, date: date, kno: kno, khname: khname, khcompany: khcompany, quin: quin, rate: rate, amt: amt })
        setNewedit("edit");
        setSredit(sr);
    }

    async function deletesanklan(sr) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/deletekhpur.php', { sr: sr, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                notify(response.data);
                setInput(prevInput => ({
                    ...prevInput,
                    khname: "",
                    quin: "",
                    rate: "",
                    amt: "",
                }))
                setSredit("");
                setNewedit("new");
                getkhpur();
                if (inputRefs.current[0]) {
                    inputRefs.current[0].focus();
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    async function getkhname() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getkhname.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                setKhnames(response.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            event.preventDefault();
            savekhadyapur();
            let nextIndex = null;
            if (currentIndex === 0) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        else if (event.key === "Backspace") {
            if (event.target.tagName === "BUTTON") {
                if (inputRefs.current[2]) {
                    inputRefs.current[2].focus();
                }
            }
        }
    }

    useEffect(() => {
        getkhpur();
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, [])


    async function getkhpur() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getkhpur.php', { date: input.date, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                setDataSource(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
            getkhpartylist();
        }).catch(err => {
            console.log(err);
        });
        getkhname();
    }

    // const getstock = async () => {
    //     if (input.khname && input.khcompany === "") {
    //         khnames.map((user, index) =>
    //             user.khname === input.khname ?
    //                 setStock(user.current_stock)
    //                 : ""
    //         )
    //     }
    //     else if (input.khname && input.khcompany) {
    //         khnames.map((user, index) =>
    //             user.khname === input.khname && user.khcompany === input.khcompany ?
    //                 setStockcompany(user.current_stockbycompany)
    //                 : ""
    //         )
    //     }
    // }

    const getstock = async () => {
        if (input.khname) {
            khnames.map((user, index) =>
                user.khname === input.khname ?
                    setStock(user.current_stock)
                    : ""
            )
        }
        else {
            setStock("");
            setStockcompany("");
        }
        getkhcompany();
    }

    useEffect(() => {
        setStock("");
        setStockcompany("");
        setCompany([]);
        getkhcompany();

    }, [input.khname])

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

        if (name === 'date') {
            // If the value is blank or invalid, reset to the last valid date or today
            if (value === "" || value === "dd/mm/yyyy" || value > today) {
              setInput(prevInput => ({
                ...prevInput,
                date: prevInput.date && prevInput.date <= today ? prevInput.date : today
              }));
              return;
            }
          }
    }

    useEffect(() => {
        if (input.khname) {
            khnames.map((user, index) =>
                user.khname === input.khname ?
                    setStock(user.current_stock)
                    : setStock(null)
            )
        }
    }, [input.khname])


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).replace(/ /g, '-');  // Replacing spaces with hyphens
    };


    useEffect(() => {
        if (input.khcompany) {
            khnames.map((user, index) =>
                user.khname === input.khname && user.khcompany === input.khcompany ?
                    setStockcompany(user.current_stock)
                    : setStockcompany(null)
            )
        }
    }, [input.khcompany])

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    const visibility = () => {
        localStorage.setItem("glcustsr", "");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setsubmenu("");
        setHeadname("दैनंदिन व्यवहार");
    }

    const opendjma = (flag) => {
        setsubmenu("khpurjma");
        setHeadname("देयबाकी जमा");
     
    };

    return (
        <div className="khadyamain">
            <div className="firstrowkhadya">
                <img onClick={props.visibility} className='back' src={back} alt="" />
                <div className="colmainkhadya">
                    <div className="firstrow2">
                        <div className="rowsank">
                            <div className="mb-2">
                                <span className='subheadsank'>दिनांक</span>
                                <input  max={today} onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="date" name="date" value={input.date} onChange={handleChange} ></input>
                            </div>
                        </div>
                    </div>
                    <div onClick={opendjma} className="rightkhpur">
                        <img className='backratib' src={cash} alt="" />
                        <span className='subheadsankratib'>देयबाकी जमा</span>
                    </div>
                </div>

            </div>
            <div className='linesank' />

            <div className="row1khpur">
                <div className="mb-2">
                    <span className='subheadsank'>डिलर क्रमांक</span>
                    <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="number" className={newedit === "new" ? "form-control inputfontkh" : "form-control inputfontkh editmode"} autoComplete='off' id="kno" name="kno" value={input.kno} onChange={handleChange} ></input>
                </div>
                <div className="mb-2 hhhh">
                    <span className='subheadsank'>खाद्य डिलर चे नाव</span>
                    <input type="text" disabled className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="name" name="name" value={input.name} onChange={handleChange} ></input>
                </div>
            </div>

            <div className="row1khpur">
                <div className="mb-2">
                    <span className='subheadsank'>खाद्याचे नाव</span>
                    <input list='khnamelist' onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="khname" name="khname" value={input.khname} onChange={handleChange} onBlur={getstock}></input>
                    <datalist id="khnamelist">
                        {khnames &&
                            // Remove duplicates by creating a Set and then spreading it back into an array
                            [...new Set(khnames.map(opt => opt.khname))].map((uniqueCompany, index) => (
                                <option key={index} value={uniqueCompany}>{uniqueCompany}</option>
                            ))
                        }
                    </datalist>
                </div>

                <div className="mb-2">
                    <span className='subheadsank'>खाद्य कंपनी{stockcompany && (
                        <span className='lblredkh'> (शिल्लक स्टॉक : {stockcompany})</span>
                    )}</span>
                    <input list='khcompanylist' onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="khcompany" name="khcompany" value={input.khcompany} onChange={handleChange} onBlur={getstockcompany}></input>
                    <datalist id="khcompanylist">
                        {company &&
                            // Remove duplicates by creating a Set and then spreading it back into an array
                            [...new Set(company.map(opt => opt.khcompany))].map((uniqueCompany, index) => (
                                <option key={index} value={uniqueCompany}>{uniqueCompany}</option>
                            ))
                        }
                    </datalist>
                </div>

                <div className="mb-2 kkh">
                    <span className='subheadsank'>खरेदी नग</span>
                    <input onKeyDown={e => handleEnterKey(e, 4)} ref={ref => (inputRefs.current[4] = ref)} type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} autoComplete='off' id="quin" name="quin" value={input.quin} onChange={handleChange} ></input>
                </div>
                <div className="mb-2 kkh">
                    <span className='subheadsank'>दर</span>
                    <input onKeyDown={e => handleEnterKey(e, 5)} ref={ref => (inputRefs.current[5] = ref)} type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} autoComplete='off' id="rate" name="rate" value={input.rate} onChange={handleChange} ></input>
                </div>
                <div className="mb-2 kkh2 hhhh">
                    <span className='subheadsank'>एकूण</span>
                    <input disabled type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} autoComplete='off' id="amt" name="amt" value={input.amt} onChange={handleChange} ></input>
                </div>
            </div>

            <div className="mb-2">
                <button onClick={savekhadyapur} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[6] = ref)} type="submit" className="btn btn-primary mx-1 sanksmall">
                    {newedit === "new" ?
                        "सेव्ह" :
                        "अपडेट"
                    }
                </button>
                <button type="submit" className="btn btn-primary mx-1 sanksmall">खरेदी यादी</button>
            </div>

            <div className="firstrow2">
                <div class="table-containerkh">
                    <table id="myTablepur" className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='thsmall' scope="col">क्र.</th>
                                <th className='thsmall' scope="col">दिनांक</th>
                                <th className='thsmall' scope="col">डिलर</th>
                                <th className='thsmall' scope="col">खाद्य</th>
                                <th className='thsmall' scope="col">कंपनी</th>
                                <th className='thsmall' scope="col">नग</th>
                                <th className='thsmall' scope="col">दर</th>
                                <th className='thsmall' scope="col">एकूण</th>
                                <th className='thsmall' scope="col">एक्शन</th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.length > 0 ? tableFilter.map((user, index) =>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{user.date}</td>
                                    <td>{user.name}</td>
                                    <td>{user.khname}</td>
                                    <td>{user.khcompany}</td>
                                    <td>{user.quin}</td>
                                    <td>{user.rate}</td>
                                    <td>{user.amt}</td>

                                    <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.kno, user.name, user.khname, user.quin, user.rate, user.amt) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.sr) }} v className="icsank" /></td>
                                </tr>
                            )
                                :
                                DataSource.map((user, index) =>
                                    <tr key={index}>
                                        <td className='thsmall'>{index + 1}</td>
                                        <td className='thsmall'>{formatDate(user.date)}</td>
                                        <td className='thsmall'>{user.name}</td>
                                        <td className='thsmall'>{user.khname}</td>
                                        <td className='thsmall'>{user.khcompany}</td>
                                        <td className='thsmall'>{user.quin}</td>
                                        <td className='thsmall'>{user.rate}</td>
                                        <td className='thsmall'>{user.amt}</td>
                                        <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.kno, user.name, user.khname, user.khcompany, user.quin, user.rate, user.amt) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.sr) }} v className="icsank" /></td>

                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Khpur

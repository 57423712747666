import React, { useContext, useEffect, useRef, useState } from 'react'
import './Bill.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import close from "../../imgs/close.png";
import { CasinoSharp, Co2Sharp, Face6TwoTone, FaxTwoTone, Rowing } from '@mui/icons-material';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Billprint from '../Billprint/Billprint';
import { useFetcher, useNavigate } from 'react-router-dom';
import { UilEditAlt, UilTrash, UilSetting, UilRefresh } from '@iconscout/react-unicons'
import Globalcontext from '../../Context/Globalcontext';
import Status from "../../imgs/statusmob.jpeg";

const Bill = (props) => {

  const [input, setInput] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10), knofrom: "", knoto: "", knofromprint: "", knotoprint: "", ikapatnote: "कपात नोट", mobileuser: "", search: "1", name: "", billamt: "", tliter: "", thevkapat: "", madv: "", cadv: "", advkapat: "", newadv: "", mkhadya: "", ckhadya: "", khadyakapat: "", newkhadya: "", ikapatbill: "", vahtuk: "", ada: "" });
  const inputRefs = useRef([]);
  const [DataSource, setDataSource] = useState([]);
  const [kapat, setKapat] = useState({});
  const [tableFilter, setTableFilter] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [print, setprint] = useState(false);
  const [showUpdate, setShowupdate] = useState(false);
  const [first, setfirst] = useState("false");
  const [refresh, setRefresh] = useState("false");
  const cellRefs = useRef([]);
  const [value, setValue] = useState('');
  const notify = (msg) => toast(msg);
  const notifyerror = (msg) => toast.error(msg);
  //const [customerData, setCustomerData] = useState([]);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  let updatedDataSource = [];
  const [updatedDataSource2, setUpdatedDataSource2] = useState([]);
  const refdelete = useRef(null);
  const refdeleteclose = useRef(null);
  const refdeletesingle = useRef(null);
  const refdeletesingleclose = useRef(null);
  const reftrailprint = useRef(null);
  const reftrailprintclose = useRef(null);
  const refsearch = useRef(null);
  const refsearchclose = useRef(null);
  const [showthevcol, setShowthevcol] = useState("No");
  const [showother, setShowother] = useState("Yes");
  const [showthev, setShowthev] = useState("Yes");
  const [wait, setWait] = useState(false);
  const [wait2, setWait2] = useState(false);
  const [waitsave, setWaitsave] = useState(false);
  const [waitprinttest, setWaitprinttest] = useState(false);
  const [waitsearch, setWaitsearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const { customerData, setCustomerData } = useContext(Globalcontext);
  const refprint = useRef(null);
  const refprintclose = useRef(null);
  const [rtype, setRtype] = useState("1");
  const [count, setCount] = useState(10);
  const [isCounting, setIsCounting] = useState(false);
  const [kno1, setKno1] = useState(1);
  const [kno2, setKno2] = useState(2);
  const [isFetching, setIsFetching] = useState(false);
  const [mobileusers, setMobileusers] = useState([]);
  const [lastdate1, setLastdate1] = useState("");
  const [lastdate2, setLastdate2] = useState("");
  const today = new Date().toISOString().split('T')[0];
  const cancelRef = useRef(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  let index2 = 0;

  const handleIsfetching = () => {

    if (isFetching === "true") {
      setIsFetching("false");
    }
    else {
      setIsFetching("true");
    }
  }

  useEffect(() => {

    if (isFetching === "true") {
      getbill();
    }

  }, [isFetching])

  // useEffect(() => {
  //   console.log(input);
  // }, [input])


  const handlertype = () => {
    if (rtype === "1") {
      setRtype("2");
    }
    else {
      setRtype("1");
    }
  }

  async function getmobileusers() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    axios.post('https://idairy.co.in/dairy/php/cust/getmobileusers.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
      if (response.data) {
        setMobileusers(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }


  useEffect(() => {
    if (input.knofrom !== "1") {
      setInput((prevInputSettings) => ({
        ...prevInputSettings,
        knoto: input.knofrom,
      }));
    }
    else {
      getmaxno();
    }
  }, [input.knofrom])

  // useEffect(()=>{
  //   if(input.knofrom === input.knoto)
  //   {
  //     setRefresh("true");
  //   }
  //   else {
  //     setRefresh("false");
  //   }
  // },[input.knoto])

  const handleDelete = () => {
    refdelete.current.click();
  }

  const handledeleteclose = () => {
    refdeleteclose.current.click();
  }

  const handleDeletesingle = () => {
    refdeletesingle.current.click();
  }

  const handledeletesingleclose = () => {
    refdeletesingleclose.current.click();
  }

  const handlePrint = () => {
    refprint.current.click();
  }
  const handleprintclose = () => {
    refprintclose.current.click();
  }

  const handleprinttrial = () => {
    reftrailprint.current.click();
  }

  const handleprinttrialclose = () => {
    reftrailprintclose.current.click();
  }

  const handlesearch = () => {
    refsearch.current.click();
  }

  const handlesearchclose = () => {
    setWaitsearch(false);
    refsearchclose.current.click();
  }

  const fetchtabledata = () => {
    try {
      const table = document.getElementById('myTablebill');
      const rows = table.getElementsByTagName('tr');

      const data = [];

      for (let i = 1; i < rows.length; i++) { // Start from 1 to skip header row
        const row = rows[i];
        const cells = row.getElementsByTagName('td');

        const rowData = {
          // Assuming the order of cells matches your column order
          date1: input.date1,
          date2: input.date2,
          kno: cells[0].textContent,
          bill: cells[2].textContent,
          tliter: cells[3].textContent,
          magilthev: cells[4].textContent,
          cthev: cells[5].textContent,
          newthev: cells[6].textContent,
          madv: cells[7].textContent,
          cadv: cells[8].textContent,
          tadv: cells[9].textContent,
          advcut: cells[10].textContent,
          newadv: cells[11].textContent,
          mkhadya: cells[12].textContent,
          ckhadya: cells[13].textContent,
          tkhadya: cells[14].textContent,
          khadyacut: cells[15].textContent,
          newkhadya: cells[16].textContent,
          ikapatliter: cells[17].textContent,
          ikapatbill: cells[18].textContent,
          vah: cells[19].textContent,
          ada: cells[20].textContent,
        };
        data.push(rowData);
      }
      const insertQuery = `INSERT INTO bill (date1, date2, kno, billamt,magilthev,cthev,newthev,madv,cadv,tadv,advkapat,newadv,mkhadya,ckhadya,tkhadya,khadyakapat,newkhadya,ikapatliter,ikapatbill,vah,ada,mainuser) VALUES
      ${data.map(row => `('${row.date1}', '${row.date2}', '${row.kno}', '${row.bill}','${row.magilthev}','${row.cthev}','${row.newthev}','${row.madv}', '${row.cadv}', '${row.tadv}', '${row.advcut}','${row.newadv}','${row.mkhadya}', '${row.ckhadya}', '${row.tkhadya}', '${row.khadyacut}','${row.newkhadya}','${row.ikapatliter}','${row.ikapatbill}','${row.vah}','${row.ada}','${localStorage.getItem("contact")}')`).join(', ')}`;


      const { date1next, date2next } = calculateNextDates(input.date1, input.date2);

      const updateQuery = `
            UPDATE billtemp
            SET 
              madv = CASE
                ${data.map(row => `
                  WHEN kno = '${row.kno}' AND date1 = '${date1next}' AND date2 = '${date2next}' AND mainuser = '${localStorage.getItem("contact")}'
                  THEN '${row.newadv}'`).join('\n')}
              END,
              mkhadya = CASE
                ${data.map(row => `
                  WHEN kno = '${row.kno}' AND date1 = '${date1next}' AND date2 = '${date2next}' AND mainuser = '${localStorage.getItem("contact")}'
                  THEN '${row.newkhadya}'`).join('\n')}
              END,
              tadv = CASE
                ${data.map(row => `
                  WHEN kno = '${row.kno}' AND date1 = '${date1next}' AND date2 = '${date2next}' AND mainuser = '${localStorage.getItem("contact")}'
                  THEN (${row.newadv} + cadv)`).join('\n')}
              END,
              tkhadya = CASE
                ${data.map(row => `
                  WHEN kno = '${row.kno}' AND date1 = '${date1next}' AND date2 = '${date2next}' AND mainuser = '${localStorage.getItem("contact")}'
                  THEN (${row.newkhadya} + ckhadya)`).join('\n')}
              END
            WHERE kno IN (${data.map(row => `'${row.kno}'`).join(', ')})
              AND date1 = '${date1next}'
              AND date2 = '${date2next}'
              AND mainuser = '${localStorage.getItem("contact")}';
          `;

      if (input.knofrom === input.knoto) {
        savebillsingle(insertQuery, updateQuery, data);
      }
      else {
        savebill(insertQuery, updateQuery, data);
      }
    }
    catch {
    }
  }

  const savebill = (insertQuery, updateQuery, data) => {
    setWaitsave(true);
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (data.length === 0 || insertQuery === "" || input.date1 === "" || input.date2 === "" || input.knofrom === "" || input.knoto === "") {
      notifyerror("अपूर्ण एंट्री माहिती!");
      setWaitsave(false);
      return;
    }
    let rawData = {
      insertQuery: insertQuery,
      updateQuery: updateQuery,
      mainuser: localStorage.getItem("contact"),
      date1: input.date1,
      date2: input.date2,
      mainuser2: mainuser2,
    }

    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/savebill2.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {


      if (response.data.trim() === "Saved.") {
        notify("सभासद बील सेव्ह झाले!");
        setDataSource([]);
        setShowupdate(false);
        getbill();
        setWaitsave(false);
        getmaxno();
       
      }
    }).catch(err => {
      console.log(err);
    });
  }


  const savebillsingle = (insertQuery, updateQuery, data) => {
    setWaitsave(true);

    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (data.length === 0 || insertQuery === "" || input.date1 === "" || input.date2 === "" || input.knofrom === "" || input.knoto === "") {
      notifyerror("अपूर्ण एंट्री माहिती!");
      setWaitsave(false);
      return;
    }
    let rawData = {
      insertQuery: insertQuery,
      updateQuery: updateQuery,
      mainuser: localStorage.getItem("contact"),
      date1: input.date1,
      date2: input.date2,
      knofrom: input.knofrom,
      knoto: input.knoto,
      mainuser2: mainuser2,

    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/savebillsingle2.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      if (response.data.trim() === "Saved.") {
        notify("खाते क्रमांक " + input.knofrom + " चे सभासद बील रिफ्रेश झाले!");
        setDataSource([]);
        setWaitsave(false);
        getmaxno();
        setWait(false);
        setShowupdate(false);
        getbill();
      }
      else {
        setWait(false);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  function calculateNextDates(inputDate1, inputDate2) {
    const date1 = new Date(inputDate1);
    const date2 = new Date(inputDate2);

    let date1next, date2next;

    if (date1.getDate() === 1 && date2.getDate() === 10) {
      // Case: date1 is 'YYYY-MM-01' and date2 is 'YYYY-MM-10'
      date1next = new Date(date1.getFullYear(), date1.getMonth(), 11);
      date2next = new Date(date2.getFullYear(), date2.getMonth(), 20);
    } else if (date1.getDate() === 11 && date2.getDate() === 20) {
      // Case: date1 is 'YYYY-MM-11' and date2 is 'YYYY-MM-20'
      date1next = new Date(date1.getFullYear(), date1.getMonth(), 21);
      date2next = new Date(date2.getFullYear(), date2.getMonth() + 1, 0); // Last day of the current month
    } else if (date1.getDate() === 21 && date2.getDate() === new Date(date2.getFullYear(), date2.getMonth() + 1, 0).getDate()) {
      // Case: date1 is 'YYYY-MM-21' and date2 is the last day of the month
      date1next = new Date(date1.getFullYear(), date1.getMonth() + 1, 1); // First day of next month
      date2next = new Date(date1.getFullYear(), date1.getMonth() + 1, 10); // 10th day of next month
    } else {
      throw new Error("Unexpected input date range");
    }

    // Format the dates to 'YYYY-MM-DD'
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    return {
      date1next: formatDate(date1next),
      date2next: formatDate(date2next)
    };
  }

  function handleEnterKey(event, currentIndex) {

    if (event.keyCode === 8) {
      if (event.target.name === "time") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          time: '',
        }));
      }
      else if (event.target.name === "type") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          type: '',
        }));
      }
    }

    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault();
      let nextIndex = null;
      if (currentIndex === 2) {
        if (input.name !== "") {
          nextIndex = currentIndex + 1;
        }
        else {
          nextIndex = currentIndex;
        }
      }
      else {
        nextIndex = currentIndex + 1;
      }
      if (inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
  }

  useEffect(() => {
    const currentDate1 = new Date(input.date1);
    const dayOfMonth1 = currentDate1.getDate();
    const currentMonth1 = currentDate1.getMonth();
    const currentYear1 = currentDate1.getFullYear();

    let lastDayOfMonth1;

    if (localStorage.getItem("dur") === "10") {
      if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
        lastDayOfMonth1 = 10;
      } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
        lastDayOfMonth1 = 20;
      } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
        lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
      }
    }
    else if (localStorage.getItem("dur") === "15") {
      if (dayOfMonth1 >= 1 && dayOfMonth1 <= 15) {
        lastDayOfMonth1 = 15;
      } else if (dayOfMonth1 >= 16 && dayOfMonth1 <= 30) {
        lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
      }
    }

    if (lastDayOfMonth1 !== undefined) {
      const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
      setInput(prevInput => ({
        ...prevInput,
        date2: formattedDate2
      }));
    }
    setDataSource([]);
    setTableFilter([]);

  }, [input.date1]);


  useEffect(() => {
    const currentDate = new Date();
    const dayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let firstDayOfMonth;
    let lastDayOfMonth;

    console.log(localStorage.getItem("lastbilldate"))
    if (localStorage.getItem("lastbilldate") !== null) {
      firstDayOfMonth = localStorage.getItem("lastbilldate");
    }


    if (localStorage.getItem("dur") === '10') {
      if (dayOfMonth >= 1 && dayOfMonth <= 10) {
        firstDayOfMonth = 1;
        lastDayOfMonth = 10;
      } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
        firstDayOfMonth = 11;
        lastDayOfMonth = 20;
      } else if (dayOfMonth >= 21 && dayOfMonth <= 30) {
        firstDayOfMonth = 21;
        lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
      }
    }
    else if (localStorage.getItem("dur") === '15') {
      if (dayOfMonth >= 1 && dayOfMonth <= 15) {
        firstDayOfMonth = 1;
        lastDayOfMonth = 15;
      } else if (dayOfMonth >= 16 && dayOfMonth <= 30) {
        firstDayOfMonth = 16;
        lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
      }
    }


    if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
      const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
      const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');
      setInput(prevInput => ({
        ...prevInput,
        date1: formattedDate1,
        date2: formattedDate2
      }));
    }

    getmobileusers();

    getmaxno();
  }, []);

  const handleCellKeyPress12 = (e, rowIndex) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (rowIndex + 1 < cellRefs.current.length) {
        const nextRow = cellRefs.current[rowIndex + 1];
        if (nextRow && nextRow[1]) { // Use nextRow[1] for column 12
          nextRow[1].focus();
        }
      }
      e.target.blur(); // Remove focus from the cell
    } else if (e.key !== 'Backspace' && e.key !== '-' && !/Arrow(Left|Right)/.test(e.key)) {
      // Prevent non-numeric input
      if (isNaN(e.key) && e.key !== '.') {
        e.preventDefault();
      }
    }
  };

  const handleChangeactive = (event) => {
    var checked = event.target.checked;

    if (event.target.checked) {
      setShowthevcol("Yes");
    }
    else {
      setShowthevcol("No");
    }
  }

  const handleOtheractive = (event) => {
    var checked = event.target.checked;

    if (event.target.checked) {
      setShowother("Yes");
    }
    else {
      setShowother("No");
    }
  }

  const handlethev = (event) => {
    var checked = event.target.checked;

    if (event.target.checked) {
      setShowthev("Yes");
    }
    else {
      setShowthev("No");
    }
  }


  const showprint = async () => {

    if (DataSource[0].flag !== "newbill") {
      refprintclose.current.click();
      setWait2(true);
      var mainuser2 = jsEncode.encode("mamapr", 125);

      try {
        const response = await axios.post('https://idairy.co.in/dairy/php/cust/getbillprint3.php', {
          mainuser: localStorage.getItem('contact'),
          date1: input.date1,
          date2: input.date2,
          mainuser2: mainuser2,
          knofrom: input.knofromprint,
          knoto: input.knotoprint,
          mobileuser: input.mobileuser,
          billtype: DataSource[0].flag,
          bcount: DataSource[0].bcount
        });

        if (DataSource && DataSource.length > 0) {
          //   console.log(response.data);
          localStorage.setItem("date1print", input.date1);
          localStorage.setItem("date2print", input.date2);
          localStorage.setItem("billtype", DataSource[0].flag);
          localStorage.setItem("bcount", DataSource[0].bcount);
          localStorage.setItem("customerData", JSON.stringify(response.data));
          localStorage.setItem("kapat", JSON.stringify(kapat));
          localStorage.setItem("showother", showother);
          localStorage.setItem("showthev", showthev);
          localStorage.setItem("design", rtype);
          if (input.ikapatnote !== "कपात नोट" && input.ikapatnote.trim() !== "") {
            localStorage.setItem("ikapatnote", "(" + input.ikapatnote + ")");
          }
          else {
            localStorage.setItem("ikapatnote", "");
          }
          localStorage.setItem("mobileuser", input.mobileuser);
          const url = "/billprint2change";
          window.open(url, '_blank');  //'_self'  _blank
        } else {
          notifyerror("या कालावधीमध्ये संकलन उपलब्ध नाही!");
        }
      } catch (error) {
        notifyerror(error);
        // Handle errors here
      } finally {
        setWait2(false);
      }
    }
    else {
      notifyerror("कृपया प्रथम बिल सेव्ह करा!")
      refprintclose.current.click();
    }
  };


  const showtrialprint = async () => {

    //
    setWaitprinttest(true);
    var mainuser2 = jsEncode.encode("mamapr", 125);

    try {
      const response = await axios.post('https://idairy.co.in/dairy/php/cust/gettrailprints.php', {
        mainuser: localStorage.getItem('contact'),
        date1: input.date1,
        date2: input.date2,
        mainuser2: mainuser2,
        knofrom: input.knofromprint,
        knoto: input.knotoprint,
        mobileuser: input.mobileuser,
      });

      //  console.log(response.data);

      localStorage.setItem("date1print", input.date1);
      localStorage.setItem("date2print", input.date2);
      localStorage.setItem("billtype", "newbill");
      localStorage.setItem("bcount", "0");
      localStorage.setItem("customerData", JSON.stringify(response.data));
      localStorage.setItem("kapat", "--");
      localStorage.setItem("showother", showother);
      localStorage.setItem("showthev", showthev);
      localStorage.setItem("design", rtype);
      if (input.ikapatnote !== "कपात नोट" && input.ikapatnote.trim() !== "") {
        localStorage.setItem("ikapatnote", "(" + input.ikapatnote + ")");
      }
      else {
        localStorage.setItem("ikapatnote", "");
      }
      localStorage.setItem("mobileuser", input.mobileuser);
      const url = "/billprinttest";
      setWaitprinttest(false);
      reftrailprintclose.current.click();
      window.open(url, '_blank');  //'_self'  _blank

    } catch (error) {
      notifyerror(error);
      // Handle errors here
    } finally {
      setWaitprinttest(false);
    }

  };

  const addOrUpdateCuchal = (kno, cuchal) => {
    setKapat((prevKapat) => {
      const updatedKapat = { ...prevKapat };

      if (updatedKapat[kno]) {
        updatedKapat[kno].cuchal = cuchal;
      } else {
        updatedKapat[kno] = { cuchal, ckhadya: '' };
      }

      return updatedKapat;
    });
  };

  const addOrUpdateCkhadya = (kno, ckhadya) => {
    setKapat((prevKapat) => {
      const updatedKapat = { ...prevKapat };

      if (updatedKapat[kno]) {
        updatedKapat[kno].ckhadya = ckhadya;
      } else {
        updatedKapat[kno] = { cuchal: '', ckhadya };
      }

      return updatedKapat;
    });
  };

  const handleCellKeyPress = (e, rowIndex) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (rowIndex + 1 < cellRefs.current.length) {
        const nextRow = cellRefs.current[rowIndex + 1];
        if (nextRow[0]) {
          nextRow[0].focus();
        }
      }
      e.target.blur(); // Remove focus from the cell
    } else if (e.key !== 'Backspace' && e.key !== '-' && !/Arrow(Left|Right)/.test(e.key)) {
      // Prevent non-numeric input
      if (isNaN(e.key) && e.key !== '.') {
        e.preventDefault();
      }
    }
  };

  const handleCellChange = (e, rowIndex, kno) => {

    let newValue = e.target.textContent;
    updatedDataSource = [...DataSource];

    //addOrUpdateCuchal(user.kno,advedit);

    if (updatedDataSource[rowIndex]) {
      //  updatedDataSource[rowIndex].khedit = updatedDataSource[rowIndex].action;
      updatedDataSource[rowIndex].action = newValue;
      // Calculate the result (column 6 - column 7) and store in column 8
      let column6Value = "";

      if (DataSource[0].flag === "oldbill") {
        column6Value = parseFloat(updatedDataSource[rowIndex].newadv) + parseFloat(updatedDataSource[rowIndex].cuchal);
      }
      else {
        column6Value = (parseFloat(updatedDataSource[rowIndex].bcount) > 0 ? parseFloat(updatedDataSource[rowIndex].newadv) : parseFloat(updatedDataSource[rowIndex].obal)) + parseFloat(updatedDataSource[rowIndex].cuchal);
      }

      const column7Value = newValue && !isNaN(newValue) && newValue !== null ? parseFloat(newValue) : 0;

      // console.log("column7Value-", column7Value);
      updatedDataSource[rowIndex].result = column6Value - column7Value;

      //if (newValue) {
      addOrUpdateCuchal(kno, newValue);
      if (DataSource[0].flag === "oldbill") {
        //notify("already before");

        let allthevtofixed = 0;
        localStorage.getItem("thevper") === "No" ?
          allthevtofixed = parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
          parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0))
          : localStorage.getItem("thevper") === "Yes" ?
            allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].bill * 10) / 100)
            :
            allthevtofixed = parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0))



        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -
          (
            allthevtofixed +
            (newValue ? parseFloat(newValue) : 0) +
            (
              (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
                ? parseFloat(updatedDataSource[rowIndex].khedit)
                : parseFloat(updatedDataSource[rowIndex].khadyakapat)
            )
            +
            (parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
              parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) -
              (parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter && updatedDataSource[rowIndex].mhliter !== "No" ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) +
                parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter && updatedDataSource[rowIndex].gayliter !== "No" ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0))

            )
          );

      }
      else {

        // console.log("Edit- ", updatedDataSource[rowIndex].ikapatedit);

        let allthevtofixed = 0;
        localStorage.getItem("thevper") === "No" ?
          allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev))
          : localStorage.getItem("thevper") === "Yes" ?
            allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].bill * 10) / 100)
            :
            allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev))


        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -

          (
            allthevtofixed +
            (parseFloat(newValue) ? parseFloat(newValue) : 0) +
            (
              (!isNaN(kapat[kno].ckhadya) && kapat[kno].ckhadya !== null)
                ? parseFloat(kapat[kno].ckhadya)
                :
                (!isNaN(updatedDataSource[rowIndex].khadyahpta) && updatedDataSource[rowIndex].khadyahpta !== null && updatedDataSource[rowIndex].khadyahpta !== '0')
                  ? parseFloat(updatedDataSource[rowIndex].khadyahpta)
                  : 0
            )
          )
          -
          (parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
            parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) -
            (parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) +
              parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0)))
      }

      if (!newValue) {
        newValue = 0;
      }

    }
    updatedDataSource[rowIndex].advedit = newValue;
    setDataSource(updatedDataSource);

    e.target.contentEditable = 'true'; // Re-enable editing
    setShowupdate(true);
  };

  const handleCellBlurkh = (e, rowIndex) => {
    let newValue = e.target.textContent.trim();
    if (!newValue) {
      e.target.textContent = '0'; // Set to '0' if empty
      newValue = 0;
    }
  };


  const filteData = (e) => {
    const searchValue = e.target.value.trim();

    if (searchValue !== "") {
      setValue(searchValue);
      const filtertable = DataSource.filter(o =>
        String(o.kno) === searchValue // Check for exact match with kno
      );

      setTableFilter([...filtertable]);
    } else {
      setValue(searchValue);
      setTableFilter([...DataSource]);
    }
  };


  const handleCellBlurikapat = (e, rowIndex) => {
    let newValue = e.target.textContent.trim();
    if (!newValue) {
      e.target.textContent = '0'; // Set to '0' if empty
      newValue = 0;
    }
  };

  const handleCellfocus = (e, rowIndex) => {
    if (e.target.textContent === "0") {
      e.target.textContent = '';
    }
  }

  const handleCellBlur = (e, rowIndex) => {
    let newValue = e.target.textContent.trim();

    if (!newValue) {
      e.target.textContent = '0'; // Set to '0' if empty
    }
  };

  const handleCellChangekh = (e, rowIndex, kno) => {
    let newValue = e.target.textContent;
    updatedDataSource = [...DataSource];

    if (updatedDataSource[rowIndex]) {
      updatedDataSource[rowIndex].action = newValue;

      // Calculate the result (column 6 - column 7) and store in column 8
      let column6Value = 0;
      if (DataSource[0].flag === "oldbill") {
        column6Value = parseFloat(updatedDataSource[rowIndex].mkhadya) + parseFloat(updatedDataSource[rowIndex].ckhadya);
      }
      else {
        column6Value = parseFloat(updatedDataSource[rowIndex].newkhadya) + parseFloat(updatedDataSource[rowIndex].ckhadya);
      }

      const column7Value = newValue && !isNaN(newValue) && newValue !== null ? parseFloat(newValue) : 0;
      updatedDataSource[rowIndex].result2 = column6Value - column7Value;

      // if (newValue) {
      addOrUpdateCkhadya(kno, newValue);
      if (DataSource[0].flag === "oldbill") {

        let allthevtofixed = 0;
        localStorage.getItem("thevper") === "No" ?
          allthevtofixed = parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
          parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0))
          : localStorage.getItem("thevper") === "Yes" ?
            allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].bill * 10) / 100)
            :
            allthevtofixed = parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0))


        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -
          (
            allthevtofixed +
            (
              (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                ? parseFloat(updatedDataSource[rowIndex].advedit)
                : parseFloat(updatedDataSource[rowIndex].advkapat)
            ) +
            (newValue ? parseFloat(newValue) : 0) +
            parseFloat(updatedDataSource[rowIndex].ikapatliter || 0) +
            parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) -
            parseFloat(updatedDataSource[rowIndex].vah || 0)
          );
      }
      else {

        let allthevtofixed = 0;
        localStorage.getItem("thevper") === "No" ?
          allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev))
          : localStorage.getItem("thevper") === "Yes" ?
            allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].bill * 10) / 100)
            :
            allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev))


        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -
          (
            allthevtofixed +
            (parseFloat(newValue) ? parseFloat(newValue) : 0) +
            (
              (!isNaN(kapat[kno].cuchal) && kapat[kno].cuchal !== null)
                ? parseFloat(kapat[kno].cuchal)
                :
                (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null && updatedDataSource[rowIndex].advhpta !== '0')
                  ? parseFloat(updatedDataSource[rowIndex].advhpta)
                  : 0
            ) +
            parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
            parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) -
            parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) -
            parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0)

          );
      }
      //}
      // else {
      //   addOrUpdateCkhadya(kno, newValue);
      //   if (DataSource[0].flag === "oldbill") {

      //     //updatedDataSource[rowIndex].adacalculated = parseFloat(updatedDataSource[rowIndex].bill) - (parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) + (parseFloat(updatedDataSource[rowIndex].advedit) ? parseFloat(updatedDataSource[rowIndex].advedit) : parseFloat(updatedDataSource[rowIndex].advkapat ? updatedDataSource[rowIndex].advkapat : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter) : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) + parseFloat(updatedDataSource[rowIndex].vah ? parseFloat(updatedDataSource[rowIndex].vah) : 0)));
      //     updatedDataSource[rowIndex].adacalculated =
      //       parseFloat(updatedDataSource[rowIndex].bill) -
      //       (
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
      //         (
      //           (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
      //             ? parseFloat(updatedDataSource[rowIndex].advedit)
      //             : parseFloat(updatedDataSource[rowIndex].advkapat)
      //         ) +
      //         (newValue ? parseFloat(newValue) : 0) +
      //         parseFloat(updatedDataSource[rowIndex].ikapatliter || 0) +
      //         parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) -
      //         parseFloat(updatedDataSource[rowIndex].vah || 0)
      //       );
      //   }
      //   else {

      //     updatedDataSource[rowIndex].adacalculated =
      //       parseFloat(updatedDataSource[rowIndex].bill) -
      //       (
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
      //         (parseFloat(newValue) ? parseFloat(newValue) : 0) +
      //         (
      //           (!isNaN(kapat[kno].cuchal) && kapat[kno].cuchal !== null)
      //             ? parseFloat(kapat[kno].cuchal)
      //             :
      //             (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null && updatedDataSource[rowIndex].advhpta !== '0')
      //               ? parseFloat(updatedDataSource[rowIndex].advhpta)
      //               : 0
      //         ) +
      //         parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
      //         parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) -
      //         parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) -
      //         parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0)

      //       );
      //   }
      // }

      if (!newValue) {
        newValue = 0;
      }
      updatedDataSource[rowIndex].khedit = newValue; //1
      setUpdatedDataSource2(prevInput => ({
        ...prevInput,
        khedit: newValue
      }))
      setDataSource(updatedDataSource);
    }

    e.target.contentEditable = 'true'; // Re-enable editing
    setShowupdate(true);
  };



  // useEffect(()=>{
  //   console.log(updatedDataSource)
  // },[updatedDataSource])

  const handleCellChangeikapat = (e, rowIndex, kno) => {

    let newValue = e.target.textContent;


    updatedDataSource = [...DataSource];

    if (updatedDataSource[rowIndex]) {
      updatedDataSource[rowIndex].action = newValue;

      //  if (newValue) {

      //  addOrUpdateCkhadya(kno, newValue);

      if (DataSource[0].flag === "oldbill") {

        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -
          (
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
            ((
              (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                ? parseFloat(updatedDataSource[rowIndex].advedit)
                : parseFloat(updatedDataSource[rowIndex].advkapat)
            )
              +
              (
                (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
                  ? parseFloat(updatedDataSource[rowIndex].khedit)
                  : parseFloat(updatedDataSource[rowIndex].khadyakapat)
              ))
            +
            ((newValue ? parseFloat(newValue) : 0)
              + (parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0))))
            - parseFloat(updatedDataSource[rowIndex].vah)

          );
      }
      else {

        let allthevtofixed = 0;
        localStorage.getItem("thevper") === "No" ?
          allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev))
          : localStorage.getItem("thevper") === "Yes" ?
            allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].bill * 10) / 100)
            :
            allthevtofixed = parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev))

        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -
          (
            allthevtofixed +
            (parseFloat(newValue) ? parseFloat(newValue) : 0) +
            (
              ((!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                ? parseFloat(updatedDataSource[rowIndex].advedit) :
                !isNaN(kapat[kno].cuchal) && kapat[kno].cuchal !== null)
                ? parseFloat(kapat[kno].cuchal)
                :
                (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null && updatedDataSource[rowIndex].advhpta !== '0')
                  ? parseFloat(updatedDataSource[rowIndex].advhpta)
                  : 0
            )
            +
            (
              (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
                ? parseFloat(updatedDataSource[rowIndex].khedit) :
                !isNaN(kapat[kno].ckhadya) && kapat[kno].ckhadya !== null
                  ? parseFloat(kapat[kno].ckhadya)
                  :
                  (!isNaN(updatedDataSource[rowIndex].khadyahpta) && updatedDataSource[rowIndex].khadyahpta !== null && updatedDataSource[rowIndex].khadyahpta !== '0')
                    ? parseFloat(updatedDataSource[rowIndex].khadyahpta)
                    : 0
            )
            +
            parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) -
            // parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : 0)) -
            (parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) +
              parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0))

          );
      }
      // }
      // else {
      //   //  addOrUpdateCkhadya(kno, newValue);
      //   if (DataSource[0].flag === "oldbill") {
      //     //updatedDataSource[rowIndex].adacalculated = parseFloat(updatedDataSource[rowIndex].bill) - (parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) + (parseFloat(updatedDataSource[rowIndex].advedit) ? parseFloat(updatedDataSource[rowIndex].advedit) : parseFloat(updatedDataSource[rowIndex].advkapat ? updatedDataSource[rowIndex].advkapat : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter) : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) + parseFloat(updatedDataSource[rowIndex].vah ? parseFloat(updatedDataSource[rowIndex].vah) : 0)));
      //     updatedDataSource[rowIndex].adacalculated =
      //       parseFloat(updatedDataSource[rowIndex].bill) -
      //       (
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
      //         (
      //           (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
      //             ? parseFloat(updatedDataSource[rowIndex].advedit)
      //             : parseFloat(updatedDataSource[rowIndex].advkapat)
      //         ) +
      //         parseFloat(updatedDataSource[rowIndex].ikapatliter || 0) +
      //         parseFloat(updatedDataSource[rowIndex].ikapatbill || 0) -
      //         parseFloat(updatedDataSource[rowIndex].vah || 0)
      //       );
      //   }
      //   else {
      //     updatedDataSource[rowIndex].adacalculated =
      //       parseFloat(updatedDataSource[rowIndex].bill) -
      //       (
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
      //         (
      //           ((!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
      //             ? parseFloat(updatedDataSource[rowIndex].advedit) :
      //             !isNaN(kapat[kno].cuchal) && kapat[kno].cuchal !== null)
      //             ? parseFloat(kapat[kno].cuchal)
      //             :
      //             (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null && updatedDataSource[rowIndex].advhpta !== '0')
      //               ? parseFloat(updatedDataSource[rowIndex].advhpta)
      //               : 0
      //         )
      //         +
      //         (
      //           (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
      //             ? parseFloat(updatedDataSource[rowIndex].khedit) :
      //             !isNaN(kapat[kno].ckhadya) && kapat[kno].ckhadya !== null
      //               ? parseFloat(kapat[kno].ckhadya)
      //               :
      //               (!isNaN(updatedDataSource[rowIndex].khadyahpta) && updatedDataSource[rowIndex].khadyahpta !== null && updatedDataSource[rowIndex].khadyahpta !== '0')
      //                 ? parseFloat(updatedDataSource[rowIndex].khadyahpta)
      //                 : 0
      //         )
      //         +
      //         parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
      //         parseFloat((updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) -
      //         (parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) +
      //           parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0))

      //       );
      //   }
      // }

      if (!newValue) {
        newValue = 0;
      }
      updatedDataSource[rowIndex].ikapatedit = newValue; //1

      setUpdatedDataSource2(prevInput => ({
        ...prevInput,
        ikapatbill2: newValue
      }))
      setDataSource(updatedDataSource);
    }
    e.target.contentEditable = 'true'; // Re-enable editing

    setShowupdate(true);
  };


  // useEffect(() => {
  //   console.log(DataSource)
  // }, [DataSource])


  async function getmaxno() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    props.setProgress(30);
    setTimeout(() => {
      props.setProgress(50);
    }, 200);
    axios.post('https://idairy.co.in/dairy/php/cust/getmaxno2.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

      if (response.data) {

        setInput(prevInput => ({
          ...prevInput,
          knofrom: "1",
          knoto: response.data[0].maxkno,
          knofromprint: "1",
          knotoprint: response.data[0].maxkno,
        }))

        setLastdate1(response.data[0].maxdate1);
        setLastdate2(response.data[0].maxdate2);

      }
      else {
        setInput(prevInput => ({
          ...prevInput,
          knofrom: "0",
          knoto: "0"
        }))
      }
    }).catch(err => {
      console.log(err);
    });
  }

  useEffect(() => {
    let interval;
    if (isCounting && count > 1) {
      interval = setInterval(() => {
        setCount(prevCount => prevCount - 1);
      }, 1000);
    }

    if (count === 1) {
      clearInterval(interval);
      setIsCounting(false);
    }

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [isCounting, count]);


  const readyBill = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      knofrom: input.knofrom,
      knoto: input.knoto,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/readybill2.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      console.log(response.data);
    }).catch(err => {
      console.log(err);
    });
  }


  const getbillold = async () => {

    setCount(parseFloat(input.knoto * 4).toFixed(0));
    setWait(true);
    setIsFetching(true);
    setIsCounting(true);

    const mainuser2 = jsEncode.encode("mamapr", 125);

    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      setWait(false);
      setIsFetching(false);
      setIsCounting(false);
      return;
    }

    const fetchBills = async (i) => {

      if (cancelRef.current) {
        setWait(false);
        setIsFetching(false);
        return;
      }

      if (i >= input.knoto) {
        setWait(false);
        setIsFetching(false);
        return;
      }
      // knofrom: i + 1,
      //  knoto: i + 2,

      const rawData = {
        date1: input.date1,
        date2: input.date2,
        knofrom: i + 1,
        knoto: i + 2,
        kno2from: input.knofrom,
        kno2to: input.knoto,
        mainuser: localStorage.getItem("contact"),
        mainuser2: mainuser2,
      };

      const formData = new FormData();
      formData.append('data', JSON.stringify(rawData));

      try {
        const response = await axios.post(
          'https://idairy.co.in/dairy/php/cust/getbillfast5.php',  //getbillfast5
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.data && response.data.length > 0) {

          if (response.data[0].flag === "oldbill") {
            setDataSource(response.data)

            setIsDataLoaded(true);
          }
          else {
            if (refresh === "true") {
              setDataSource(response.data)
            }
            else {
              setDataSource(prevDataSource => [...prevDataSource, ...response.data]);
            }
            // setIsDataLoaded(true);
          }

          {
            response.data && response.data.map((user, index) => {

              // let allthevtofixed = 0;
              // localStorage.getItem("thevper") === "No" ?
              //   allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
              //   :
              //   allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2);

              let allthevtofixed = 0;
              localStorage.getItem("thevper") === "No" ?
                allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
                : localStorage.getItem("thevper") === "Yes" ?
                  allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2)
                  :
                  allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)


              let billminusthev = parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed)).toFixed(2);
              let tadvance = parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2);
              let advancehfta = user.advhpta && user.advhpta !== null && user.advhpta !== '0' && parseFloat(user.advhpta);
              let totalkhadya = parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)

              let khedit = user.flag === "oldbill" ?
                (user.khadyakapat && user.khadyakapat !== '0' ? user.khadyakapat : 0) :

                parseFloat(
                  totalkhadya < billminusthev ?
                    user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0'
                      ?
                      parseFloat(user.khadyahpta).toFixed(2)
                      :
                      user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                        parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                          parseFloat(billminusthev < totalkhadya ?
                            billminusthev :
                            totalkhadya)
                          - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                          : 0
                        :
                        billminusthev < totalkhadya ?
                          billminusthev :
                          totalkhadya

                    :
                    //parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -       

                    parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                    ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                    ) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                    ) < totalkhadya ?

                      parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                      ) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                      + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)

                      :
                      parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                      ) -
                        parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))))
                      + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                ).toFixed(2);

              let advedit = user.flag === "oldbill" ?
                (user.advkapat ? user.advkapat : 0) :
                parseFloat(tadvance > 0 ?
                  parseFloat(advancehfta != 0 && advancehfta <= (billminusthev - khedit) ?
                    advancehfta <= tadvance
                      ?
                      user.advhpta <= parseFloat(billminusthev - totalkhadya)
                        ?
                        parseFloat(user.advhpta)
                        :
                        parseFloat(billminusthev - totalkhadya) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                      :
                      tadvance
                    :
                    billminusthev
                      >
                      parseFloat(
                        billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                          billminusthev :
                          parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))

                      ?
                      parseFloat(
                        billminusthev
                        -
                        parseFloat(
                          billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                            billminusthev :
                            parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)))
                        <=
                        parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)

                        ?
                        parseFloat(parseFloat(
                          billminusthev -
                          parseFloat(
                            billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                              billminusthev :
                              parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))
                        )
                          -
                          parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                          +
                          parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                          +
                          parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                        ).toFixed(2)
                        :

                        parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                      :
                      0)
                  :
                  0);

              addOrUpdateCuchal(user.kno, advedit);
              addOrUpdateCkhadya(user.kno, khedit);
            })
          }

          if (response.data[0].flag === "oldbill") {
            setWait(false);
            setIsFetching(false);
            setIsCounting(false);
            return;
          }
        }

        // Proceed to the next batch regardless of success or empty response
        updatedDataSource = [...DataSource];

        await delay(200);

        await fetchBills(i + 2);

      } catch (error) {
        console.log(error);
        setWait(false);
        setIsFetching(false);
      }
    };

    await fetchBills(0);
  };

  const getbill = async () => {

    setCount(parseFloat(input.knoto * 2).toFixed(0));
    setWait(true);
    setIsFetching(true);
    setIsCounting(true);

    const mainuser2 = jsEncode.encode("mamapr", 125);

    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      setWait(false);
      setIsFetching(false);
      setIsCounting(false);
      return;
    }

    const fetchBills = async (i) => {

      if (cancelRef.current) {
        setWait(false);
        setIsFetching(false);
        return;
      }

      if (i >= input.knoto) {
        setWait(false);
        setIsFetching(false);
        return;
      }
      // knofrom: i + 1,
      //  knoto: i + 2,

      const rawData = {
        date1: input.date1,
        date2: input.date2,
        knofrom: input.knofrom,
        knoto: input.knoto,
        kno2from: input.knofrom,
        kno2to: input.knoto,
        mainuser: localStorage.getItem("contact"),
        mainuser2: mainuser2,
      };

      const formData = new FormData();
      formData.append('data', JSON.stringify(rawData));

      try {
        const response = await axios.post(
          'https://idairy.co.in/dairy/php/cust/readybill2.php',  //getbillfast5
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.data && response.data.length > 0) {

          if (response.data[0].flag === "oldbill") {
            setDataSource(response.data)

            setIsDataLoaded(true);
          }
          else {
            if (refresh === "true") {
              setDataSource(response.data)
            }
            else {
              setDataSource(prevDataSource => [...prevDataSource, ...response.data]);
            }
            // setIsDataLoaded(true);
          }

          {
            response.data && response.data.map((user, index) => {

              // let allthevtofixed = 0;
              // localStorage.getItem("thevper") === "No" ?
              //   allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
              //   :
              //   allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2);

              let allthevtofixed = 0;
              localStorage.getItem("thevper") === "No" ?
                allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
                : localStorage.getItem("thevper") === "Yes" ?
                  allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2)
                  :
                  allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)


              let billminusthev = parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed)).toFixed(2);
              let tadvance = parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2);
              let advancehfta = user.advhpta && user.advhpta !== null && user.advhpta !== '0' && parseFloat(user.advhpta);
              let totalkhadya = parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)

              let khedit = user.flag === "oldbill" ?
                (user.khadyakapat && user.khadyakapat !== '0' ? user.khadyakapat : 0) :

                parseFloat(
                  totalkhadya < billminusthev ?
                    user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0'
                      ?
                      parseFloat(user.khadyahpta).toFixed(2)
                      :
                      user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                        parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                          parseFloat(billminusthev < totalkhadya ?
                            billminusthev :
                            totalkhadya)
                          - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                          : 0
                        :
                        billminusthev < totalkhadya ?
                          billminusthev :
                          totalkhadya

                    :
                    //parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -       

                    parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                    ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                    ) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                    ) < totalkhadya ?

                      parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                      ) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                      + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)

                      :
                      parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                      ) -
                        parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))))
                      + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                ).toFixed(2);

              let advedit = user.flag === "oldbill" ?
                (user.advkapat ? user.advkapat : 0) :
                parseFloat(tadvance > 0 ?
                  parseFloat(advancehfta != 0 && advancehfta <= (billminusthev - khedit) ?
                    advancehfta <= tadvance
                      ?
                      user.advhpta <= parseFloat(billminusthev - totalkhadya)
                        ?
                        parseFloat(user.advhpta)
                        :
                        parseFloat(billminusthev - totalkhadya) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                      :
                      tadvance
                    :
                    billminusthev
                      >
                      parseFloat(
                        billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                          billminusthev :
                          parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))

                      ?
                      parseFloat(
                        billminusthev
                        -
                        parseFloat(
                          billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                            billminusthev :
                            parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)))
                        <=
                        parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)

                        ?
                        parseFloat(parseFloat(
                          billminusthev -
                          parseFloat(
                            billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                              billminusthev :
                              parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))
                        )
                          -
                          parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                          +
                          parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                          +
                          parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                        ).toFixed(2)
                        :

                        parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                      :
                      0)
                  :
                  0);

              addOrUpdateCuchal(user.kno, advedit);
              addOrUpdateCkhadya(user.kno, khedit);
            })
          }

          if (response.data[0].flag === "oldbill") {
            setWait(false);
            setIsFetching(false);
            setIsCounting(false);
            return;
          }
        }

        // Proceed to the next batch regardless of success or empty response
        updatedDataSource = [...DataSource];

        await delay(200);

        // await fetchBills(i + 2);
        i = 2000;
        setWait(false);

      } catch (error) {
        console.log(error);
        setWait(false);
        setIsFetching(false);
      }
    };

    await fetchBills(0);
  };


  const getbillsingle = async () => {
    setWait(true);
    const mainuser2 = jsEncode.encode("mamapr", 125);

    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      setWait(false);
      setIsFetching(false);
      setIsCounting(false);
      return;
    }

    if (cancelRef.current) {
      setWait(false);
      setIsFetching(false);
      return;
    }


    const rawData = {
      date1: input.date1,
      date2: input.date2,
      knofrom: input.knofrom,
      knoto: input.knoto,
      kno2from: input.knofrom,
      kno2to: input.knoto,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(rawData));

    try {
      const response = await axios.post(
        'https://idairy.co.in/dairy/php/cust/getbillfastsingle2.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data && response.data.length > 0) {
        setDataSource([]);

        if (response.data[0].flag === "oldbill") {
          setDataSource(response.data)
        }
        else {
          if (refresh === "true") {
            setDataSource(response.data)
          }
          else {
            setDataSource(prevDataSource => [...prevDataSource, ...response.data]);
          }
        }

        setWait(false);
        {
          response.data && response.data.map((user, index) => {

            // let allthevtofixed = 0;
            // localStorage.getItem("thevper") === "No" ?
            //   allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
            //   :
            //   allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2);

            let allthevtofixed = 0;
            localStorage.getItem("thevper") === "No" ?
              allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
              : localStorage.getItem("thevper") === "Yes" ?
                allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2)
                :
                allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)


            let billminusthev = parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed)).toFixed(2);
            let tadvance = parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2);
            let advancehfta = user.advhpta && user.advhpta !== null && user.advhpta !== '0' && parseFloat(user.advhpta);
            let totalkhadya = parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)

            let khedit = user.flag === "oldbill" ?
              (user.khadyakapat && user.khadyakapat !== '0' ? user.khadyakapat : 0) :

              parseFloat(
                totalkhadya < billminusthev ?
                  user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0'
                    ?
                    parseFloat(user.khadyahpta).toFixed(2)
                    :
                    user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                      parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                        parseFloat(billminusthev < totalkhadya ?
                          billminusthev :
                          totalkhadya)
                        - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                        : 0
                      :
                      billminusthev < totalkhadya ?
                        billminusthev :
                        totalkhadya

                  :
                  //parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -       

                  parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))) -
                    parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                  ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                  ) -
                    parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                  ) < totalkhadya ?

                    parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                    ) -
                    parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                    + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)

                    :
                    parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                    ) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))))
                    + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
              ).toFixed(2);

            let advedit = user.flag === "oldbill" ?
              (user.advkapat ? user.advkapat : 0) :
              parseFloat(tadvance > 0 ?
                parseFloat(advancehfta != 0 && advancehfta <= (billminusthev - khedit) ?
                  advancehfta <= tadvance
                    ?
                    user.advhpta <= parseFloat(billminusthev - totalkhadya)
                      ?
                      parseFloat(user.advhpta)
                      :
                      parseFloat(billminusthev - totalkhadya) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                    :
                    tadvance
                  :
                  billminusthev
                    >
                    parseFloat(
                      billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                        billminusthev :
                        parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))

                    ?
                    parseFloat(
                      billminusthev
                      -
                      parseFloat(
                        billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                          billminusthev :
                          parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)))
                      <=
                      parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)

                      ?
                      parseFloat(parseFloat(
                        billminusthev -
                        parseFloat(
                          billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                            billminusthev :
                            parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))
                      )
                        -
                        parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                        +
                        parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                        +
                        parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                      ).toFixed(2)
                      :

                      parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                    :
                    0)
                :
                0);

            addOrUpdateCuchal(user.kno, advedit);
            addOrUpdateCkhadya(user.kno, khedit);
          })
        }


      }
      else {
        notifyerror("माहिती उपलब्ध नाही!");
        setWait(false);
      }

      // Proceed to the next batch regardless of success or empty response
      updatedDataSource = [...DataSource];


    } catch (error) {
      console.log(error);
      setWait(false);
      setIsFetching(false);
    }

  };


  const getbillsingleauto = async () => {

    setWait(true);
    const mainuser2 = jsEncode.encode("mamapr", 125);

    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      setWait(false);
      setIsFetching(false);
      setIsCounting(false);
      return;
    }

    if (cancelRef.current) {
      setWait(false);
      setIsFetching(false);
      return;
    }


    const rawData = {
      date1: input.date1,
      date2: input.date2,
      knofrom: input.knofrom,
      knoto: input.knoto,
      kno2from: input.knofrom,
      kno2to: input.knoto,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(rawData));

    try {
      const response = await axios.post(
        'https://idairy.co.in/dairy/php/cust/getbillfastsingle2.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data && response.data.length > 0) {
        setDataSource([]);

        if (response.data[0].flag === "oldbill") {
          setDataSource(response.data)
        }
        else {
          if (refresh === "true") {
            setDataSource(response.data)
          }
          else {
            setDataSource(prevDataSource => [...prevDataSource, ...response.data]);
          }
        }

        setWait(false);
        {
          response.data && response.data.map((user, index) => {

            // let allthevtofixed = 0;
            // localStorage.getItem("thevper") === "No" ?
            //   allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
            //   :
            //   allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2);

            let allthevtofixed = 0;
            localStorage.getItem("thevper") === "No" ?
              allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
              : localStorage.getItem("thevper") === "Yes" ?
                allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2)
                :
                allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)


            let billminusthev = parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed)).toFixed(2);
            let tadvance = parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2);
            let advancehfta = user.advhpta && user.advhpta !== null && user.advhpta !== '0' && parseFloat(user.advhpta);
            let totalkhadya = parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)

            let khedit = user.flag === "oldbill" ?
              (user.khadyakapat && user.khadyakapat !== '0' ? user.khadyakapat : 0) :

              parseFloat(
                totalkhadya < billminusthev ?
                  user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0'
                    ?
                    parseFloat(user.khadyahpta).toFixed(2)
                    :
                    user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                      parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                        parseFloat(billminusthev < totalkhadya ?
                          billminusthev :
                          totalkhadya)
                        - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                        : 0
                      :
                      billminusthev < totalkhadya ?
                        billminusthev :
                        totalkhadya

                  :
                  //parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -       

                  parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))) -
                    parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                  ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                  ) -
                    parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                  ) < totalkhadya ?

                    parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                    ) -
                    parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                    + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)

                    :
                    parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                    ) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))))
                    + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
              ).toFixed(2);

            let advedit = user.flag === "oldbill" ?
              (user.advkapat ? user.advkapat : 0) :
              parseFloat(tadvance > 0 ?
                parseFloat(advancehfta != 0 && advancehfta <= (billminusthev - khedit) ?
                  advancehfta <= tadvance
                    ?
                    user.advhpta <= parseFloat(billminusthev - totalkhadya)
                      ?
                      parseFloat(user.advhpta)
                      :
                      parseFloat(billminusthev - totalkhadya) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                    :
                    tadvance
                  :
                  billminusthev
                    >
                    parseFloat(
                      billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                        billminusthev :
                        parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))

                    ?
                    parseFloat(
                      billminusthev
                      -
                      parseFloat(
                        billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                          billminusthev :
                          parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)))
                      <=
                      parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)

                      ?
                      parseFloat(parseFloat(
                        billminusthev -
                        parseFloat(
                          billminusthev < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                            billminusthev :
                            parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))
                      )
                        -
                        parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                        +
                        parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                        +
                        parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                      ).toFixed(2)
                      :

                      parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                    :
                    0)
                :
                0);

            addOrUpdateCuchal(user.kno, advedit);
            addOrUpdateCkhadya(user.kno, khedit);
          })
        }

      }
      else {
        notifyerror("माहिती उपलब्ध नाही!");
        setWait(false);
      }

      // Proceed to the next batch regardless of success or empty response
      updatedDataSource = [...DataSource];


      if (input.knofrom === input.knoto) {
        await delay(1000);
        fetchtabledata();
      }

    } catch (error) {
      console.log(error);
      setWait(false);
      setIsFetching(false);
    }
  };


  function formatDate(inputDate) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(inputDate).toLocaleDateString('en-IN', options);
  }

  const deletebill = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    const { date1next, date2next } = calculateNextDates(input.date1, input.date2);

    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      date1next: date1next,
      date2next: date2next,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/deletebill2.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      if (response.data.trim() === "Deleted") {
        notify("सभासद बील डिलीट झाले!");
        refdeleteclose.current.click();
        setDataSource([]);
        getmaxno();
      }
    }).catch(err => {
      console.log(err);
    });
  }


  const deletebillesingle = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      kno: input.knofrom,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/deletebillsingle.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      if (response.data.trim() === "Deleted") {
        // notify("खाते क्रमांक " + input.knofrom + "चे सभासद बील डिलीट झाले!");
        refdeletesingleclose.current.click();
        setDataSource([]);
        // getmaxno();
        getbillsingleauto();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const handleedit = (sr, date, time, kno, name, type, liter, fat, snf, rate, amt) => {
    // setInput({ date: date, time: time, kno: kno, name: name, type: type, liter: liter, fat: fat, snf: snf, rate: rate, amt: amt })
    // setNewedit("edit");
    // setSredit(sr);
  }
  const handleChange = async (event) => {
    const name = event.target.name;
    let valuecurrent = "";
    valuecurrent = event.target.value;


    if (name === 'date1') {

      const currentDate1 = new Date(valuecurrent);
      const dayOfMonth1 = currentDate1.getDate();
      const currentMonth1 = currentDate1.getMonth();
      const currentYear1 = currentDate1.getFullYear();

      if (localStorage.getItem("dur") === "10") {
        if (dayOfMonth1 == '1' || dayOfMonth1 == '11' || dayOfMonth1 == '21') {
          // If the value is blank or invalid, reset to the last valid date or today
          if (value === "" || value === "dd/mm/yyyy" || value > today) {
            setInput(prevInput => ({
              ...prevInput,
              date1: prevInput.date1 && prevInput.date1 <= today ? prevInput.date1 : today
            }));

            setInput(value => ({ ...value, [name]: valuecurrent }));
            return;
          }
        }
        else {
          notifyerror("कृपया १, ११ किंवा २१ यामधील दिनांक निवडा!")
        }
      }
      else if (localStorage.getItem("dur") === "15") {
        if (dayOfMonth1 == '1' || dayOfMonth1 == '16') {
          // If the value is blank or invalid, reset to the last valid date or today
          if (value === "" || value === "dd/mm/yyyy" || value > today) {
            setInput(prevInput => ({
              ...prevInput,
              date1: prevInput.date1 && prevInput.date1 <= today ? prevInput.date1 : today
            }));

            setInput(value => ({ ...value, [name]: valuecurrent }));
            return;
          }
        }
        else {
          notifyerror("कृपया १, १६ यामधील दिनांक निवडा!")
        }
      }
    }
    else {
      setInput(value => ({ ...value, [name]: valuecurrent }));
    }
  }

  const handleBack = () => {
    cancelRef.current = true; // Set cancel flag in the ref
    setTimeout(() => {
      props.visibility();
    }, 500); // 500 ms delay (you can adjust this time as needed)
  }

  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={reftrailprint}
        data-bs-target="#trialprintbox"
      >
      </button>

      <div className="modal fade" id="trialprintbox" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={reftrailprintclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title" id="exampleModalLabel">ट्रायल प्रिंट साठी खाते क्रमांक निवडा</h5>
              <div className='helpmain'>
                <img className='smallimg' src={Status} alt="" />
                <input onChange={handleChange} type="number" className="form-control" id="knofromprint" name="knofromprint" value={input.knofromprint} autoComplete='off' />
                <span> ते </span>
                <input onChange={handleChange} type="number" className="form-control" id="knotoprint" name="knotoprint" value={input.knotoprint} autoComplete='off' />
              </div>

              <span className="greenlbl" htmlFor="activeother">
                सूचना : ट्रायल प्रिंट्स च्या मदतीने आपण सर्व एंट्रीज बरोबर केल्या आहेत की नाही हे तपासून पाहता येते,यासाठी बिल पाहण्याची किंवा सेव्ह करण्याची गरज नाही,धन्यवाद!
              </span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleprinttrialclose}>बाहेर</button>
              {
                waitprinttest ? (
                  <button type="button" className="btn btn-success">लोडिंग</button>
                ) : (
                  <button type="button" className="btn btn-success" onClick={showtrialprint}>ओके</button>
                )
              }
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------ */}

      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={refdeletesingle}
        data-bs-target="#deletebillsingle"
      >
      </button>

      <div className="modal fade" id="deletebillsingle" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeletesingleclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h4 className="modal-title" id="exampleModalLabel">खाते क्रमांक {input.knofrom} चे बील रिफ्रेश करायचे?</h4>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeletesingleclose}  >नाही</button>
              <button type="button" className="btn btn-danger" onClick={deletebillesingle}>होय</button>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------ */}
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={refprint}
        data-bs-target="#printinput"
      >
      </button>

      <div className="modal fade" id="printinput" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refprintclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title" id="exampleModalLabel">प्रिंट साठी खाते क्रमांक निवडा</h5>
              <div className='helpmain'>
                <img className='smallimg' src={Status} alt="" />
                <input onChange={handleChange} type="number" className="form-control" id="knofromprint" name="knofromprint" value={input.knofromprint} autoComplete='off' />
                <span> ते </span>
                <input onChange={handleChange} type="number" className="form-control" id="knotoprint" name="knotoprint" value={input.knotoprint} autoComplete='off' />
              </div>
              <div className="radiotheme">
                <div className="form-check">
                  <input onClick={handlertype} defaultChecked className="form-check-input" type="radio" name="exampleRadios" id="radio2" value="option2" />
                  <label className="form-check-label radiolabel" htmlFor="radio2">
                    बिल डिझाईन 1
                  </label>
                </div>
                <div className="form-check">
                  <input onClick={handlertype} className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                  <label className="form-check-label radiolabel" htmlFor="radio1">
                    बिल डिझाईन 2
                  </label>
                </div>
              </div>

              <div className="form-check check">
                <input className="form-check-input" type="checkbox" defaultChecked value="" onChange={handleOtheractive} id="activeother" />
                <input onChange={handleChange} type="text" className="form-control" id="ikapatnote" name="ikapatnote" value={input.ikapatnote} autoComplete='off' />
              </div>

              <div className="mb-2">
                <span className='subheadsank'>मोबाईल यूजर नाव</span>
                <select onKeyDown={e => handleEnterKey(e, 6)} ref={ref => (inputRefs.current[6] = ref)} className={"form-control"} autoComplete='off' id="mobileuser" name="mobileuser" value={input.mobileuser} onChange={handleChange}>
                  <option key={0} value={"मोबाईल यूजर नाव निवडा"}>मोबाईल यूजर नाव निवडा</option>
                  {mobileusers ? mobileusers.map((opt2, index) =>

                    <option key={index + 1} value={opt2.name}>{opt2.name}</option>

                  ) : ""}
                </select>
              </div>

              <span className='note'>बील सेटिंग्ज</span>

              <div className="form-check check">
                <input className="form-check-input" type="checkbox" defaultChecked value="" onChange={handleOtheractive} id="activeother" />
                <span className="checklbl" htmlFor="activeother">
                  इतर कपात
                </span>
              </div>

              <div className="form-check check">
                <input className="form-check-input" type="checkbox" defaultChecked value="" onChange={handlethev} id="activethev" />
                <span className="checklbl" htmlFor="activeother">
                  मागील व शिल्लक ठेव
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleprintclose}>बाहेर</button>
              <button type="button" className="btn btn-danger" onClick={showprint}>ओके</button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={refdelete}
        data-bs-target="#deletebill"
      >
      </button>

      <div className="modal fade" id="deletebill" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h4 className="modal-title" id="exampleModalLabel">बील डिलीट करायचे?</h4>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeleteclose}  >नाही</button>
              <button type="button" className="btn btn-danger" onClick={deletebill}>होय</button>
            </div>
          </div>
        </div>
      </div>
      <div className="sanklanmain">
        <span className="redbill" htmlFor="activestatus">
          अखेरचे सेव्ह केलेले बील : {formatDate(lastdate1)} ते {formatDate(lastdate2)}
        </span>
        <div className="linebill"></div>
        <div className="firstrowsank">

          <img onClick={handleBack} className='back' src={back} alt="" />
          <div className="colmainbill">

            <div className="firstrowbill">

              <div className="rowbill">
                <div className="mb-2">
                  <span className='subheadsank'>दिनांक</span>
                  <input max={today} onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                </div>

                <span className='subheadbill'>ते</span>

                <div className="mb-2">
                  <span className='subheadsank'>दिनांक</span>
                  <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                </div>

                <span className='subheadbill se'> || </span>


                {/* {DataSource && DataSource.length === 0 ?
                    '' :
                    DataSource[0].flag === "oldbill" ?
                      (DataSource[0]?.lastbill === "Yes" || DataSource[1]?.lastbill === "Yes" || DataSource[2]?.lastbill === "Yes" || DataSource[3]?.lastbill === "Yes" || DataSource[4]?.lastbill === "Yes") ?
                        <UilTrash onClick={handleDelete} className="icdelete" />
                        : ''

                      : ''} */}

                <div className="mb-2 se">
                  <span className='subheadsank'>खाते क्र.</span>

                  {DataSource && DataSource.length === 0 ?
                    <input disabled onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="knofrom" name="knofrom" value={input.knofrom} onChange={handleChange} ></input> :
                    DataSource[0].flag === "oldbill" ?
                      (DataSource[0]?.lastbill === "Yes" || DataSource[1]?.lastbill === "Yes" || DataSource[2]?.lastbill === "Yes" || DataSource[3]?.lastbill === "Yes" || DataSource[4]?.lastbill === "Yes") ?
                        <input onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="knofrom" name="knofrom" value={input.knofrom} onChange={handleChange} ></input>
                        : <input disabled onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="knofrom" name="knofrom" value={input.knofrom} onChange={handleChange} ></input>

                      : <input disabled onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="knofrom" name="knofrom" value={input.knofrom} onChange={handleChange} ></input>}

                  {/* = {DataSource && DataSource.length === 0} */}
                </div>

                <span className='subheadbill'>ते</span>

                <div className="mb-2 se">
                  <span className='subheadsank'>खाते क्र</span>

                  {DataSource && DataSource.length === 0 ?
                    <input disabled onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="text" className="form-control" autoComplete='off' id="knoto" name="knoto" value={input.knoto} onChange={handleChange} ></input> :
                    DataSource[0].flag === "oldbill" ?
                      (DataSource[0]?.lastbill === "Yes" || DataSource[1]?.lastbill === "Yes" || DataSource[2]?.lastbill === "Yes" || DataSource[3]?.lastbill === "Yes" || DataSource[4]?.lastbill === "Yes") ?
                        <input onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="text" className="form-control" autoComplete='off' id="knoto" name="knoto" value={input.knoto} onChange={handleChange} ></input>
                        : <input disabled onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="text" className="form-control" autoComplete='off' id="knoto" name="knoto" value={input.knoto} onChange={handleChange} ></input>

                      : <input disabled onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="text" className="form-control" autoComplete='off' id="knoto" name="knoto" value={input.knoto} onChange={handleChange} ></input>}


                </div>
                <div className="mb-2">
                  {
                    wait ?
                      <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">Loading..{count}</button>
                      :
                      input.knofrom === input.knoto ?
                        <button onClick={getbillsingle} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">खाते क्र {input.knofrom}  चे बील पहा</button>
                        :
                        <button onClick={handleIsfetching} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">बील पहा</button>
                  }
                  {input.knofrom !== input.knoto ?

                    wait ?
                      '' :
                      DataSource && DataSource.length === 0 ?
                        null :
                        (
                          DataSource && DataSource.length > 1 ?
                            waitsave ?
                              <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='save' className="btn btn-primary mx-1 sanksmall">
                                {
                                  "लोडिंग ..."
                                }
                              </button>
                              :
                              DataSource[0].flag === "newbill"
                                ?
                                <button onClick={fetchtabledata} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='save' className="btn btn-primary mx-1 sanksmall">
                                  {
                                    DataSource[0].flag === "oldbill" ? "बील अपडेट" :
                                      "बील सेव्ह"
                                  }
                                </button>
                                :
                                DataSource[0].flag === "oldbill" && showUpdate
                                  ?
                                  <button onClick={fetchtabledata} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='save' className="btn btn-primary mx-1 sanksmall">
                                    {
                                      DataSource[0].flag === "oldbill" ? "बील अपडेट" :
                                        "बील सेव्ह"
                                    }
                                  </button>
                                  : ''

                            : ''
                        )
                    : ''
                  }
                  {
                    DataSource && DataSource.length != 0 ?
                      '' :
                      <button onClick={handleprinttrial} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-success mx-1 sanksmall">ट्रायल प्रिंट्स</button>
                  }


                  {input.knofrom !== input.knoto ?

                    wait ?
                      '' :
                      DataSource && DataSource.length === 0 ?
                        '' :
                        (
                          DataSource && DataSource.length > 1 ?
                            wait2 ? (
                              <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">लोडिंग...</button>
                            ) : (
                              <button onClick={handlePrint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">बील प्रिंट</button>
                            )
                            : ''
                        )
                    : ''
                  }


                </div>
              </div>
              <div className="optionbill">

                <div className='newbill'>
                  <span>{DataSource && DataSource.length === 0 ? "नवीन बील" : DataSource[0].flag === "oldbill" ? "जूने बील" :
                    "नवीन बील"}</span>
                  {DataSource && DataSource.length === 0 ?
                    '' :
                    DataSource[0].flag === "oldbill" ?
                      (DataSource[0]?.lastbill === "Yes" || DataSource[1]?.lastbill === "Yes" || DataSource[2]?.lastbill === "Yes" || DataSource[3]?.lastbill === "Yes" || DataSource[4]?.lastbill === "Yes") ?
                        <UilTrash onClick={handleDelete} className="icdelete" />
                        : ''

                      : ''}
                </div>

                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" onChange={handleChangeactive} id="activestatus" />
                  <span className="checklbl" htmlFor="activestatus">
                    सर्व कॉलम्स
                  </span>
                </div>
                <div hidden className="form-check">
                  <input className="form-check-input" type="checkbox" value="" onChange={handleChangeactive} id="activestatus" />
                  <span className="checklbl" htmlFor="activestatus">
                    ठेव अधिक कॉलम्स
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="line"></div>

        </div>
        {/* {
          DataSource && DataSource.length === 0 ?
            '' :
            DataSource[0].flag === "oldbill" ?
              <input type="text" className="form-control search mx-2" id="searchbill" name="searchbill" placeholder='खाते क्रमांक शोधा' value={value} onChange={filteData} autoComplete='off' />
              :
              ''
        } */}
        <div className="firstrow2">
          <div class="table-containerbill">
            <table id="myTablebill" className="table table-bordered">
              <thead>
                <tr>
                  <th className='thsmall' scope="col">खाते क्रमांक</th>
                  <th className='thlarge' scope="col">नाव</th>
                  <th className='thsmall' scope="col">बील रक्कम</th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">ए.लिटर</th>
                  <th className={'thsmallhide'} scope="col">मागील ठेव</th>
                  <th className='thsmall' scope="col">ठेव कपात<span><br />(-)</span></th>
                  <th className={'thsmallhide'} scope="col">नवीन ठेव</th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">मागील एडव्हान्स बाकी</th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">चालू एडव्हान्स</th>
                  <th className='thsmall' scope="col">एकूण एडव्हान्स</th>
                  <th className='thsmall' scope="col"><UilEditAlt className="editlogo" />एडव्हान्स कपात<br /><span>(-)</span></th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmall'} scope="col">नवीन एडव्हान्स बाकी</th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">मागील खाद्य बाकी</th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">चालू खाद्य</th>
                  <th className='thsmall' scope="col">एकूण खाद्य</th>
                  <th className='thsmall' scope="col"><UilEditAlt className="editlogo" />खाद्य कपात<br /><span>(-)</span></th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmall'} scope="col">नवीन खाद्य बाकी</th>
                  <th className={'thsmallhide'} scope="col">इ.कपात/ली<br /><span>(-)</span></th>
                  <th className={'thsmall'} scope="col">इ.कपात<br /><span>(-)</span></th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">वाहतूक<br /><span>(+)</span></th>
                  <th className='thsmall' scope="col">अदा<br /><span>(=)</span></th>
                  {DataSource && DataSource.length === 0 ?
                    '' :
                    DataSource[0].flag === "oldbill" ?
                      input.knofrom === input.knoto ?
                        DataSource && DataSource.length === 1 ?
                          <th className='thsmall' scope="col">एक्शन</th>
                          : ''
                        : ''
                      : ''
                  }
                </tr>

              </thead>
              <tbody>
                {
                  value.length > 0 ? tableFilter.map((user, index) => {

                    let allthevtofixed = 0;
                    localStorage.getItem("thevper") === "No" ?
                      allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
                      : localStorage.getItem("thevper") === "Yes" ?
                        allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2)
                        :
                        allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)

                    let allthev = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev));
                    let billminusthev = parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed)).toFixed(2);
                    let totalkhadya = parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)

                    let khedit = user.flag === "oldbill" ?
                      (user.khadyakapat && user.khadyakapat !== '0' ? user.khadyakapat : 0) :

                      parseFloat(
                        // billminusthev
                        totalkhadya <= billminusthev ?
                          user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0' ? parseFloat(user.khadyahpta).toFixed(2)
                            :
                            user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                              parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                                parseFloat(billminusthev < totalkhadya ?
                                  billminusthev :
                                  totalkhadya)
                                - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                                : 0
                              :

                              billminusthev < totalkhadya ?
                                billminusthev :
                                totalkhadya

                          :
                          // parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                          parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                          ) -
                            parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                          ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                          ) -
                            parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                          ) < totalkhadya ?

                            parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                            ) -
                            parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                            + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)

                            :
                            parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                            ) -
                              billminusthev > 0 ?
                              parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                              : 0

                            )
                            + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                      ).toFixed(2);

                    //-------------------------------

                    let tadvance = parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2);
                    let advancehfta = user.advhpta && user.advhpta !== null && user.advhpta !== '0' && parseFloat(user.advhpta);

                    let advedit = user.flag === "oldbill" ?
                      (user.advkapat ? user.advkapat : 0) :
                      parseFloat(tadvance > 0 ?
                        parseFloat(advancehfta != 0 && advancehfta <= (billminusthev - khedit) ?
                          advancehfta <= tadvance ?
                            user.advhpta <= parseFloat(billminusthev - totalkhadya) ?
                              parseFloat(user.advhpta)
                              :
                              parseFloat(billminusthev - totalkhadya) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                            :
                            tadvance
                          :
                          billminusthev
                            >
                            parseFloat(
                              billminusthev < totalkhadya ?
                                billminusthev :
                                totalkhadya)
                            ?
                            parseFloat(
                              billminusthev
                              -
                              parseFloat(
                                billminusthev < totalkhadya ?
                                  billminusthev :
                                  totalkhadya))
                              <=
                              parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)

                              ?
                              parseFloat(parseFloat(
                                billminusthev -
                                parseFloat(
                                  billminusthev < totalkhadya ?
                                    billminusthev :
                                    totalkhadya)
                              )
                                -
                                parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                                +
                                parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                +
                                parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                              ).toFixed(2)
                              :

                              parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                            :
                            0)
                        :
                        0);

                    // updatedDataSource = [...DataSource];

                    const cuchalValue = parseFloat(user.cuchal);
                    const newadvValue = parseFloat(user.newadv);
                    const obalValue = parseFloat(user.obal);
                    const bcountValue = parseFloat(user.bcount);
                    const advhptaValue = parseFloat(user.advhpta);
                    const billValue = parseFloat(user.bill).toFixed(2);
                    const mhliterValue = parseFloat(user.mhliter);
                    const mhthevValue = parseFloat(user.mhthev);
                    const gayliterValue = parseFloat(user.gayliter);
                    const gaythevValue = parseFloat(user.gaythev);
                    const ckhadyaValue = parseFloat(user.ckhadya);
                    const newkhadyaValue = parseFloat(user.newkhadya);
                    const ikapatliter2Value = parseFloat(user.ikapatliter2);
                    const tliterValue = parseFloat(user.tliter);
                    const ikapatbill2Value = parseFloat(user.ikapatbill2);
                    const vah2Value = parseFloat(user.vah2);
                    const gayvahValue = parseFloat(user.gayvah);

                    const calculatedValue = parseFloat(
                      cuchalValue + (bcountValue > 0 ? newadvValue : obalValue)
                    ).toFixed(2);

                    const mhthevCalculation = (mhliterValue * mhthevValue).toFixed(2);
                    const gaythevCalculation = (gayliterValue * gaythevValue).toFixed(2);
                    const totalBillMinusThev = (billValue - parseFloat(mhthevCalculation) - parseFloat(gaythevCalculation)).toFixed(2);

                    const advhptaCheck = advhptaValue <= totalBillMinusThev ? advhptaValue : totalBillMinusThev;

                    const ckhadyaPlusNewKhadya = (ckhadyaValue + newkhadyaValue).toFixed(2);
                    const khadyaCheck = parseFloat(totalBillMinusThev) < parseFloat(ckhadyaPlusNewKhadya) ? totalBillMinusThev : ckhadyaPlusNewKhadya;

                    const finalCalculation = (
                      parseFloat(
                        totalBillMinusThev -
                        parseFloat(ikapatliter2Value * tliterValue || 0) -
                        parseFloat(ikapatbill2Value || 0) +
                        parseFloat(vah2Value * mhliterValue || 0) +
                        parseFloat(gayvahValue * gayliterValue || 0)
                      ).toFixed(2)
                    );

                    const resultValue = user.result && !isNaN(user.result)
                      ? parseFloat(user.result).toFixed(2)
                      : finalCalculation <= calculatedValue ? finalCalculation : calculatedValue;



                    if (updatedDataSource[index]) {
                      addOrUpdateCkhadya(user.kno, khedit);
                      addOrUpdateCuchal(user.kno, advedit);
                      // console.log("in 1");
                      updatedDataSource[index].action = khedit;

                      if (!khedit) {
                        khedit = 0;
                      }
                      updatedDataSource[index].khedit = khedit;
                      updatedDataSource[index].advedit = advedit;
                      if (first === "false") {
                        // console.log("in first time");
                        updatedDataSource[index].khedit = khedit;
                        updatedDataSource[index].advedit = advedit;
                        setfirst("true")
                      }
                    }

                    return (
                      <tr key={index}>
                        <td className='thsmall'>{user.kno}</td>
                        <td className='thsmall'>{user.name}</td>
                        <td className='thsmall2'>{parseFloat(user.bill).toFixed(2)}</td>
                        <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{user.tliter ? parseFloat(user.tliter).toFixed(2) : 0}</td>
                        <td className={'thsmallhide'}>
                          {
                            user.flag === "oldbill" ?
                              (user.magilthev ? parseFloat(user.magilthev).toFixed(2) : 0) :
                              parseFloat(user.bcount) > 0 ? parseFloat(user.newthev).toFixed(2) : parseFloat(user.othev).toFixed(2)
                          }</td>
                        <td className='thsmall2'>{
                          user.flag === "oldbill" ?
                            (user.cthev ? parseFloat(user.cthev).toFixed(2) : 0) :
                            localStorage.getItem("thevper") === "No" ?
                              allthevtofixed
                              :
                              parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2)
                        }</td>
                        <td className={'thsmallhide '}>
                          {
                            user.flag === "oldbill" ?
                              parseFloat(user.newthev) ? parseFloat(user.newthev).toFixed(2) : 0
                              :
                              localStorage.getItem("thevper") === "No" ?
                                parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newthev) : parseFloat(user.othev))).toFixed(2)
                                :
                                (parseFloat(parseFloat(user.bill * 10) / 100) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newthev) : parseFloat(user.othev))).toFixed(2)
                          }</td>
                        <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>
                          {
                            user.flag === "oldbill" ?
                              (user.newadv ? user.newadv : 0) :
                              parseFloat(user.bcount) > 0 ? parseFloat(user.newadv).toFixed(2) : parseFloat(user.obal).toFixed(2)
                          }</td>
                        <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{user.cuchal ? user.cuchal : 0}</td>
                        <td className='thsmall2'>
                          {
                            user.flag === "oldbill" ?
                              (user.tadv ? parseFloat(user.tadv).toFixed(2) : 0) :
                              parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                          }</td>
                        <td className='thsmall2edit'
                          contentEditable={
                            user.flag === "oldbill" ?
                              user.lastbill === "Yes" ?
                                'true'
                                : 'false'
                              : 'true'
                          }
                          onKeyDown={(e) => handleCellKeyPress(e, index)}
                          onBlur={(e) => handleCellBlur(e, index)}
                          onFocus={(e) => handleCellfocus(e, index)}
                          ref={(td) => {
                            if (!cellRefs.current[index]) {
                              cellRefs.current[index] = [];
                            }
                            cellRefs.current[index][0] = td; // Save the ref for the cell
                          }}
                          onInput={(e) => handleCellChange(e, index, user.kno)}>
                          {
                            parseFloat(advedit).toFixed(2)
                          }
                        </td>

                        <td className={showthevcol === "Yes" ? 'thsmallgreen' : 'thsmallgreen'} >
                          {user.flag === "oldbill" ? (
                            user.result != null && !isNaN(user.result) ? parseFloat(user.result).toFixed(2) : user.newadvcurrent != null && !isNaN(user.newadvcurrent) ? parseFloat(user.newadvcurrent).toFixed(2) : 0
                          )
                            :
                            tadvance > 0
                              ?
                              (
                                user.result != null && !isNaN(user.result)
                                  ?
                                  parseFloat(user.result).toFixed(2)
                                  :
                                  parseFloat(parseFloat(tadvance) - parseFloat(advedit)).toFixed(2)
                                // parseFloat(tadvance - (advancehfta != 0 && advancehfta <= billminusthev
                                //   ?
                                //   advancehfta <= tadvance ?
                                //     user.advhpta <= parseFloat(billminusthev - totalkhadya) ?
                                //       parseFloat(user.advhpta)
                                //       :
                                //       parseFloat(billminusthev - totalkhadya) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                //     :
                                //     tadvance
                                //   :
                                //   parseFloat(advancehfta <= billminusthev
                                //     ?
                                //     parseFloat(user.advhpta)
                                //     :
                                //     billminusthev
                                //       >
                                //       parseFloat(
                                //         billminusthev < totalkhadya
                                //           ?
                                //           billminusthev
                                //           :
                                //           parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya)
                                //             ?
                                //             (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2)
                                //             :
                                //             0))
                                //       ?
                                //       parseFloat(
                                //         billminusthev
                                //         -
                                //         parseFloat(
                                //           billminusthev < totalkhadya ?
                                //             billminusthev :
                                //             totalkhadya))
                                //         <=
                                //         tadvance
                                //         ?
                                //         tadvance > 0 ?
                                //           parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0'
                                //             ?
                                //             billminusthev < parseFloat(user.advhpta)
                                //               ?
                                //               billminusthev - parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)) : 0).toFixed(2) //problem was here
                                //               :
                                //               parseFloat(user.advhpta)

                                //             :
                                //             billminusthev
                                //               >
                                //               parseFloat(
                                //                 billminusthev < totalkhadya ?
                                //                   billminusthev :
                                //                   totalkhadya)

                                //               ?
                                //               parseFloat(
                                //                 billminusthev
                                //                 -
                                //                 parseFloat(
                                //                   billminusthev < totalkhadya ?
                                //                     billminusthev :
                                //                     totalkhadya))
                                //                 <=
                                //                 tadvance

                                //                 ?
                                //                 parseFloat(
                                //                   parseFloat(
                                //                     billminusthev -
                                //                     parseFloat(
                                //                       billminusthev < totalkhadya ?
                                //                         billminusthev :
                                //                         totalkhadya)
                                //                   )
                                //                   -
                                //                   parseFloat(parseFloat(user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                                //                   +
                                //                   parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                //                   +
                                //                   parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                //                 ).toFixed(2)
                                //                 :
                                //                 tadvance
                                //               :
                                //               0)
                                //           :
                                //           0
                                //         :
                                //         tadvance
                                //       :
                                //       0)
                                // )).toFixed(2)
                              )
                              :
                              tadvance}
                        </td>

                        <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{
                          user.flag === "oldbill" ?
                            (user.mkhadya ? parseFloat(user.mkhadya).toFixed(2) : 0) :
                            user.newkhadya ? parseFloat(user.newkhadya).toFixed(2) : 0

                        }</td>
                        <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{user.ckhadya ? parseFloat(user.ckhadya).toFixed(2) : 0}</td>
                        <td className='thsmall2'>
                          {
                            user.flag === "oldbill" ?
                              parseFloat(user.ckhadya) + parseFloat(user.mkhadya) ? parseFloat(parseFloat(user.ckhadya) + parseFloat(user.mkhadya)).toFixed(2) : 0
                              :
                              parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0

                          }
                        </td>
                        <td className='thsmall2edit'
                          contentEditable={
                            user.flag === "oldbill" ?
                              user.lastbill === "Yes" ?
                                'true'
                                : 'false'
                              : 'true'
                          }
                          onKeyDown={(e) => handleCellKeyPress12(e, index)}
                          onBlur={(e) => handleCellBlurkh(e, index)}
                          ref={(td) => {
                            if (!cellRefs.current[index]) {
                              cellRefs.current[index] = [];
                            }
                            cellRefs.current[index][1] = td; // Save the ref for the cell in column 12
                          }}
                          onInput={(e) => handleCellChangekh(e, index, user.kno)}>
                          {
                            parseFloat(khedit).toFixed(2)
                          }
                        </td>
                        <td className={showthevcol === "Yes" ? 'thsmallgreen' : 'thsmallgreen'}>
                          {
                            user.flag === "oldbill" ?
                              (user.result2 != null && !isNaN(user.result2) ? parseFloat(user.result2).toFixed(2) : user.newkhadyacurrent != null && !isNaN(user.newkhadyacurrent) ? parseFloat(user.newkhadyacurrent).toFixed(2) : 0) :
                              user.result2 != null && !isNaN(user.result2) ? parseFloat(user.result2).toFixed(2) : (parseFloat(user.ckhadya) + parseFloat(user.newkhadya) -
                                (parseFloat(
                                  // billminusthev
                                  totalkhadya <= billminusthev ?
                                    user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0' ? parseFloat(user.khadyahpta).toFixed(2)
                                      :
                                      user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                                        parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                                          parseFloat(billminusthev < totalkhadya ?
                                            billminusthev :
                                            totalkhadya)
                                          - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                                          : 0
                                        :
                                        billminusthev < totalkhadya ?
                                          billminusthev :
                                          totalkhadya

                                    :
                                    //parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                                    parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                                    ) -
                                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                                    ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                                    ) -
                                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                                    ) < totalkhadya ?

                                      parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                                      ) -
                                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                                      + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                      :
                                      parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                                      ) -
                                        billminusthev > 0 ?
                                        parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                                        : 0
                                        + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                        + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                      )

                                ).toFixed(2)
                                )).toFixed(2)
                          }
                        </td>
                        <td className={'thsmallhide'}>
                          {
                            user.flag === "oldbill" ?
                              (user.ikapatliter ? parseFloat(user.ikapatliter) : 0) :
                              (user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter).toFixed(2) : 0)
                          }
                        </td>
                        {/* <td className={'thsmall2edit'}>
                            {
                              user.flag === "oldbill" ?
                                (user.ikapatbill ? parseFloat(user.ikapatbill) : 0) :
                                (user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)
                            }
                          </td> */}

                        <td className='thsmall2edit'
                          contentEditable=
                          {
                            true
                            // user.flag === "oldbill" ?
                            //   user.lastbill === "Yes" ?
                            //     'true'
                            //     : 'false'
                            //   : 'true'
                          }
                          onKeyDown={(e) => handleCellKeyPress12(e, index)}
                          onBlur={(e) => handleCellBlurikapat(e, index)}
                          ref={(td) => {
                            if (!cellRefs.current[index]) {
                              cellRefs.current[index] = [];
                            }
                            cellRefs.current[index][1] = td; // Save the ref for the cell in column 12
                          }}
                          onInput={(e) => handleCellChangeikapat(e, index, user.kno)}>
                          {
                            user.flag === "oldbill" ?
                              (user.ikapatbill ? parseFloat(user.ikapatbill) : 0) :
                              (user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)
                          }
                        </td>

                        <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>
                          {
                            user.flag === "oldbill" ?
                              (user.vah ? parseFloat(user.vah) : 0) :
                              parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter).toFixed(2) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter).toFixed(2) : 0)
                          }
                        </td>
                        <td className='thsmall2'>
                          {
                            user.flag === "oldbill" ?
                              (user.adacalculated != null && !isNaN(user.adacalculated)) ? parseFloat(user.adacalculated).toFixed(2) : (user.ada ? parseFloat(user.ada).toFixed(2) : 0) :
                              (user.adacalculated != null && !isNaN(user.adacalculated)) ? parseFloat(user.adacalculated).toFixed(2) : user.bill
                                ?
                                parseFloat(parseFloat(user.bill)
                                  -
                                  allthevtofixed +
                                  + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                  + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                  - khedit
                                  - advedit
                                  - parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                                ).toFixed(2) : 0
                          }
                        </td>
                      </tr>
                    );
                  }
                  )
                    :
                    DataSource && DataSource.map((user, index) => {

                      // let allthevtofixed = 0;
                      // localStorage.getItem("thevper") === "No" ?
                      //   allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
                      //   :
                      //   allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2);

                      let allthevtofixed = 0;
                      localStorage.getItem("thevper") === "No" ?
                        allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
                        : localStorage.getItem("thevper") === "Yes" ?
                          allthevtofixed = parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2)
                          :
                          allthevtofixed = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)


                      let allthev = parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev));
                      let billminusthev = parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed)).toFixed(2);
                      let totalkhadya = parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)

                      let khedit = user.flag === "oldbill" ?
                        (user.khadyakapat && user.khadyakapat !== '0' ? user.khadyakapat : 0) :

                        parseFloat(
                          // billminusthev
                          totalkhadya <= billminusthev ?
                            user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0' ? parseFloat(user.khadyahpta).toFixed(2)
                              :
                              user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                                parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                                  parseFloat(billminusthev < totalkhadya ?
                                    billminusthev :
                                    totalkhadya)
                                  - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                                  : 0
                                :

                                billminusthev < totalkhadya ?
                                  billminusthev :
                                  totalkhadya

                            :
                            // parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                            parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                            ) -
                              parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                            ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                            ) -
                              parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                            ) < totalkhadya ?

                              parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                              ) -
                              parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                              + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)

                              :
                              parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                              ) -
                                billminusthev > 0 ?
                                parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                                : 0

                              )
                              + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                        ).toFixed(2);

                      //-------------------------------

                      let tadvance = parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2);
                      let advancehfta = user.advhpta && user.advhpta !== null && user.advhpta !== '0' && parseFloat(user.advhpta);

                      let advedit = user.flag === "oldbill" ?
                        (user.advkapat ? user.advkapat : 0) :
                        parseFloat(tadvance > 0 ?
                          parseFloat(advancehfta != 0 && advancehfta <= (billminusthev - khedit) ?
                            advancehfta <= tadvance ?
                              user.advhpta <= parseFloat(billminusthev - totalkhadya) ?
                                parseFloat(user.advhpta)
                                :
                                parseFloat(billminusthev - totalkhadya) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                              :
                              tadvance
                            :
                            billminusthev
                              >
                              parseFloat(
                                billminusthev < totalkhadya ?
                                  billminusthev :
                                  totalkhadya)
                              ?
                              parseFloat(
                                billminusthev
                                -
                                parseFloat(
                                  billminusthev < totalkhadya ?
                                    billminusthev :
                                    totalkhadya))
                                <=
                                parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)

                                ?
                                parseFloat(parseFloat(
                                  billminusthev -
                                  parseFloat(
                                    billminusthev < totalkhadya ?
                                      billminusthev :
                                      totalkhadya)
                                )
                                  -
                                  parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                                  +
                                  parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                  +
                                  parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                ).toFixed(2)
                                :

                                parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                              :
                              0)
                          :
                          0);

                      // updatedDataSource = [...DataSource];

                      const cuchalValue = parseFloat(user.cuchal);
                      const newadvValue = parseFloat(user.newadv);
                      const obalValue = parseFloat(user.obal);
                      const bcountValue = parseFloat(user.bcount);
                      const advhptaValue = parseFloat(user.advhpta);
                      const billValue = parseFloat(user.bill).toFixed(2);
                      const mhliterValue = parseFloat(user.mhliter);
                      const mhthevValue = parseFloat(user.mhthev);
                      const gayliterValue = parseFloat(user.gayliter);
                      const gaythevValue = parseFloat(user.gaythev);
                      const ckhadyaValue = parseFloat(user.ckhadya);
                      const newkhadyaValue = parseFloat(user.newkhadya);
                      const ikapatliter2Value = parseFloat(user.ikapatliter2);
                      const tliterValue = parseFloat(user.tliter);
                      const ikapatbill2Value = parseFloat(user.ikapatbill2);
                      const vah2Value = parseFloat(user.vah2);
                      const gayvahValue = parseFloat(user.gayvah);

                      const calculatedValue = parseFloat(
                        cuchalValue + (bcountValue > 0 ? newadvValue : obalValue)
                      ).toFixed(2);

                      const mhthevCalculation = (mhliterValue * mhthevValue).toFixed(2);
                      const gaythevCalculation = (gayliterValue * gaythevValue).toFixed(2);
                      const totalBillMinusThev = (billValue - parseFloat(mhthevCalculation) - parseFloat(gaythevCalculation)).toFixed(2);

                      const advhptaCheck = advhptaValue <= totalBillMinusThev ? advhptaValue : totalBillMinusThev;

                      const ckhadyaPlusNewKhadya = (ckhadyaValue + newkhadyaValue).toFixed(2);
                      const khadyaCheck = parseFloat(totalBillMinusThev) < parseFloat(ckhadyaPlusNewKhadya) ? totalBillMinusThev : ckhadyaPlusNewKhadya;

                      const finalCalculation = (
                        parseFloat(
                          totalBillMinusThev -
                          parseFloat(ikapatliter2Value * tliterValue || 0) -
                          parseFloat(ikapatbill2Value || 0) +
                          parseFloat(vah2Value * mhliterValue || 0) +
                          parseFloat(gayvahValue * gayliterValue || 0)
                        ).toFixed(2)
                      );

                      const resultValue = user.result && !isNaN(user.result)
                        ? parseFloat(user.result).toFixed(2)
                        : finalCalculation <= calculatedValue ? finalCalculation : calculatedValue;



                      if (updatedDataSource[index]) {
                        addOrUpdateCkhadya(user.kno, khedit);
                        addOrUpdateCuchal(user.kno, advedit);
                        // console.log("in 1");
                        updatedDataSource[index].action = khedit;

                        if (!khedit) {
                          khedit = 0;
                        }
                        updatedDataSource[index].khedit = khedit;
                        updatedDataSource[index].advedit = advedit;
                        if (first === "false") {
                          // console.log("in first time");
                          updatedDataSource[index].khedit = khedit;
                          updatedDataSource[index].advedit = advedit;
                          setfirst("true")
                        }
                      }

                      return (
                        <tr key={index}>
                          <td className='thsmall'>{user.kno}</td>
                          <td className='thsmall'>{user.name}</td>
                          <td className='thsmall2'>{parseFloat(user.bill).toFixed(2)}</td>
                          <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{user.tliter ? parseFloat(user.tliter).toFixed(2) : 0}</td>
                          <td className={'thsmallhide'}>
                            {
                              user.flag === "oldbill" ?
                                (user.magilthev ? parseFloat(user.magilthev).toFixed(2) : 0) :
                                parseFloat(user.bcount) > 0 ? parseFloat(user.newthev).toFixed(2) : parseFloat(user.othev).toFixed(2)
                            }</td>
                          <td className='thsmall2'>{
                            user.flag === "oldbill" ?
                              (user.cthev ? parseFloat(user.cthev).toFixed(2) : 0) :
                              localStorage.getItem("thevper") === "No" ?
                                allthevtofixed
                                :
                                parseFloat(parseFloat(user.bill * 10) / 100).toFixed(2)
                          }</td>
                          <td className={'thsmallhide '}>
                            {
                              user.flag === "oldbill" ?
                                parseFloat(user.newthev) ? parseFloat(user.newthev).toFixed(2) : 0
                                :
                                localStorage.getItem("thevper") === "No" ?
                                  parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newthev) : parseFloat(user.othev))).toFixed(2)
                                  :
                                  (parseFloat(parseFloat(user.bill * 10) / 100) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newthev) : parseFloat(user.othev))).toFixed(2)
                            }</td>
                          <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>
                            {
                              user.flag === "oldbill" ?
                                (user.newadv ? user.newadv : 0) :
                                parseFloat(user.bcount) > 0 ? parseFloat(user.newadv).toFixed(2) : parseFloat(user.obal).toFixed(2)
                            }</td>
                          <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{user.cuchal ? user.cuchal : 0}</td>
                          <td className='thsmall2'>
                            {
                              user.flag === "oldbill" ?
                                (user.tadv ? parseFloat(user.tadv).toFixed(2) : 0) :
                                parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                            }</td>
                          <td className='thsmall2edit'
                            contentEditable={
                              user.flag === "oldbill" ?
                                user.lastbill === "Yes" ?
                                  'true'
                                  : 'false'
                                : 'true'
                            }
                            onKeyDown={(e) => handleCellKeyPress(e, index)}
                            onBlur={(e) => handleCellBlur(e, index)}
                            onFocus={(e) => handleCellfocus(e, index)}
                            ref={(td) => {
                              if (!cellRefs.current[index]) {
                                cellRefs.current[index] = [];
                              }
                              cellRefs.current[index][0] = td; // Save the ref for the cell
                            }}
                            onInput={(e) => handleCellChange(e, index, user.kno)}>
                            {
                              parseFloat(advedit).toFixed(2)
                            }
                          </td>

                          <td className={showthevcol === "Yes" ? 'thsmallgreen' : 'thsmallgreen'} >
                            {user.flag === "oldbill" ? (
                              user.result != null && !isNaN(user.result) ? parseFloat(user.result).toFixed(2) : user.newadvcurrent != null && !isNaN(user.newadvcurrent) ? parseFloat(user.newadvcurrent).toFixed(2) : 0
                            )
                              :
                              tadvance > 0
                                ?
                                (
                                  user.result != null && !isNaN(user.result)
                                    ?
                                    parseFloat(user.result).toFixed(2)
                                    :
                                    parseFloat(parseFloat(tadvance) - parseFloat(advedit)).toFixed(2)
                                  // parseFloat(tadvance - (advancehfta != 0 && advancehfta <= billminusthev
                                  //   ?
                                  //   advancehfta <= tadvance ?
                                  //     user.advhpta <= parseFloat(billminusthev - totalkhadya) ?
                                  //       parseFloat(user.advhpta)
                                  //       :
                                  //       parseFloat(billminusthev - totalkhadya) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                  //     :
                                  //     tadvance
                                  //   :
                                  //   parseFloat(advancehfta <= billminusthev
                                  //     ?
                                  //     parseFloat(user.advhpta)
                                  //     :
                                  //     billminusthev
                                  //       >
                                  //       parseFloat(
                                  //         billminusthev < totalkhadya
                                  //           ?
                                  //           billminusthev
                                  //           :
                                  //           parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya)
                                  //             ?
                                  //             (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2)
                                  //             :
                                  //             0))
                                  //       ?
                                  //       parseFloat(
                                  //         billminusthev
                                  //         -
                                  //         parseFloat(
                                  //           billminusthev < totalkhadya ?
                                  //             billminusthev :
                                  //             totalkhadya))
                                  //         <=
                                  //         tadvance
                                  //         ?
                                  //         tadvance > 0 ?
                                  //           parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0'
                                  //             ?
                                  //             billminusthev < parseFloat(user.advhpta)
                                  //               ?
                                  //               billminusthev - parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)) : 0).toFixed(2) //problem was here
                                  //               :
                                  //               parseFloat(user.advhpta)

                                  //             :
                                  //             billminusthev
                                  //               >
                                  //               parseFloat(
                                  //                 billminusthev < totalkhadya ?
                                  //                   billminusthev :
                                  //                   totalkhadya)

                                  //               ?
                                  //               parseFloat(
                                  //                 billminusthev
                                  //                 -
                                  //                 parseFloat(
                                  //                   billminusthev < totalkhadya ?
                                  //                     billminusthev :
                                  //                     totalkhadya))
                                  //                 <=
                                  //                 tadvance

                                  //                 ?
                                  //                 parseFloat(
                                  //                   parseFloat(
                                  //                     billminusthev -
                                  //                     parseFloat(
                                  //                       billminusthev < totalkhadya ?
                                  //                         billminusthev :
                                  //                         totalkhadya)
                                  //                   )
                                  //                   -
                                  //                   parseFloat(parseFloat(user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                                  //                   +
                                  //                   parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                  //                   +
                                  //                   parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                  //                 ).toFixed(2)
                                  //                 :
                                  //                 tadvance
                                  //               :
                                  //               0)
                                  //           :
                                  //           0
                                  //         :
                                  //         tadvance
                                  //       :
                                  //       0)
                                  // )).toFixed(2)
                                )
                                :
                                tadvance}
                          </td>

                          <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{
                            user.flag === "oldbill" ?
                              (user.mkhadya ? parseFloat(user.mkhadya).toFixed(2) : 0) :
                              user.newkhadya ? parseFloat(user.newkhadya).toFixed(2) : 0

                          }</td>
                          <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{user.ckhadya ? parseFloat(user.ckhadya).toFixed(2) : 0}</td>
                          <td className='thsmall2'>
                            {
                              user.flag === "oldbill" ?
                                parseFloat(user.ckhadya) + parseFloat(user.mkhadya) ? parseFloat(parseFloat(user.ckhadya) + parseFloat(user.mkhadya)).toFixed(2) : 0
                                :
                                parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0

                            }
                          </td>
                          <td className='thsmall2edit'
                            contentEditable={
                              user.flag === "oldbill" ?
                                user.lastbill === "Yes" ?
                                  'true'
                                  : 'false'
                                : 'true'
                            }
                            onKeyDown={(e) => handleCellKeyPress12(e, index)}
                            onBlur={(e) => handleCellBlurkh(e, index)}
                            ref={(td) => {
                              if (!cellRefs.current[index]) {
                                cellRefs.current[index] = [];
                              }
                              cellRefs.current[index][1] = td; // Save the ref for the cell in column 12
                            }}
                            onInput={(e) => handleCellChangekh(e, index, user.kno)}>
                            {
                              parseFloat(khedit).toFixed(2)
                            }
                          </td>
                          <td className={showthevcol === "Yes" ? 'thsmallgreen' : 'thsmallgreen'}>
                            {
                              user.flag === "oldbill" ?
                                (user.result2 != null && !isNaN(user.result2) ? parseFloat(user.result2).toFixed(2) : user.newkhadyacurrent != null && !isNaN(user.newkhadyacurrent) ? parseFloat(user.newkhadyacurrent).toFixed(2) : 0) :
                                user.result2 != null && !isNaN(user.result2) ? parseFloat(user.result2).toFixed(2) : (parseFloat(user.ckhadya) + parseFloat(user.newkhadya) -
                                  (parseFloat(
                                    // billminusthev
                                    totalkhadya <= billminusthev ?
                                      user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0' ? parseFloat(user.khadyahpta).toFixed(2)
                                        :
                                        user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                                          parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                                            parseFloat(billminusthev < totalkhadya ?
                                              billminusthev :
                                              totalkhadya)
                                            - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                                            : 0
                                          :
                                          billminusthev < totalkhadya ?
                                            billminusthev :
                                            totalkhadya

                                      :
                                      //parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                                      parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                                      ) -
                                        parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                                      ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                                      ) -
                                        parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                                      ) < totalkhadya ?

                                        parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                                        ) -
                                        parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                                        + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                        :
                                        parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(allthevtofixed))
                                        ) -
                                          billminusthev > 0 ?
                                          parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                                          : 0
                                          + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                          + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                        )

                                  ).toFixed(2)
                                  )).toFixed(2)
                            }
                          </td>
                          <td className={'thsmallhide'}>
                            {
                              user.flag === "oldbill" ?
                                (user.ikapatliter ? parseFloat(user.ikapatliter) : 0) :
                                (user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter).toFixed(2) : 0)
                            }
                          </td>
                          {/* <td className={'thsmall2edit'}>
                            {
                              user.flag === "oldbill" ?
                                (user.ikapatbill ? parseFloat(user.ikapatbill) : 0) :
                                (user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)
                            }
                          </td> */}

                          <td className='thsmall2edit'
                            contentEditable=
                            {
                              true
                              // user.flag === "oldbill" ?
                              //   user.lastbill === "Yes" ?
                              //     'true'
                              //     : 'false'
                              //   : 'true'
                            }
                            onKeyDown={(e) => handleCellKeyPress12(e, index)}
                            onBlur={(e) => handleCellBlurikapat(e, index)}
                            ref={(td) => {
                              if (!cellRefs.current[index]) {
                                cellRefs.current[index] = [];
                              }
                              cellRefs.current[index][1] = td; // Save the ref for the cell in column 12
                            }}
                            onInput={(e) => handleCellChangeikapat(e, index, user.kno)}>
                            {
                              user.flag === "oldbill" ?
                                (user.ikapatbill ? parseFloat(user.ikapatbill) : 0) :
                                (user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)
                            }
                          </td>

                          <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>
                            {
                              user.flag === "oldbill" ?
                                (user.vah ? parseFloat(user.vah) : 0) :
                                parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter).toFixed(2) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter).toFixed(2) : 0)
                            }
                          </td>
                          <td className='thsmall2'>
                            {
                              user.flag === "oldbill" ?
                                (user.adacalculated != null && !isNaN(user.adacalculated)) ? parseFloat(user.adacalculated).toFixed(2) : (user.ada ? parseFloat(user.ada).toFixed(2) : 0) :
                                (user.adacalculated != null && !isNaN(user.adacalculated)) ? parseFloat(user.adacalculated).toFixed(2) : user.bill
                                  ?
                                  parseFloat(parseFloat(user.bill)
                                    -
                                    allthevtofixed +
                                    + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                    + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                    - khedit
                                    - advedit
                                    - parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                                  ).toFixed(2) : 0
                            }
                          </td>
                          {DataSource && DataSource.length === 0 ?
                            '' :
                            DataSource[0].flag === "oldbill" ?
                              input.knofrom === input.knoto ?
                                DataSource && DataSource.length === 1 ?
                                  <td><UilRefresh onClick={() => { handleDeletesingle() }} className="ic2" /></td>
                                  : ''
                                : ''
                              : ''
                          }

                        </tr>
                      );
                    }
                    )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Bill

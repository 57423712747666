import React, { useEffect, useState } from 'react';
import './Khadyaadvreportprint.css';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";

Font.register({
    family: 'Lato-Italic',
    src: LatoItalicFont, // Use the imported font variable
});

const Khadyaadvreportprint = () => {
    const [customerData, setCustomerData] = useState({});
    const [literTotal, setLiterTotal] = useState(0);
    const [amtTotal, setAmtTotal] = useState(0);
    const [PreviousAmt, setPreviousAmt] = useState();
    let totals = {};

    useEffect(() => {
        //   console.log(localStorage.getItem("khrtype"));
        let phplanding = "";
        if (localStorage.getItem("khrtype") === "khpur") {
            phplanding = "getkhpurreport.php";
        }
        else if (localStorage.getItem("khrtype") === "khsale") {
            phplanding = "getkhsalereport.php";
        }
        else if (localStorage.getItem("khrtype") === "adv") {
            phplanding = "getadvreport.php";
        }
        else if (localStorage.getItem("khrtype") === "udhari") {
            phplanding = "getudhari.php";
        }
        else if (localStorage.getItem("khrtype") === "stock") {
            phplanding = "getkhstock.php";
        }
        else if (localStorage.getItem("khrtype") === "datestock") {
            phplanding = "getkhstockbydate.php";
        }
        else if (localStorage.getItem("khrtype") === "dreport") {
            phplanding = "getdreport.php";
        }
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            date1: localStorage.getItem("date1print"),
            date2: localStorage.getItem("date2print"),
            kno: localStorage.getItem("khrtype") === "dreport" ?  localStorage.getItem("dno") : localStorage.getItem("kno"),
            partyname: localStorage.getItem("partyname"),
            khname: localStorage.getItem("khname"),
            mainuser: localStorage.getItem("contact"),
            billtype: localStorage.getItem("bill10type"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${phplanding}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
          //  console.log(response.data);
            setCustomerData(response.data);
            if (localStorage.getItem("khrtype") === "dreport") {
                setPreviousAmt(response.data[0].previousamt);
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const getDatesInRange = (date1, date2) => {
        const dates = [];
        const currentDate = new Date(date1);

        while (currentDate <= new Date(date2)) {
            dates.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    function formatDate(inputDate) {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(inputDate).toLocaleDateString('en-US', options);
    }

    if (localStorage.getItem("khrtype") === "khpur" || localStorage.getItem("khrtype") === "khsale") {
        if (Array.isArray(customerData) && customerData.length > 0) {
            totals = {
                tquin: customerData.reduce((total, rowData) => total + parseFloat(rowData.quin), 0),
                tamt: customerData.reduce((total, rowData) => total + parseFloat(rowData.amt), 0),
            };
        }
    }
    else if (localStorage.getItem("khrtype") === "adv") {
        if (Array.isArray(customerData) && customerData.length > 0) {
            totals = {
                tamt: customerData.reduce((total, rowData) => total + parseFloat(rowData.amt), 0),
            };
        }
    }
    else if (localStorage.getItem("khrtype") === "udhari" && localStorage.getItem("bill10type") === "1") {
        if (Array.isArray(customerData) && customerData.length > 0) {
            totals = {
                tamtadv: customerData.reduce((total, rowData) => total + parseFloat(rowData.newadv), 0),
                tamtkhadya: customerData.reduce((total, rowData) => total + parseFloat(rowData.newkhadya), 0),
                tamt: customerData.reduce((total, rowData) => total + (parseFloat(rowData.newadv) + parseFloat(rowData.newkhadya)), 0),
            };
        }
    }
    else if (localStorage.getItem("khrtype") === "udhari" && localStorage.getItem("bill10type") === "0") {
        if (Array.isArray(customerData) && customerData.length > 0) {
            totals = {
                tamtud: customerData.reduce((total, rowData) => total + parseFloat(rowData.tbaki), 0),
            };
        }
    }
    else if (localStorage.getItem("khrtype") === "stock") {
        if (Array.isArray(customerData) && customerData.length > 0) {
            totals = {
                pur: customerData.reduce((total, rowData) => total + parseFloat(rowData.total_purchase_qty), 0),
                sale: customerData.reduce((total, rowData) => total + parseFloat(rowData.total_sale_qty), 0),
                stock: customerData.reduce((total, rowData) => total + parseFloat(rowData.stock), 0),
            };
        }
    }
    else if (localStorage.getItem("khrtype") === "dreport") {
        if (Array.isArray(customerData) && customerData.length > 0) {
            totals = {
                quin: customerData.reduce((total, rowData) => total + parseFloat(rowData.quin), 0),
                tamt: parseFloat(
                    customerData.reduce((total, user) => {
                        if (user.trantype === "नावे") {
                            return total + parseFloat(user.amt);
                        } else {
                            return total - parseFloat(user.amt);
                        }
                    }, 0) + parseFloat(PreviousAmt)
                ).toFixed(2)
            };
        }
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };
    return (
        <PDFViewer className='pdf'>
            <Document>

                {customerData.length > 0 ? (
                    localStorage.getItem("khrtype") === "khpur"
                        ?
                        <Page size="A4">
                            <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                            <Text style={styles.latoText}>{"खाद्य खरेदी रिपोर्ट"}</Text>
                            <Text style={styles.latoText2}>{"कालावधी : " + formatDate(localStorage.getItem("date1print")) + "   ते   " + formatDate(localStorage.getItem("date2print"))}</Text>
                            <View style={styles.imagerow}>
                                <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                <Image style={styles.image} src={Logo} />
                            </View>

                            <View style={styles.table}>
                                {/* Table Header */}
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableHeader}>{"दिनांक"}</Text>
                                    <Text style={styles.tableHeader}>{"खाद्याचे नाव"}</Text>
                                    <Text style={styles.tableHeader}>{"नग"}</Text>
                                    <Text style={styles.tableHeader}>{"दर"}</Text>
                                    <Text style={styles.tableHeaderlast}>{"रक्कम"}</Text>
                                </View>
                                {customerData.map((rowData, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <Text style={styles.tableCell}>{rowData.date}</Text>
                                        <Text style={styles.tableCell}>{rowData.khname}</Text>
                                        <Text style={styles.tableCell}>{rowData.quin}</Text>
                                        <Text style={styles.tableCell}>{rowData.rate}</Text>
                                        <Text style={styles.tableCell}>{rowData.amt}</Text>

                                        {/* Add more data cells as needed */}
                                    </View>
                                ))}
                                <View style={styles.tableRow3}>
                                    <Text style={styles.tableHeader}>{"एकूण"}</Text>
                                    <Text style={styles.tableHeader}>{"-"}</Text>
                                    <Text style={styles.tableHeader}>{totals.tquin}</Text>
                                    <Text style={styles.tableHeader}>{"-"}</Text>
                                    <Text style={styles.tableHeaderlast}>{totals.tamt}</Text>
                                </View>
                            </View>

                        </Page>
                        :
                        localStorage.getItem("khrtype") === "khsale" ?
                            <Page size="A4" >
                                <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                                <Text style={styles.latoText}>{"खाद्य विक्री रिपोर्ट"}</Text>
                                <Text style={styles.latoText2}>{"कालावधी : " + formatDate(localStorage.getItem("date1print")) + "   ते   " + formatDate(localStorage.getItem("date2print"))}</Text>
                                <View style={styles.imagerow}>
                                    <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                    <Image style={styles.image} src={Logo} />
                                </View>

                                <View style={styles.table}>
                                    {/* Table Header */}
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableHeader}>{"दिनांक"}</Text>
                                        <Text style={styles.tableHeader}>{"खाते/नाव"}</Text>
                                        <Text style={styles.tableHeader}>{"खाद्याचे नाव"}</Text>
                                        <Text style={styles.tableHeader}>{"नग"}</Text>
                                        <Text style={styles.tableHeader}>{"दर"}</Text>
                                        <Text style={styles.tableHeaderlast}>{"रक्कम"}</Text>
                                    </View>
                                    {customerData.map((rowData, index) => (
                                        <View key={index} style={styles.tableRow}>
                                            <Text style={styles.tableCell}>{rowData.date}</Text>
                                            <Text style={styles.tableCell}>{rowData.cname}</Text>
                                            <Text style={styles.tableCell}>{rowData.khname}</Text>
                                            <Text style={styles.tableCell}>{rowData.quin}</Text>
                                            <Text style={styles.tableCell}>{rowData.rate}</Text>
                                            <Text style={styles.tableCell}>{rowData.amt}</Text>
                                        </View>
                                    ))}

                                    <View style={styles.tableRow3}>
                                        <Text style={styles.tableHeader}>{"एकूण"}</Text>
                                        <Text style={styles.tableHeader}>{"-"}</Text>
                                        <Text style={styles.tableHeader}>{"-"}</Text>
                                        <Text style={styles.tableHeader}>{totals.tquin}</Text>
                                        <Text style={styles.tableHeader}>{"-"}</Text>
                                        <Text style={styles.tableHeaderlast}>{totals.tamt}</Text>
                                    </View>
                                </View>

                            </Page>
                            :
                            localStorage.getItem("khrtype") === "stock" ?
                                <Page size="A4" >
                                    <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                                    <Text style={styles.latoText}>{"खाद्य स्टॉक रिपोर्ट"}</Text>
                                    <View style={styles.imagerow}>
                                        <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                        <Image style={styles.image} src={Logo} />
                                    </View>

                                    <View style={styles.table}>
                                        {/* Table Header */}
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableHeader}>{"क्रमांक"}</Text>
                                            <Text style={styles.tableHeader}>{"खाद्य नाव"}</Text>
                                            <Text style={styles.tableHeader}>{"कंपनी"}</Text>
                                            <Text style={styles.tableHeader}>{"ए.खरेदी"}</Text>
                                            <Text style={styles.tableHeader}>{"ए.विक्री"}</Text>
                                            <Text style={styles.tableHeaderlast}>{"स्टॉक"}</Text>
                                        </View>
                                        {customerData.map((rowData, index) => (
                                            <View key={index} style={styles.tableRow}>
                                                <Text style={styles.tableCell}>{index + 1}</Text>
                                                <Text style={styles.tableCell}>{rowData.khname}</Text>
                                                <Text style={styles.tableCell}>{rowData.khcompany}</Text>
                                                <Text style={styles.tableCell}>{rowData.total_purchase_qty}</Text>
                                                <Text style={styles.tableCell}>{rowData.total_sale_qty}</Text>
                                                <Text style={styles.tableCell}>{rowData.stock}</Text>
                                            </View>
                                        ))}

                                        <View style={styles.tableRow3}>
                                            <Text style={styles.tableHeader}>{"एकूण"}</Text>
                                            <Text style={styles.tableHeader}>{"-"}</Text>
                                            <Text style={styles.tableHeader}>{"-"}</Text>
                                            <Text style={styles.tableHeader}>{totals.pur}</Text>
                                            <Text style={styles.tableHeader}>{totals.sale}</Text>
                                            <Text style={styles.tableHeaderlast}>{totals.stock}</Text>
                                        </View>
                                    </View>

                                </Page>

                                :
                                localStorage.getItem("khrtype") === "datestock" ?
                                <Page size="A4" >
                                    <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                                    <Text style={styles.latoText}>{"खाद्य स्टॉक रिपोर्ट (" + localStorage.getItem("khname") + ")"}</Text>
                                    <View style={styles.imagerow}>
                                        <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                        <Image style={styles.image} src={Logo} />
                                    </View>

                                    <View style={styles.table}>
                                        {/* Table Header */}
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableHeader}>{"दिनांक"}</Text>
                                            <Text style={styles.tableHeader}>{"ओपनिंग स्टॉक"}</Text>
                                            <Text style={styles.tableHeader}>{"खरेदी"}</Text>
                                            <Text style={styles.tableHeader}>{"विक्री"}</Text>
                                            <Text style={styles.tableHeader}>{"क्लोजिंग स्टॉक"}</Text>
                                        
                                        </View>
                                        {customerData.map((rowData, index) => (
                                            <View key={index} style={styles.tableRow}>
                                                <Text style={styles.tableCell}>{rowData.date}</Text>
                                                <Text style={styles.tableCell}>{rowData.opening_stock}</Text>
                                                <Text style={styles.tableCell}>{rowData.purchase_qty}</Text>
                                                <Text style={styles.tableCell}>{rowData.sale_qty}</Text>
                                                <Text style={styles.tableCell}>{rowData.closing_stock}</Text>
                                            </View>
                                        ))}
                                    </View>

                                </Page>

                                :

                                localStorage.getItem("khrtype") === "udhari" && localStorage.getItem("bill10type") === "1" ?
                                    <Page size="A4" >
                                        <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                                        <Text style={styles.latoText}>{"उधारी रिपोर्ट"}</Text>
                                        <View style={styles.imagerow}>
                                            <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                            <Image style={styles.image} src={Logo} />
                                        </View>

                                        <View style={styles.table}>
                                            {/* Table Header */}
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableHeader}>{"खाते क्र"}</Text>
                                                <Text style={styles.tableHeader}>{"सभासद नाव"}</Text>
                                                <Text style={styles.tableHeader}>{"एडव्हांस बाकी"}</Text>
                                                <Text style={styles.tableHeader}>{"खाद्य बाकी"}</Text>
                                                <Text style={styles.tableHeaderlast}>{"एकूण बाकी"}</Text>
                                            </View>
                                            {customerData.map((rowData, index) => (
                                                <View key={index} style={styles.tableRow}>
                                                    <Text style={styles.tableCell}>{rowData.kno}</Text>
                                                    <Text style={styles.tableCell}>{rowData.cname}</Text>
                                                    <Text style={styles.tableCell}>{parseFloat(rowData.newadv).toFixed(2)}</Text>
                                                    <Text style={styles.tableCell}>{parseFloat(rowData.newkhadya).toFixed(2)}</Text>
                                                    <Text style={styles.tableCell}>{parseFloat(parseFloat(rowData.newadv) + parseFloat(rowData.newkhadya)).toFixed(2)}</Text>

                                                </View>
                                            ))}

                                            <View style={styles.tableRow3}>
                                                <Text style={styles.tableHeader}>{"एकूण"}</Text>
                                                <Text style={styles.tableHeader}>{"-"}</Text>
                                                <Text style={styles.tableHeader}>{parseFloat(totals.tamtadv).toFixed(2)}</Text>
                                                <Text style={styles.tableHeader}>{parseFloat(totals.tamtkhadya).toFixed(2)}</Text>
                                                <Text style={styles.tableHeaderlast}>{parseFloat(totals.tamt).toFixed(2)}</Text>
                                            </View>
                                        </View>

                                    </Page> :
                                    localStorage.getItem("khrtype") === "udhari" && localStorage.getItem("bill10type") === "0" ?
                                        <Page size="A4" >
                                            <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                                            <Text style={styles.latoText}>{"उधारी रिपोर्ट"}</Text>
                                            <View style={styles.imagerow}>
                                                <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                                <Image style={styles.image} src={Logo} />
                                            </View>

                                            <View style={styles.table}>
                                                {/* Table Header */}
                                                <View style={styles.tableRow}>
                                                    <Text style={styles.tableHeader}>{"खाते क्र"}</Text>
                                                    <Text style={styles.tableHeader}>{"सभासद नाव"}</Text>
                                                    <Text style={styles.tableHeaderlast}>{"उधारी बाकी"}</Text>
                                                </View>
                                                {customerData.map((rowData, index) => (
                                                    <View key={index} style={styles.tableRow}>
                                                        <Text style={styles.tableCell}>{rowData.kno}</Text>
                                                        <Text style={styles.tableCell}>{rowData.cname}</Text>
                                                        <Text style={styles.tableCell}>{parseFloat(rowData.tbaki).toFixed(2)}</Text>

                                                    </View>
                                                ))}

                                                <View style={styles.tableRow3}>
                                                    <Text style={styles.tableHeader}>{"एकूण"}</Text>
                                                    <Text style={styles.tableHeader}>{"-"}</Text>
                                                    <Text style={styles.tableHeaderlast}>{parseFloat(totals.tamtud).toFixed(2)}</Text>
                                                </View>
                                            </View>

                                        </Page>
                                        :
                                        localStorage.getItem("khrtype") === "adv" ?
                                            <Page size="A4">
                                                <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                                                <Text style={styles.latoText}>{"एड्व्हांस/उचल रिपोर्ट"}</Text>
                                                <Text style={styles.latoText2}>{"कालावधी : " + formatDate(localStorage.getItem("date1print")) + "   ते   " + formatDate(localStorage.getItem("date2print"))}</Text>
                                                <View style={styles.imagerow}>
                                                    <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                                    <Image style={styles.image} src={Logo} />
                                                </View>

                                                <View style={styles.table}>
                                                    {/* Table Header */}
                                                    <View style={styles.tableRow}>
                                                        <Text style={styles.tableHeader}>{"दिनांक"}</Text>
                                                        <Text style={styles.tableHeader}>{"खाते/नाव"}</Text>
                                                        <Text style={styles.tableHeader}>{"प्रकार"}</Text>
                                                        <Text style={styles.tableHeaderlast}>{"रक्कम"}</Text>
                                                    </View>
                                                    {customerData.map((rowData, index) => (
                                                        <View key={index} style={styles.tableRow}>
                                                            <Text style={styles.tableCell}>{rowData.date}</Text>
                                                            <Text style={styles.tableCell}>{rowData.cname}</Text>
                                                            <Text style={styles.tableCell}>{rowData.type}</Text>
                                                            <Text style={styles.tableCell}>{parseFloat(rowData.amt).toFixed(2)}</Text>
                                                        </View>
                                                    ))}

                                                    <View style={styles.tableRow3}>
                                                        <Text style={styles.tableHeader}>{"एकूण"}</Text>
                                                        <Text style={styles.tableHeader}>{"-"}</Text>
                                                        <Text style={styles.tableHeader}>{"-"}</Text>
                                                        <Text style={styles.tableHeaderlast}>{parseFloat(totals.tamt).toFixed(2)}</Text>

                                                    </View>
                                                </View>

                                            </Page>
                                            :
                                            localStorage.getItem("khrtype") === "dreport" ?
                                                <Page size="A4">
                                                    <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                                                    <Text style={styles.latoText}>{"डिलर खाते रिपोर्ट"}</Text>
                                                    <Text style={styles.latoText2}>{"नाव: " + localStorage.getItem("partyname")}</Text>
                                                    <Text style={styles.latoText2}>{"कालावधी : " + formatDate(localStorage.getItem("date1print")) + "   ते   " + formatDate(localStorage.getItem("date2print"))}</Text>
                                                    <View style={styles.imagerow}>
                                                        <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                                        <Image style={styles.image} src={Logo} />
                                                    </View>

                                                    <View style={styles.table}>
                                                        {/* Table Header */}
                                                        <View style={styles.tableRow}>
                                                            <Text style={styles.tableHeader}>{"दिनांक"}</Text>
                                                            <Text style={styles.tableHeader}>{"प्रकार"}</Text>
                                                            <Text style={styles.tableHeader}>{"खाद्य"}</Text>
                                                            <Text style={styles.tableHeaderlast}>{"नग"}</Text>
                                                            <Text style={styles.tableHeaderlast}>{"दर"}</Text>
                                                            <Text style={styles.tableHeaderlast}>{"रक्कम"}</Text>
                                                        </View>

                                                        <View  style={styles.tableRow}>
                                                                <Text style={styles.tableCell}>{"मागील बाकी"}</Text>
                                                                <Text style={styles.tableCell}>-</Text>
                                                                <Text style={styles.tableCell}>-</Text>
                                                                <Text style={styles.tableCell}>-</Text>
                                                                <Text style={styles.tableCell}>-</Text>
                                                                <Text style={styles.tableCell}>{parseFloat(PreviousAmt).toFixed(2)}</Text>
                                                            </View>

                                                        {customerData.map((rowData, index) => (
                                                            <View key={index} style={styles.tableRow}>
                                                                <Text style={styles.tableCell}>{rowData.date}</Text>
                                                                <Text style={styles.tableCell}>{rowData.trantype}</Text>
                                                                <Text style={styles.tableCell}>{rowData.khname}</Text>
                                                                <Text style={styles.tableCell}>{rowData.quin}</Text>
                                                                <Text style={styles.tableCell}>{parseFloat(rowData.rate).toFixed(2)}</Text>
                                                                <Text style={styles.tableCell}>{parseFloat(rowData.amt).toFixed(2)}</Text>
                                                            </View>
                                                        ))}

                                                        <View style={styles.tableRow3}>
                                                            <Text style={styles.tableHeader}>{"एकूण"}</Text>
                                                            <Text style={styles.tableHeader}>{"-"}</Text>
                                                            <Text style={styles.tableHeader}>{"-"}</Text>
                                                            <Text style={styles.tableHeaderlast}>{parseFloat(totals.quin).toFixed(2)}</Text>
                                                            <Text style={styles.tableHeader}>{"-"}</Text>
                                                            <Text style={styles.tableHeaderlast}>{parseFloat(totals.tamt).toFixed(2)}</Text>

                                                        </View>


                                                    </View>

                                                </Page>
                                                : ''
                ) : ""}
            </Document>
        </PDFViewer>

    );

};

// ... (styles and export)



const styles = StyleSheet.create({
    page: {
        paddingTop: 10,
        flexDirection: 'row',
        justifyContent: 'center', // Center horizontally
    },
    header: {
        position: 'absolute',
        top: 0, // Adjust this value to change the vertical position
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
    },
    table: {
        display: 'table',
        width: '97%', // Adjust as needed to fit four tables in a row
        margin: 5,
    },
    tableone: {
        display: 'table',
        width: '18%', // Adjust as needed to fit four tables in a row
        margin: 5,
    },
    tableCellone: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        fontSize: 8,
        fontFamily: 'Lato-Italic',
        textAlign: 'center',
        flex: 1, // Remove width and use flex to distribute space equally
    },
    tableRowone: {
        flexDirection: 'row',
        width: '100%',
    },
    tableHeaderone: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        border: '1px solid #000',
        padding: 2,
        fontSize: 8,
        textAlign: 'center',
        flex: 1, // Remove width and use flex to distribute space equally
    },

    tableRow: {
        flexDirection: 'row',

    },
    tableRow2: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 5,
    },

    tableRow3: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 10,
    },
    line: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 5,
        height: 1,
        width: '100vw',
        marginBottom: 5,
    },
    tableHeader: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderTopWidth: 1,    // Add border only for top side
        borderBottomWidth: 1, // Add border only for bottom side
        borderLeftWidth: 1,   // Add border only for left side
        borderRightWidth: 0,  // No border for right side
        borderStyle: 'solid', // Set the border style to 'solid'
        padding: 2,
        flex: 0.5,
        borderColor: 'lightblack',
        textAlign: 'center',
        fontSize: 9,
    },
    tableHeaderlast: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderTopWidth: 1,    // Add border only for top side
        borderBottomWidth: 1, // Add border only for bottom side
        borderLeftWidth: 1,   // Add border only for left side
        borderRightWidth: 1,  // No border for right side
        borderStyle: 'solid', // Set the border style to 'solid'
        borderColor: 'lightblack',
        padding: 2,
        flex: 0.5,
        textAlign: 'center',
        fontSize: 9,
    },
    tableCell: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 10,
        fontFamily: 'Lato-Italic',
        width: 2,
        textAlign: 'center',
    },
    tableCellgreen: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 10,
        fontWeight: 'bold',
        fontFamily: 'Lato-Italic',
        color: 'green',
        width: 2,
        textAlign: 'center',
    },
    tableCellred: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 9,
        fontWeight: 'bold',
        fontFamily: 'Lato-Italic',
        color: 'red',
        width: 2,
        textAlign: 'center',
    },
    latoTextlarge: {
        fontFamily: 'Lato-Italic',
        fontSize: 15,
        marginBottom: 5,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
        marginTop: 5,
    },
    latoText: {
        fontFamily: 'Lato-Italic',
        fontSize: 14,
        marginBottom: 5,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
        marginTop: 5,
    },
    latoText2: {
        fontFamily: 'Lato-Italic',
        fontSize: 11,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
    },
    headtextsmall: {
        fontFamily: 'Lato-Italic',
        fontSize: 10,
        fontWeight: 'bold',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
    },
    row1: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 5,
        paddingRight: 5,
    },
    row2: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 140,
        paddingRight: 140,
        backgroundColor: 'lightgray',
    },
    column1: {
        display: 'flex',
        flexDirection: 'column',
        width: '19%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 20,
    },
    column2: {
        display: 'flex',
        flexDirection: 'column',
        width: '15%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,


    },
    column3: {
        display: 'flex',
        flexDirection: 'column',
        width: '17%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 20,
    },
    column1Row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    column1Rowgray: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 6,
        marginTop: 5,
        border: '0.5px solid black',
        backgroundColor: 'lightgray'
    },
    column1Label: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'left', // Align labels to the left
    },
    column1Value: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'right', // Align values to the right
    },
    pageBreak: {
        pageBreakBefore: 'always',
    },
    totalsRow: {
        flexDirection: 'row',
        borderTop: '1px solid #000',
        paddingTop: 5,
        paddingLeft: 2,
    },
    totalsCell: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 8,
        width: 2,
        textAlign: 'center',
    },
    imagerow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: 80, // Adjust the width as needed
        height: "auto", // Maintain aspect ratio
        marginLeft: -15,
        marginTop: 5

    },
    imgtext: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center', // Align labels to the left
    },
});

export default Khadyaadvreportprint

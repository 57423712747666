import React, { useContext, useEffect, useRef, useState } from 'react'
import './Sanklanbykno.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import refresh from "../../imgs/refresh.png";
import close from "../../imgs/close.png";
import { CasinoSharp, Face6TwoTone, FaxTwoTone, Preview } from '@mui/icons-material';
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';
import { compareSync } from 'bcryptjs';

const Sanklan = (props) => {

    const fatInputRef = useRef(null);
    const snfInputRef = useRef(null);

    const [newedit, setNewedit] = useState("new");
    const [deletetime, setDeletetime] = useState();
    const [lock, setLock] = useState("both");
    const [old, setOld] = useState(false);
    const [input, setInput] = useState({ date: new Date().toISOString().substr(0, 10), time: "दोन्ही", kno: "", name: "", vibhag: "", type: "", liter: "", fat: "", snf: "", rate: "", amt: "", literevn: "", fatevn: "", snfevn: "", rateevn: "", amtevn: "", reporttype: "", vibh1: "", vibh2: "", vibh3: "", vibh4: "", vibh5: "", vibh6: "", vibh7: "", vibh8: "", vibh1gay: "", vibh2gay: "", vibh3gay: "", vibh4gay: "", vibh5gay: "", vibh6gay: "", vibh7gay: "", vibh8gay: "" });
    const [dates, setDates] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10) });

    const inputRefs = useRef([]);
    const [tcust, setTcust] = useState({ tlitermor: "", tamtmor: "", tliterevn: 0, tamtevn: 0, totalamount: 0, mkhadya: 0, ckhadya: 0, madv: 0, cadv: 0 });
    const [sredit, setSredit] = useState('');
    const [DataSource, setDataSource] = useState([]);
    const [Prevdata, setPrevdata] = useState([]);
    const [Prev, setPrev] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [value, setValue] = useState('');
    const [custdata, setCustdata] = useState([]);
    const { submenu, setsubmenu, newcustsource, setNewcustsource, custlist, setCustlist } = useContext(Globalcontext);
    const [tableFilter2, setTableFilter2] = useState([]);
    const [sendMsg, setSendmsg] = useState(true);
    const [custSank, SetCustsank] = useState(false);

    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const refdelete = useRef(null);
    const refdeleteclose = useRef(null);
    const [flag, setFlag] = useState("");
    const [fatcol, setFatcol] = useState(true)

    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        const currentDate = new Date();
        const dayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        let firstDayOfMonth;
        let lastDayOfMonth;

        if (dayOfMonth >= 1 && dayOfMonth <= 10) {
            firstDayOfMonth = 1;
            lastDayOfMonth = 10;
        } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
            firstDayOfMonth = 11;
            lastDayOfMonth = 20;
        } else if (dayOfMonth >= 21 && dayOfMonth <= 30) {
            firstDayOfMonth = 21;
            lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
        }

        if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
            const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
            const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');

            setDates(prevInput => ({
                ...prevInput,
                date1: formattedDate1,
                date2: formattedDate2
            }));
        }

        getoldnextrates();

    }, []);


    async function getoldnextrates() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getoldnextrates.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data && response.data.length > 0) {
                response.data.map((user, index) =>
                    setInput(prevInput => ({
                        ...prevInput,
                        vibh1: user.vibh1,
                        vibh2: user.vibh2,
                        vibh3: user.vibh3,
                        vibh4: user.vibh4,
                        vibh5: user.vibh5,
                        vibh6: user.vibh6,
                        vibh7: user.vibh7,
                        vibh8: user.vibh8,
                        vibh1gay: user.vibh1gay,
                        vibh2gay: user.vibh2gay,
                        vibh3gay: user.vibh3gay,
                        vibh4gay: user.vibh4gay,
                        vibh5gay: user.vibh5gay,
                        vibh6gay: user.vibh6gay,
                        vibh7gay: user.vibh7gay,
                        vibh8gay: user.vibh8gay
                    }))
                )

            }
        }).catch(err => {
            console.log(err);
        });
    }



    useEffect(() => {
        const currentDate1 = new Date(dates.date1);
        const dayOfMonth1 = currentDate1.getDate();
        const currentMonth1 = currentDate1.getMonth();
        const currentYear1 = currentDate1.getFullYear();

        let lastDayOfMonth1;

        if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
            lastDayOfMonth1 = 10;
        } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
            lastDayOfMonth1 = 20;
        } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
            lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
        }

        if (lastDayOfMonth1 !== undefined) {
            const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
            setDates(prevInput => ({
                ...prevInput,
                date2: formattedDate2
            }));

            setInput(prevInput => ({
                ...prevInput,
                date: dates.date1
            }));
        }
        setDataSource([]);
        setTableFilter([]);



    }, [dates.date1]);

    const handleedit = (sr, date, time, kno, name, vibhag, type, liter, fat, snf, rate, amt) => {
        setNewedit("edit");

        setInput({ date: date, time: time, kno: kno, name: name, vibhag: vibhag, type: type, liter: liter, fat: fat, snf: snf, rate: rate, amt: amt })
        setTimeout(() => {
            setInput({ date: date, time: time, kno: kno, name: name, vibhag: vibhag, type: type, liter: liter, fat: fat, snf: snf, rate: rate, amt: amt })
            setSredit(sr);

            if (inputRefs.current[4]) {
                inputRefs.current[4].focus();
            }
        }, 0);
    }


    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

        if (valuecurrent.includes('.')) {
            const parts = valuecurrent.split('.');
            if (parts[1].length === 1) {
                if (name === 'liter') {
                    inputRefs.current[7].focus();
                }
                else if (name === 'literevn') {
                    inputRefs.current[10].focus();
                }
            }
        }
    }

    const handleChangedates = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setDates(value => ({ ...value, [name]: valuecurrent }));
    }

    const handleboxex = async () => {
        if (input.time === "सकाळ") {
            setLock("mor");
        }
        else if (input.time === "संध्याकाळ") {
            setLock("evn");
        }
        else if (input.time === "दोन्ही") {
            setLock("both");
            // setInput(prevInput => ({
            //     ...prevInput,
            //     time: "दोन्ही"
            // }));
        }
    }

    const handleChangefs = async e => {
        const { name, value } = e.target;
        let valuecurrent = "";
        valuecurrent = e.target.value;
        if (value === "") {
            setInput(prevInput => ({
                ...prevInput,
                [name]: value
            }));
        } else if (/^\d*(\.\d{0,1})?$/.test(value)) {
            setInput(prevInput => ({
                ...prevInput,
                [name]: value
            }));

            if (valuecurrent.includes('.')) {
                const parts = valuecurrent.split('.');
                if (parts[1].length === 1) {
                    if (name === 'fat') {
                        setTimeout(() => {
                            inputRefs.current[8].focus();
                        }, 0);
                    }
                    else if (name === 'snf') {
                        setTimeout(() => {
                            inputRefs.current[8].focus();
                        }, 0);
                    }
                    else if (name === 'fatevn') {
                        setTimeout(() => {
                            inputRefs.current[11].focus();
                        }, 0);
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (localStorage.getItem("sankdate") && localStorage.getItem("sanktime")) {
            setInput(prevInput => ({
                ...prevInput,
                date: localStorage.getItem("sankdate"),
                time: localStorage.getItem("sanktime")
            }))

            localStorage.setItem("sankdate", "");
            localStorage.setItem("sanktime", "")

        }
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }

    }, [])
    useEffect(() => {
        if (input.kno === "") {
            if (custSank) {
                setDataSource([]);
            }
            setInput(prevInput => ({
                ...prevInput,
                kno: "",
                name: "",
                vibhag: "",
                type: "",
                liter: "",
                fat: "",
                snf: "",
                rate: "",
                amt: ""
            }))
            setSredit("");
            setNewedit("new");
        }
        else {
            if (newedit === 'new') {
                let custlisttemp = JSON.parse(localStorage.getItem("custlist"));

                for (let i = 0; i < custlisttemp.length; i++) {
                    setInput(prevInput => ({
                        ...prevInput,
                        name: ''
                    }));
                    const user = custlisttemp[i];
                    if (user.kno === input.kno) {
                        setInput(prevInput => ({
                            ...prevInput,
                            name: user.cname,
                            type: user.type,
                            vibhag: user.vibhag,
                        }));
                        break; // Exit the loop once the condition is met
                    }
                }
            }
        }
    }, [input.kno])

    useEffect(() => {
        if (!old) {
            setInput(prevInput => ({
                ...prevInput,
                rate: 0,
                amt: 0,
            }))

            if (input.fat !== "" && input.fat !== "" && input.type !== "") {
                getratefromdata();
            }
        }
    }, [input.fat])

    useEffect(() => {
        if (!old) {
            setInput(prevInput => ({
                ...prevInput,
                rate: 0,
                amt: 0,
            }))

            if (input.fat !== "" && input.fat !== "" && input.type !== "") {

                getratefromdata();
            }
        }

    }, [input.snf])

    useEffect(() => {
        if (!old) {
            setInput(prevInput => ({
                ...prevInput,
                rateevn: 0,
                amtevn: 0,
            }))

            if (input.fatevn !== "" && input.fatevn !== "" && input.type !== "") {
                getratefromdataevn();
            }
        }

    }, [input.fatevn])

    useEffect(() => {
        if (!old) {
            setInput(prevInput => ({
                ...prevInput,
                rateevn: 0,
                amtevn: 0,
            }))

            if (input.fatevn !== "" && input.fatevn !== "" && input.type !== "") {
                getratefromdataevn();
            }
        }

    }, [input.snfevn])

    const getratefromdataold = () => {
        let rates = null;
        let rates2 = null;
        if (input.type === "म्हैस") {
            rates = JSON.parse(localStorage.getItem("mhrates"));
            rates2 = JSON.parse(localStorage.getItem("mhrates2"));
        }
        else if (input.type === "गाय") {
            rates = JSON.parse(localStorage.getItem("gayrates"));
            rates2 = JSON.parse(localStorage.getItem("gayrates2"));
        }

        if (!rates) {
            notifyerror("कृपया दूध प्रकार निवडा!")
            return;
        }
        if (input.vibhag === "दरपत्रक विभाग-१") {

            if (rates) {
                for (let i = 0; i < rates.length; i++) {
                    const user = rates[i];

                    if (user.fat === input.fat && user.snf === input.snf) {

                        setTimeout(() => {
                            setInput(prevInput => ({
                                ...prevInput,
                                rate: user.rate,
                                amt: parseFloat(parseFloat(user.rate) * parseFloat(input.liter)).toFixed(2)
                            }))
                        }, 0);
                        break; // Exit the loop once the condition is met
                    }
                }
            }
        }
        else if (input.vibhag === "दरपत्रक विभाग-२") {

            if (rates2) {
                for (let i = 0; i < rates2.length; i++) {
                    const user = rates2[i];
                    if (user.fat === input.fat && user.snf === input.snf) {

                        setTimeout(() => {
                            setInput(prevInput => ({
                                ...prevInput,
                                rate: user.rate,
                                amt: parseFloat(parseFloat(user.rate) * parseFloat(input.liter)).toFixed(2)
                            }))
                        }, 0);
                        break; // Exit the loop once the condition is met
                    }
                }
            }
        }
    }

    const getratefromdataevnold = () => {
        let rates = null;
        let rates2 = null;
        if (input.type === "म्हैस") {
            rates = JSON.parse(localStorage.getItem("mhrates"));
            rates2 = JSON.parse(localStorage.getItem("mhrates2"));
        }
        else if (input.type === "गाय") {
            rates = JSON.parse(localStorage.getItem("gayrates"));
            rates2 = JSON.parse(localStorage.getItem("gayrates2"));
        }

        if (!rates) {
            notifyerror("कृपया दूध प्रकार निवडा!")
            return;
        }
        if (input.vibhag === "दरपत्रक विभाग-१") {

            if (rates) {
                for (let i = 0; i < rates.length; i++) {
                    const user = rates[i];


                    if (user.fat === input.fatevn && user.snf === input.snfevn) {
                        setTimeout(() => {
                            setInput(prevInput => ({
                                ...prevInput,
                                rateevn: user.rate,
                                amtevn: parseFloat(parseFloat(user.rate) * parseFloat(input.literevn)).toFixed(2)
                            }))
                        }, 0);
                        break; // Exit the loop once the condition is met
                    }
                }
            }
        }
        else if (input.vibhag === "दरपत्रक विभाग-२") {

            if (rates2) {
                for (let i = 0; i < rates2.length; i++) {
                    const user = rates2[i];
                    if (user.fat === input.fatevn && user.snf === input.snfevn) {

                        setTimeout(() => {
                            setInput(prevInput => ({
                                ...prevInput,
                                rateevn: user.rate,
                                amtevn: parseFloat(parseFloat(user.rate) * parseFloat(input.literevn)).toFixed(2)
                            }))
                        }, 0);
                        break; // Exit the loop once the condition is met
                    }
                }
            }
        }
    }

    const getratefromdata = () => {

        let rates = null;
        let rates9 = null;
        if (input.type === "म्हैस") {
            rates = JSON.parse(localStorage.getItem("mhrates"));
            if (localStorage.getItem("mhrates9") !== "") {
                rates9 = JSON.parse(localStorage.getItem("mhrates9"));
              }
        }
        else if (input.type === "गाय") {
            rates = JSON.parse(localStorage.getItem("gayrates"));
            if (localStorage.getItem("gayrates9") !== "") {
                rates9 = JSON.parse(localStorage.getItem("gayrates9"));
              }
        }

        if (!rates) {
            notifyerror("कृपया दूध प्रकार निवडा!")
            return;
        }


        if (input.vibhag !== "दरपत्रक विभाग-९ (M)") {
                for (let i = 0; i < rates.length; i++) {
                    const user = rates[i];

                    let diff = 0;

                    if (input.vibhag === "दरपत्रक विभाग-१" && input.type === 'म्हैस') {
                        diff = input.vibh1;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-१" && input.type === 'गाय') {
                        diff = input.vibh1gay;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-२" && input.type === 'म्हैस') {
                        diff = input.vibh2;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-२" && input.type === 'गाय') {
                        diff = input.vibh2gay;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-३" && input.type === 'म्हैस') {
                        diff = input.vibh3;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-३" && input.type === 'गाय') {
                        diff = input.vibh3gay;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-४" && input.type === 'म्हैस') {
                        diff = input.vibh4;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-४" && input.type === 'गाय') {
                        diff = input.vibh4gay;
                    //   console.log("Diff- ", input.vibh4gay);
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-५" && input.type === 'म्हैस') {
                        diff = input.vibh5;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-५" && input.type === 'गाय') {
                        diff = input.vibh5gay;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-६" && input.type === 'म्हैस') {
                        diff = input.vibh6;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-६" && input.type === 'गाय') {
                        diff = input.vibh6gay;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-७" && input.type === 'म्हैस') {
                        diff = input.vibh7;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-७" && input.type === 'गाय') {
                        diff = input.vibh7gay;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-८" && input.type === 'म्हैस') {
                        diff = input.vibh8;
                    }
                    else if (input.vibhag === "दरपत्रक विभाग-८" && input.type === 'गाय') {
                        diff = input.vibh8gay;
                    }



                    if (user.fat === input.fat && user.snf === input.snf) {
                        setTimeout(() => {
                            setInput(prevInput => ({
                                ...prevInput,
                                rate: parseFloat(user.rate) + parseFloat(diff),
                                amt: parseFloat(parseFloat(parseFloat(user.rate) + parseFloat(diff)) * parseFloat(input.liter)).toFixed(2)
                            }))
                        }, 0);
                        break; // Exit the loop once the condition is met
                    }
                }
        }
        else if (input.vibhag === "दरपत्रक विभाग-९ (M)") {
            for (let i = 0; i < rates9.length; i++) {
              const user = rates9[i];
              if (user.fat === input.fat && user.snf === input.snf) {
                setTimeout(() => {
                  setInput(prevInput => ({
                    ...prevInput,
                    rate: user.rate,
                    amt: parseFloat(parseFloat(user.rate) * parseFloat(input.liter)).toFixed(2)
                  }))
                }, 0);
                break; // Exit the loop once the condition is met
              }
            }
    
        }

        // }
        // else if (input.vibhag === "दरपत्रक विभाग-२") {
        //     for (let i = 0; i < rates.length; i++) {
        //       const user = rates2[i];
        //       if (user.fat === input.fat && user.snf === input.snf) {
        //         setTimeout(() => {
        //           setInput(prevInput => ({
        //             ...prevInput,
        //             rate: user.rate,
        //             amt: parseFloat(parseFloat(user.rate) * parseFloat(input.liter)).toFixed(2)
        //           }))
        //         }, 0);
        //         break; // Exit the loop once the condition is met
        //       }
        //     }

        // }
    }

    const getratefromdataevn = () => {

        let rates = null;
        let rates2 = null;
        if (input.type === "म्हैस") {

            rates = JSON.parse(localStorage.getItem("mhrates"));
            // rates2 = JSON.parse(localStorage.getItem("mhrates2"));
        }
        else if (input.type === "गाय") {
            rates = JSON.parse(localStorage.getItem("gayrates"));
            //rates2 = JSON.parse(localStorage.getItem("gayrates2"));
        }

        if (!rates) {
            notifyerror("कृपया दूध प्रकार निवडा!")
            return;
        }

        for (let i = 0; i < rates.length; i++) {
            const user = rates[i];

            let diff = 0;

            if (input.vibhag === "दरपत्रक विभाग-१" && input.type === 'म्हैस') {
                diff = input.vibh1;
            }
            else if (input.vibhag === "दरपत्रक विभाग-१" && input.type === 'गाय') {
                diff = input.vibh1gay;
            }
            else if (input.vibhag === "दरपत्रक विभाग-२" && input.type === 'म्हैस') {
                diff = input.vibh2;
            }
            else if (input.vibhag === "दरपत्रक विभाग-२" && input.type === 'गाय') {
                diff = input.vibh2gay;
            }
            else if (input.vibhag === "दरपत्रक विभाग-३" && input.type === 'म्हैस') {
                diff = input.vibh3;
            }
            else if (input.vibhag === "दरपत्रक विभाग-३" && input.type === 'गाय') {
                diff = input.vibh3gay;
            }
            else if (input.vibhag === "दरपत्रक विभाग-४" && input.type === 'म्हैस') {
                diff = input.vibh4;
            }
            else if (input.vibhag === "दरपत्रक विभाग-४" && input.type === 'गाय') {
                diff = input.vibh4gay;
               // console.log("Diff- ", input.vibh4gay);
            }
            else if (input.vibhag === "दरपत्रक विभाग-५" && input.type === 'म्हैस') {
                diff = input.vibh5;
            }
            else if (input.vibhag === "दरपत्रक विभाग-५" && input.type === 'गाय') {
                diff = input.vibh5gay;
            }
            else if (input.vibhag === "दरपत्रक विभाग-६" && input.type === 'म्हैस') {
                diff = input.vibh6;
            }
            else if (input.vibhag === "दरपत्रक विभाग-६" && input.type === 'गाय') {
                diff = input.vibh6gay;
            }
            else if (input.vibhag === "दरपत्रक विभाग-७" && input.type === 'म्हैस') {
                diff = input.vibh7;
            }
            else if (input.vibhag === "दरपत्रक विभाग-७" && input.type === 'गाय') {
                diff = input.vibh7gay;
            }
            else if (input.vibhag === "दरपत्रक विभाग-८" && input.type === 'म्हैस') {
                diff = input.vibh8;
            }
            else if (input.vibhag === "दरपत्रक विभाग-८" && input.type === 'गाय') {
                diff = input.vibh8gay;
            }


            console.log(rates);

            if (user.fat === input.fatevn && user.snf === input.snfevn) {

                
                setTimeout(() => {
                    setInput(prevInput => ({
                        ...prevInput,
                        rateevn: parseFloat(user.rate) + parseFloat(diff),
                        amtevn: parseFloat(parseFloat(parseFloat(user.rate) + parseFloat(diff)) * parseFloat(input.literevn)).toFixed(2)
                    }))
                }, 0);
                break; // Exit the loop once the condition is met
            }
        }

    }


    useEffect(() => {
        if (newedit === "new") {
            if (input.type !== "प्रकार निवडा") {
                setInput(prevInput => ({
                    ...prevInput,
                    rate: "",
                    amt: "",
                    type: input.type
                }))
            }
            if (input.fat !== "" && input.fat !== "" && input.type !== "" && newedit === "new") {
                //getrate(input.fat, input.snf, input.type);
                getratefromdata();
            }
        }
        setPrev([]);
    }, [input.type])

    useEffect(() => {
        if (input.liter === "") {
            setInput(prevInput => ({
                ...prevInput,
                amt: "0",
            }))
        }
        if (input.liter !== "" && input.rate !== "") {
            setInput(prevInput => ({
                ...prevInput,
                amt: parseFloat(parseFloat(input.rate) * parseFloat(input.liter)).toFixed(2)
            }))
        }
    }, [input.liter])

    useEffect(() => {
        if (<input type="text" className="literev" /> === "") {
            setInput(prevInput => ({
                ...prevInput,
                amtevn: "0",
            }))
        }
        if (input.literevn !== "" && input.rateevn !== "") {
            setInput(prevInput => ({
                ...prevInput,
                amtevn: parseFloat(parseFloat(input.rateevn) * parseFloat(input.literevn)).toFixed(2)
            }))
        }
    }, [input.literevn])



    useEffect(() => {
        const selectedDate = new Date(input.date);
        // Get the current date
        const currentDate = new Date();

        // Check if the selected date is equal to the current date
        const isToday = selectedDate.toDateString() === currentDate.toDateString();

        // Set the sendMsg state based on whether it's today or not
        //   setSendmsg(isToday);

        setPrev([]);
        // setInput(prevInput => ({
        //     ...prevInput,
        //     time: "दोन्ही"
        // }))
        // setTcust({ tcust: "", tcompleted: "", tliter: 0, tavg: 0, tmh: 0, mhavg: 0, tgay: 0, gayavg: 0 });
    }, [input.date])


    async function getoldentry(date, type) {
        DataSource
            .filter(data => data.date === date)
            .map((user, index) => (

                setInput(prevInput => ({
                    ...prevInput,
                    date: user.date,
                    liter: user.litermor,
                    fat: user.fatmor,
                    snf: user.snfmor,
                    rate: user.ratemor,
                    amt: user.amtmor,
                    literevn: user.literevn,
                    fatevn: user.fatevn,
                    snfevn: user.snfevn,
                    rateevn: user.rateevn,
                    amtevn: user.amtevn,

                })),

                setOld(true)
            ));
        if (type === "1") {
            if (inputRefs.current[6]) {
                inputRefs.current[6].focus();
            }
        }
        else if (type === "2") {
            if (inputRefs.current[9]) {
                inputRefs.current[9].focus();
            }
        }
    }

    // useEffect(() => {
    //     console.log(input);
    // })

    const getprevfatsnf2 = () => {
        // console.log(Prevdata);
        for (let i = 0; i < Prevdata.length; i++) {
            const user = Prevdata[i];
            if (user.kno === input.kno && user.type === input.type && user.time === input.time) {
                //  console.log("in");
                setPrev((prevInputSettings) => ({
                    ...prevInputSettings,
                    liter: user.liter,
                    fat: user.fat,
                    snf: user.snf,
                }));
                break; // Exit the loop once the condition is met
            }
        }
    }

    // useEffect(() => {
    //   console.log(Prev.liter)
    // }, [Prev])


    async function getsanklan() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getsanklanbyknonew.php', { date1: dates.date1, date2: dates.date2, type: input.type, kno: input.kno, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {

             //   console.log(response.data);
                setDataSource(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
                const totals = response.data.reduce(
                    (accumulator, user) => {
                        const liter = parseFloat(user.liter);
                        const fat = parseFloat(user.fat);

                        accumulator.totalLiter += liter;
                        accumulator.tfat += fat;

                        if (user.type === "म्हैस") {
                            accumulator.totalLitermh += liter;
                            accumulator.tfatmh += fat;
                            accumulator.tentrymh += 1;
                        } else if (user.type === "गाय") {
                            accumulator.totalLitergay += liter;
                            accumulator.tfatgay += fat;
                            accumulator.tentrygay += 1;
                        }
                        return accumulator;
                    },
                    {
                        tlitermor: 0,
                        tamtmor: 0,
                        tliterevn: 0,
                        tamtevn: 0,
                        totalamount: 0,
                        mkhadya: 0,
                        ckhadya: 0,
                        madv: 0,
                        cadv: 0
                    }
                );

                const {
                    tlitermor,
                    tamtmor,
                    tliterevn,
                    tamtevn,
                    totalamount,
                    mkhadya,
                    ckhadya,
                    madv,
                    cadv

                } = totals;

                response.data.map((user, index) =>
                (
                    setTcust(prevInput => ({
                        ...prevInput,
                        tlitermor: user.sum_litermor,
                        tamtmor: user.sum_amtmor,
                        tliterevn: user.sum_literevn,
                        tamtevn: user.sum_amtevn,
                        totalamount: user.totalamount,
                        mkhadya: user.prev_newkhadya,
                        ckhadya: user.curr_ckhadya,
                        madv: user.prev_madv,
                        cadv: user.curr_cadv
                    }))
                ))
            }


            if (response.data.length == 0) {
                setTcust(prevInput => ({
                    ...prevInput,
                    tlitermor: 0,
                    tamtmor: 0,
                    tliterevn: 0,
                    tamtevn: 0,
                    totalamount: 0,
                }))
            }

            getuchalkhbelow();

        }).catch(err => {
            console.log(err);
        });
    }

    async function getuchalkhbelow() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getkhuchalbykno.php', { date1: dates.date1, date2: dates.date2, type: input.type, kno: input.kno, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
              //  console.log(response.data);

                response.data.map((user, index) =>
                (
                    setTcust(prevInput => ({
                        ...prevInput,
                        mkhadya: user.prev_newkhadya,
                        ckhadya: user.ckhadya,
                        madv: user.prev_madv,
                        cadv: user.cadv
                    }))
                ))
            }


            if (response.data.length == 0) {
                setTcust(prevInput => ({
                    ...prevInput,
                    tlitermor: 0,
                    tamtmor: 0,
                    tliterevn: 0,
                    tamtevn: 0,
                    totalamount: 0,
                }))
            }

        }).catch(err => {
            console.log(err);
        });
    }


    function setoldtofalse() {

        setOld(false);

        if (input.liter === "") {
            setInput(prevInput => ({
                ...prevInput,
                amt: "0",
            }))
        }
        if (input.liter !== "" && input.rate !== "") {
            setInput(prevInput => ({
                ...prevInput,
                amt: parseFloat(parseFloat(input.rate) * parseFloat(input.liter)).toFixed(2)
            }))
        }
    }


    async function getprevsanklan() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getprevsanklan.php', { date: input.date, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                setPrevdata(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    function handleEnterKey(event, currentIndex) {
        // Handle Backspace key
        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा') ||
                (event.target.name === 'time' && event.target.value === 'वेळ निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement && !prevElement.disabled) { // Skip disabled inputs
                    prevElement.focus();
                    break;
                } else {
                    prevIndex--;
                }
            }
        }

        // Handle Enter or Tab key
        if (event.key === 'Enter' || event.key === "Tab") {

            event.preventDefault();
            let nextIndex = null;

            if (currentIndex === 2) {

                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                } else {
                    nextIndex = currentIndex;
                }
            } else {

                nextIndex = currentIndex + 1;

            }

            while (inputRefs.current[nextIndex]) {

                if (!inputRefs.current[nextIndex].disabled) { // Skip disabled inputs

                    inputRefs.current[nextIndex].focus();
                    break;
                } else {

                    nextIndex++;
                }
            }
        }
    }


    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            event.preventDefault();
            savesanklan();
            if (custSank) {
                if (inputRefs.current[1]) {
                    inputRefs.current[1].focus();
                }
            }
            else {
                let nextIndex = null;
                if (currentIndex === 0) {
                    if (input.name !== "") {
                        nextIndex = currentIndex + 1;
                    }
                    else {
                        nextIndex = currentIndex;
                    }
                }
                else {
                    nextIndex = currentIndex + 1;
                }
                if (inputRefs.current[nextIndex]) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
        else if (event.key === "Backspace") {
            if (event.target.tagName === "BUTTON") {
                if (inputRefs.current[6]) {
                    inputRefs.current[6].focus();
                }
            }
        }
    }

    async function getcustdata(kno) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        setTimeout(() => {
            props.setProgress(70);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getcustomer.php', { kno: kno, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data.length > 0) {
                response.data.map((user, index) =>
                (
                    user.type !== "दोन्ही" ?
                        setInput(prevInput => ({
                            ...prevInput,
                            name: user.cname,
                            type: user.type
                        }))
                        :
                        setInput(prevInput => ({
                            ...prevInput,
                            name: user.cname,
                            type: "प्रकार निवडा"
                        }))
                )
                )
            }
            setTimeout(() => {
                props.setProgress(100);
            }, 200);
        }).catch(err => {
            console.log(err);
        });
    }

    async function getratesdf(fat, snf, type) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let typeid = null;
        if (input.type === "म्हैस") {
            typeid = "1";
        }
        else {
            typeid = "2";
        }
        axios.post('https://idairy.co.in/dairy/php/cust/getrate.php', { typeid: typeid, fat: fat, snf: snf, type: type, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            //console.log(response.data);
            if (response.data) {
                setInput(prevInput => ({
                    ...prevInput,
                    rate: response.data,
                    amt: parseFloat(parseFloat(response.data) * parseFloat(input.liter)).toFixed(2)
                }))
                // if (inputRefs.current[4]) {
                //   inputRefs.current[4].focus();
                // }
            }

        }).catch(err => {
            console.log(err);
        });
    }

    async function getprevfatsnf() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let typeid = null;
        if (input.type === "म्हैस") {
            typeid = "1";
        }
        else {
            typeid = "2";
        }
        axios.post('https://idairy.co.in/dairy/php/cust/getprev.php', { kno: input.kno, typeid: typeid, type: input.type, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2, date: input.date }).then(function sample(response) {
            if (response.data) {
                //  console.log(response.data);
                setPrev(response.data);
            }


        }).catch(err => {
            console.log(err);
        });
    }

    async function sendnotification(date, time, liter, fat, snf, rate, amt) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            date: date,
            time: time,
            liter: liter,
            fat: fat,
            snf: snf,
            rate: rate,
            amt: amt,
            kendra: localStorage.getItem("kendra"),
            topic: localStorage.getItem("contact") + input.kno,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }

        axios.post('https://idairy.co.in/dairy/php/cust/sendnoti.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            notify(response.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const savesanklan = () => {

        var mainuser2 = jsEncode.encode("mamapr", 125);

        if(parseFloat(input.amt) != parseFloat(parseFloat(input.rate) * parseFloat(input.liter)).toFixed(2))
        {
            notifyerror("चुकीची सकाळ रक्कम!")
            return;
        }

        if(parseFloat(input.amtevn) != parseFloat(parseFloat(input.rateevn) * parseFloat(input.literevn)).toFixed(2))
            {
                notifyerror("चुकीची संध्याकाळ रक्कम!")
                return;
            }

        if (input.time === "सकाळ") {
            if (input.date === "" || input.time === "वेळ निवडा" || input.time === "" || input.kno === "" || input.name === "" || input.type === "" || input.liter === "" || input.fat === "" || input.snf === "" || input.rate === "" || input.amt === "") {
                notifyerror("अपूर्ण एंट्री माहिती!..");
                return;
            }
        }
        if (input.time === "संध्याकाळ") {
            if (input.date === "" || input.time === "वेळ निवडा" || input.time === "" || input.kno === "" || input.name === "" || input.type === "" || input.literevn === "" || input.fatevn === "" || input.snfevn === "" || input.rateevn === "" || input.amtevn === "") {
                notifyerror("अपूर्ण एंट्री माहिती!..");
                return;
            }
        }

        if (input.time === "दोन्ही") {
            if (input.date === "" || input.time === "वेळ निवडा" || input.time === "" || input.kno === "" || input.name === "" || input.type === "" || input.liter === "" || input.fat === "" || input.snf === "" || input.rate === "" || input.amt === "" || input.literevn === "" || input.fatevn === "" || input.snfevn === "" || input.rateevn === "" || input.amtevn === "") {
                notifyerror("अपूर्ण एंट्री माहिती!..");
                return;
            }
        }
        let rawData = {
            kno: input.kno,
            type: input.type,
            time: input.time,
            vibhag: input.vibhag,
            date: input.date,
            date1: dates.date1,
            date2: dates.date2,
            liter: input.liter,
            fat: input.fat,
            snf: input.snf,
            rate: input.rate,
            amt: input.amt,
            literevn: input.literevn,
            fatevn: input.fatevn,
            snfevn: input.snfevn,
            rateevn: input.rateevn,
            amtevn: input.amtevn,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            flag: newedit,
            sredit: sredit,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }

        axios.post('https://idairy.co.in/dairy/php/cust/savesanklanbykno2.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (response.data.trim() === "mor successfully updated!".trim() || response.data.trim() === "evn successfully updated!".trim() || response.data.trim() === "mor successfully updated!evn successfully updated!".trim()) {
                notify("एंट्री सेव्ह झाली!");
                setNewedit("new");
                insertnewentry();
                setPrev([]);
                setInput(prevInput => ({
                    ...prevInput,

                    liter: "",
                    fat: "",
                    snf: "",
                    rate: "",
                    amt: "",
                    literevn: "",
                    fatevn: "",
                    snfevn: "",
                    rateevn: "",
                    amtevn: ""

                }))

                setInput(prevInput => {
                    // Create a new Date object from the current date
                    const newDate = new Date(prevInput.date);
                    // Add 1 day
                    newDate.setDate(newDate.getDate() + 1);
                    // Convert the date back to the format 'YYYY-MM-DD'
                    const updatedDate = newDate.toISOString().substr(0, 10);
                    // Return the updated input state
                    return {
                        ...prevInput,
                        date: updatedDate
                    };
                });

                if (response.data.trim() === "mor successfully updated!".trim() || response.data.trim() === "mor successfully updated!evn successfully updated!".trim()) {
                    if (inputRefs.current[4]) {
                        inputRefs.current[4].focus();
                    }
                }
                else if (response.data.trim() === "evn successfully updated!".trim()) {
                    if (inputRefs.current[7]) {
                        inputRefs.current[7].focus();
                    }
                }
                getsanklan();
            }


        }).catch(err => {
            console.log(err);
        });
    }

    const insertnewentry = () => {
        // Create a new item object
        const newItem = {
            sr: "new", // Replace with the appropriate value
            date: input.date, // Replace with the appropriate value
            year_week: "-", // Replace with the appropriate value
            time: input.time, // Replace with the appropriate value
            kno: input.kno, // Replace with the appropriate value  
            vibhag: input.vibhag,
            type: input.type, // Replace with the appropriate value
            liter: input.liter, // Replace with the appropriate value
            fat: input.fat, // Replace with the appropriate value
            previous_fat: "Refresh", // Replace with the appropriate value
            differencefat: "Refresh", // Replace with the appropriate value
            snf: input.snf, // Replace with the appropriate value
            rate: input.rate, // Replace with the appropriate value
            amt: input.amt, // Replace with the appropriate value
            mainuser: localStorage.getItem("contact"),// Replace with the appropriate value
            name: input.name, // Replace with the appropriate value
            tcustomer: "0" // Replace with the appropriate value
        };

        // Update the state to include the new item
        //setDataSource((prevDataSource) => [...prevDataSource, newItem]);
        setDataSource((prevDataSource) => [newItem, ...prevDataSource]);
    }

    // useEffect(() => {
    //     const totals = DataSource.reduce(
    //         (accumulator, user) => {
    //             const liter = parseFloat(user.liter);
    //             const fat = parseFloat(user.fat);
    //             const snf = parseFloat(user.snf);
    //             const amt = parseFloat(user.amt);

    //             accumulator.totalLiter += liter;
    //             accumulator.tfat += fat;
    //             accumulator.tsnf += snf;
    //             accumulator.tamt += amt;

    //             if (user.type === "म्हैस") {
    //                 accumulator.totalLitermh += liter;
    //                 accumulator.tfatmh += fat;
    //                 accumulator.tsnfmh += snf;
    //                 accumulator.tamtmh += amt;
    //                 accumulator.tentrymh += 1;
    //             } else if (user.type === "गाय") {
    //                 accumulator.totalLitergay += liter;
    //                 accumulator.tfatgay += fat;
    //                 accumulator.tsnfgay += snf;
    //                 accumulator.tamtgay += amt;
    //                 accumulator.tentrygay += 1;
    //             }
    //             return accumulator;
    //         },
    //         {
    //             totalLiter: 0,
    //             tfat: 0,
    //             totalLitermh: 0,
    //             tfatmh: 0,
    //             tentrymh: 0,
    //             totalLitergay: 0,
    //             tfatgay: 0,
    //             tentrygay: 0,
    //             tsnf: 0,
    //             tsnfmh: 0,
    //             tsnfgay: 0,
    //             tamt: 0,
    //             tamtmh: 0,
    //             tamtgay: 0
    //         }
    //     );

    //     const {
    //         totalLiter,
    //         tfat,
    //         totalLitermh,
    //         tfatmh,
    //         tentrymh,
    //         tfatgay,
    //         tentrygay,
    //         totalLitergay,
    //         tsnf,
    //         tsnfmh,
    //         tsnfgay,
    //         tamtmh,
    //         tamtgay,
    //         tamt
    //     } = totals;

    //     DataSource.map((user, index) =>
    //     (
    //         setTcust(prevInput => ({
    //             ...prevInput,
    //             tliter: totalLiter.toFixed(2),
    //             tavg: parseFloat(parseFloat(tfat) / parseFloat(DataSource.length)).toFixed(1),
    //             tsnfavg: parseFloat(parseFloat(tsnf) / parseFloat(DataSource.length)).toFixed(1),
    //             tmh: totalLitermh.toFixed(2),
    //             mhavg: parseFloat(parseFloat(tfatmh) / parseFloat(tentrymh)).toFixed(1),
    //             mhsnfavg: parseFloat(parseFloat(tsnfmh) / parseFloat(tentrymh)).toFixed(1),
    //             tgay: totalLitergay.toFixed(2),
    //             gayavg: parseFloat(parseFloat(tfatgay) / parseFloat(tentrygay)).toFixed(1),
    //             gaysnfavg: parseFloat(parseFloat(tsnfgay) / parseFloat(tentrygay)).toFixed(1),
    //             tamt: parseFloat(parseFloat(tamt)).toFixed(1),
    //             tamtmh: parseFloat(parseFloat(tamtmh)).toFixed(1),
    //             tamtgay: parseFloat(parseFloat(tamtgay)).toFixed(1),
    //         }))
    //     ))
    //     setTcust(prevInput => ({
    //         ...prevInput,
    //         tcompleted: DataSource.length,
    //     }))
    // }, [DataSource])

    const updateentry = () => {
        // Create a new item object
        const modifiedItem = {
            date: input.date, // Replace with the appropriate value
            year_week: "-", // Replace with the appropriate value
            time: input.time, // Replace with the appropriate value
            kno: input.kno, // Replace with the appropriate value
            type: input.type, // Replace with the appropriate value
            liter: input.liter, // Replace with the appropriate value
            fat: input.fat, // Replace with the appropriate value
            snf: input.snf, // Replace with the appropriate value
            rate: input.rate, // Replace with the appropriate value
            amt: input.amt, // Replace with the appropriate value
            mainuser: localStorage.getItem("contact"),// Replace with the appropriate value
            name: input.name, // Replace with the appropriate value
            tcustomer: "0" // Replace with the appropriate value
        };

        if (!custSank) {
            const updatedDataSource = DataSource.map((item) => {
                if (item.kno === input.kno && item.type === input.type) {
                    return { ...item, ...modifiedItem };
                } else {
                    return item;
                }
            });

            setDataSource(updatedDataSource);
        }
        else {
            const updatedDataSource = DataSource.map((item) => {
                if (item.kno === input.kno && item.type === input.type && item.date === input.date) {
                    return { ...item, ...modifiedItem };
                } else {
                    return item;
                }
            });

            setDataSource(updatedDataSource);
        }

    }

    const removeentry = (date, time, kno, type) => {
        // Create a new item object

        const shouldRemoveItem = (item) => {
            return item.kno === kno && item.type === type;
        };

        // Remove items from the DataSource based on user input criteria (kno and type)

        const updatedDataSource = DataSource.filter((item) => !shouldRemoveItem(item));

        // Update the state with the modified DataSource
        setDataSource(updatedDataSource);

    }

    const handleFocus = (e) => {

        if (e.target.name === "snf") {
            // setTimeout(() => {
            //     if (inputRefs.current[10].value === '0' || inputRefs.current[10].value === '0.0') {
            //         setInput(prevInput => ({ ...prevInput, snf: '' }));
            //     }
            // }, 7);


            if (input.type === 'म्हैस') {
                setInput({ ...input, snf: '9' });
            } else if (input.type === 'गाय') {
                setInput({ ...input, snf: '8.5' });
            }

        }
        if (e.target.name === "snfevn") {

            // setTimeout(() => {
            //     if (inputRefs.current[7].value === '0' || inputRefs.current[7].value === '0.0') {
            //         setInput(prevInput => ({ ...prevInput, snfevn: '' }));
            //     }
            // }, 7);



            if (input.type === 'म्हैस') {
                setInput({ ...input, snfevn: '9' });
            } else if (input.type === 'गाय') {
                setInput({ ...input, snfevn: '8.5' });
            }

        }
    };

    function formatDate(inputDate) {
        const options = { day: '2-digit', month: 'short' };
        return new Date(inputDate).toLocaleDateString('en-US', options);
    }

    async function deletesanklan(sr, date, time, kno, type) {

        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/deletesanklan2.php', { sr: sr, date: date,date1: dates.date1,date2: dates.date2,time: time, kno: kno, type: type, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                // console.log(response.data);
                notify(response.data);
                setNewedit("new");
                insertnewentry();
                setPrev([]);
                setInput(prevInput => ({
                    ...prevInput,

                    liter: "",
                    fat: "",
                    snf: "",
                    rate: "",
                    amt: "",
                    literevn: "",
                    fatevn: "",
                    snfevn: "",
                    rateevn: "",
                    amtevn: ""

                }))

                if (inputRefs.current[3]) {
                    inputRefs.current[3].focus();
                }

                handledeleteclose();

                getsanklan();
            }

        }).catch(err => {
            console.log(err);
        });
    }

    const handleBlur = (e) => {

        if (e.target.name === "fat" || e.target.name === "snf") {
         
            if (input.fat !== "") {
                setInput(prevInput => ({
                    ...prevInput,
                    fat: parseFloat(input.fat).toFixed(1),
                    snf: parseFloat(input.snf).toFixed(1)  // Convert to one decimal place
                }));
            }
        }

        if (e.target.name === "fatevn" || e.target.name === "snfevn") {
           
            if (input.fatevn !== "") {
                setInput(prevInput => ({
                    ...prevInput,
                    fatevn: parseFloat(input.fatevn).toFixed(1),
                    snfevn: parseFloat(input.snfevn).toFixed(1)  // Convert to one decimal place
                }));
            }
        }
    };


    const handleDelete = (time, date) => {
        setDeletetime(time);
        setInput(prevInput => ({
            ...prevInput,
            date: date
        }));
        refdelete.current.click();
    }

    const handledeleteclose = () => {
        refdeleteclose.current.click();
    }



    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };



    return (

        <>

            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refdelete}
                data-bs-target="#deletebill"
            >
            </button>

            <div className="modal fade" id="deletebill" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel">एंट्री डिलीट करायची?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeleteclose}  >नाही</button>
                            <button type="button" className="btn btn-danger" onClick={e => deletesanklan(input.sr, input.date, deletetime, input.kno, input.type)} >होय</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sanklanmain">
                <div className="firstrowsank">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <div className="colmain">
                        <div className="firstrow2kno">

                            <div className="mb-2 knoo">
                                <span className='subheadsank'>रिपोर्ट प्रकार</span>
                                <select onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} className={newedit === "new" ? "form-control" : "form-control editmode"} id="reporttype" name="reporttype" value={input.reporttype} onChange={handleChange} >
                                    <option key={0} value="संकलन">संकलन</option>
                                    <option key={1} value="उचल/एड्व्हांस">उचल/एड्व्हांस</option>
                                    <option key={2} value="खाद्य">खाद्य</option>
                                </select>
                            </div>

                            <div className="midd">
                                <span>||</span>
                            </div>

                            <div className="rowsank" id='secondkno'>
                                <div className="rowbill">
                                    <div className="mb-2 knoo">
                                        <span className='subheadsank'>दिनांक</span>
                                        <input type="date" onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} className="form-control" autoComplete='off' id="date1" name="date1" value={dates.date1} onChange={handleChangedates} ></input>
                                    </div>

                                    <span className='subheadbill'>ते</span>

                                    <div className="mb-2 knoo">
                                        <span className='subheadsank'>दिनांक</span>
                                        <input disabled type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={dates.date2} onChange={handleChangedates} ></input>
                                    </div>

                                </div>
                            </div>

                            <div className="midd2">
                                <span>||</span>
                            </div>

                            <div className="rowsank">
                                <div className="rowsank">
                                    <div className="mb-2 knoo">
                                        <span className='subheadsank'>खाते क्रमांक</span>
                                        <input onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="kno" name="kno" value={input.kno} onChange={handleChange} ></input>
                                    </div>
                                    <div className="mb-2 knoo">
                                        <span className='subheadsank'>सभासदाचे नाव <span className='prev'>{input.vibhag ? " (" + input.vibhag + ")" : ''}</span></span>
                                        <input type="text" disabled className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="name" name="name" value={input.name} onChange={handleChange} ></input>
                                    </div>
                                    <div className="mb-2 knoo">
                                        <span className='subheadsank'>प्रकार</span>
                                        <select onBlur={getsanklan} onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} className={newedit === "new" ? "form-control" : "form-control editmode"} id="type" name="type" value={input.type} onChange={handleChange} >
                                            <option key={0} value="प्रकार निवडा">प्रकार निवडा</option>
                                            <option key={1} value="म्हैस">म्हैस</option>
                                            <option key={2} value="गाय">गाय</option>
                                        </select>
                                    </div>
                                    <div className="mb-2 knoo">
                                        <span className='subheadsank'>वेळ</span>
                                        <select onBlur={handleboxex} onKeyDown={e => handleEnterKey(e, 4)} ref={ref => (inputRefs.current[4] = ref)} list="encodings" autoComplete='off' className={newedit === "new" ? "form-control" : "form-control editmode"} id="time" name="time" value={input.time} onChange={handleChange}>
                                            <option key={0} value="दोन्ही">दोन्ही</option>
                                            <option key={1} value="सकाळ">सकाळ</option>
                                            <option key={2} value="संध्याकाळ">संध्याकाळ</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='linesank' />

                <div className="firstrow2kno">
                    <div className="rowkno">
                        <div className="intial">
                            <span></span>
                        </div>
                        <div className="firstkno">
                            <span className='byknolbl'>--------------------सकाळ------------------------</span>
                        </div>

                        <div className="secondkno">
                            <span className='byknolbl'>-------------------संध्याकाळ-----------------------</span>
                        </div>
                    </div>

                </div>
                <div id='rowbykno' className="row1sank">
                    <div className="rowkno">
                        <div className="intial2">
                            <div className="mb-2 knoo">
                                <span onClick={sendnotification} className='subheadsank'>दिनांक</span>
                                <input onKeyDown={e => handleEnterKey(e, 5)} ref={ref => (inputRefs.current[5] = ref)} onBlur={e => getoldentry(input.date, "1")} type="date" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="date" name="date" value={input.date} onChange={handleChange} ></input>
                            </div>
                        </div>

                        <div className="firstkno2">
                            <div className="mb-2 knoo">
                                <span id='literlblmor' className='subheadsank'>लिटर<span className='prev'>{Prev.liter ? " (" + Prev.liter + ")" : ''}</span></span>
                                <input onBlur={setoldtofalse} disabled={lock === "mor" || lock === "both" ? false : true} onKeyDown={e => handleEnterKey(e, 6)} ref={ref => (inputRefs.current[6] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="liter" name="liter" value={input.liter} onChange={handleChange}
                                    onFocus={() => {
                                        setTimeout(() => {
                                            const ref4 = inputRefs.current[6];
                                            if (ref4 && (ref4.value === '0' || ref4.value === '0.0' || ref4.value === '0.00')) {
                                                setInput(prevInput => ({ ...prevInput, liter: '' }));
                                            }
                                        }, 7);
                                    }} ></input>
                            </div>
                            <div id='fatlblmor' className="mb-2 knoo">
                                <span className='subheadsank'>फॅट <span className='prev'>{Prev.fat ? " (" + Prev.fat + ")" : ''}</span></span>
                                <input disabled={lock === "mor" || lock === "both" ? false : true} onKeyDown={e => handleEnterKey(e, 7)} onBlur={handleBlur} ref={ref => (inputRefs.current[7] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="fat" name="fat" value={input.fat} onChange={handleChangefs} onFocus={() => {
                                    setTimeout(() => {
                                        const ref4 = inputRefs.current[7];
                                        if (ref4 && (ref4.value === '0' || ref4.value === '0.0' || ref4.value === '0.00')) {
                                            setInput(prevInput => ({ ...prevInput, fat: '' }));
                                        }
                                    }, 7);
                                }} ></input>
                            </div>
                            <div id='snflblmor' className="mb-2 knoo">
                                <span className='subheadsank'>एसएनएफ <span className='prev'>{Prev.snf ? " (" + Prev.snf + ")" : ''}</span></span>
                                <input disabled={lock === "mor" || lock === "both" ? false : true} onKeyDown={e => handleEnterKey(e, 8)} onBlur={handleBlur} ref={ref => (inputRefs.current[8] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="snf" name="snf" value={input.snf} onChange={handleChangefs} onFocus={handleFocus}
                                ></input>
                            </div>
                            <div id='ratelblmor' className="mb-2 knoo">
                                <span className='subheadsank'>दर</span>
                                <input disabled type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="rate" name="rate" value={input.rate} onChange={handleChange} ></input>
                            </div>
                            <div id='amtlblmor' className="mb-2 knoo">
                                <span className='subheadsank'>रक्कम</span>
                                <input disabled type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="amt" name="amt" value={input.amt ? parseFloat(input.amt.replace(/,/g, '')).toFixed(2) : '0.00'} onChange={handleChange} ></input>
                            </div>
                        </div>

                        <div className="secondkno2">
                            <div className="mb-2 knoo">
                                <span id='literlblevn' className='subheadsank'>लिटर<span className='prev'>{Prev.liter ? " (" + Prev.liter + ")" : ''}</span></span>
                                <input onBlur={setoldtofalse} disabled={lock === "evn" || lock === "both" ? false : true} onKeyDown={e => handleEnterKey(e, 9)} ref={ref => (inputRefs.current[9] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="literevn" name="literevn" value={input.literevn} onChange={handleChange} onFocus={() => {
                                    setTimeout(() => {
                                        const ref4 = inputRefs.current[9];
                                        if (ref4 && (ref4.value === '0' || ref4.value === '0.0' || ref4.value === '0.00')) {
                                            setInput(prevInput => ({ ...prevInput, literevn: '' }));
                                        }
                                    }, 7);
                                }}></input>
                            </div>
                            <div className="mb-2 knoo">
                                <span id='fatlblevn' className='subheadsank'>फॅट <span className='prev'>{Prev.fat ? " (" + Prev.fat + ")" : ''}</span></span>
                                <input disabled={lock === "evn" || lock === "both" ? false : true} onKeyDown={e => handleEnterKey(e, 10)} onBlur={handleBlur} ref={ref => (inputRefs.current[10] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="fatevn" name="fatevn" value={input.fatevn} onChange={handleChangefs} onFocus={() => {
                                    setTimeout(() => {

                                        const ref4 = inputRefs.current[10];
                                        if (ref4 && (ref4.value === '0' || ref4.value === '0.0' || ref4.value === '0.00')) {
                                            setInput(prevInput => ({ ...prevInput, fatevn: '' }));
                                        }
                                    }, 7);
                                }} ></input>
                            </div>
                            <div className="mb-2 knoo">
                                <span id='snflblevn' className='subheadsank'>एसएनएफ <span className='prev'>{Prev.snf ? " (" + Prev.snf + ")" : ''}</span></span>
                                <input disabled={lock === "evn" || lock === "both" ? false : true} onKeyDown={e => handleEnterKey(e, 11)} onBlur={handleBlur} ref={ref => (inputRefs.current[11] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="snfevn" name="snfevn" value={input.snfevn} onChange={handleChangefs} onFocus={handleFocus}></input>
                            </div>
                            <div className="mb-2 knoo">
                                <span id='ratelblevn' className='subheadsank'>दर</span>
                                <input disabled type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="rateevn" name="rateevn" value={input.rateevn} onChange={handleChange} ></input>
                            </div>
                            <div className="mb-2 knoo">
                                <span id='amtlblevn' className='subheadsank'>रक्कम</span>
                                <input disabled type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="amtevn" name="amtevn" value={input.amtevn ? parseFloat(input.amtevn.replace(/,/g, '')).toFixed(2) : '0.00'} onChange={handleChange} ></input>
                            </div>
                        </div>
                        <div className="mb-2 jmaa">

                            <span className='subheadsank'>.</span>
                            <button onClick={savesanklan} ref={ref => (inputRefs.current[12] = ref)} type="submit" className="btn btn-primary mx-1 sanksmall">
                                {newedit === "new" ?
                                    "सेव्ह" :
                                    "अपडेट"
                                }

                                {/* onKeyDown={e => handleEnterKeybutton(e, 11)} */}

                            </button>
                        </div>
                        <div className="mb-2 lastimg">
                        </div>
                    </div>
                </div>


                <div className="firstrow2kno">
                    <div className="table-containersankkno">
                        <table id="myTable" className="table table-bordered">
                            <thead>
                                <tr>
                                    <th className='thsmallkno' scope="col">दिनांक</th>
                                    <th className='thsmallkno' scope="col">लिटर</th>
                                    <th className='thsmallkno' scope="col">फॅट</th>
                                    <th className='thsmallkno' scope="col">SNF</th>
                                    <th className='thsmallkno' scope="col">दर</th>
                                    <th className='thsmallkno' scope="col">रक्कम</th>
                                    <th className='thaction' scope="col">एक्शन</th>
                                    <th className='thsmallkno' scope="col">लिटर</th>
                                    <th className='thsmallkno' scope="col">फॅट</th>
                                    <th className='thsmallkno' scope="col">SNF</th>
                                    <th className='thsmallkno' scope="col">दर</th>
                                    <th className='thsmallkno' scope="col">रक्कम</th>
                                    <th className='thaction' scope="col">एक्शन</th>
                                </tr>
                            </thead>
                            <tbody>
                                {value.length > 0 ? tableFilter.map((user, index) =>
                                    ''
                                )
                                    :
                                    DataSource.map((user, index) =>
                                        <tr key={index}>
                                            <td className={'thsmallbykno'}>{formatDate(user.date)}</td>
                                            <td className={user.litermor == 0 ? 'thsmallbyknored' : 'thsmallbykno'}>{user.litermor}</td>
                                            <td className={user.litermor == 0 ? 'thsmallbyknored' : 'thsmallbykno'}>{user.fatmor}</td>
                                            <td className={user.litermor == 0 ? 'thsmallbyknored' : 'thsmallbykno'}>{user.snfmor}</td>
                                            <td className={user.litermor == 0 ? 'thsmallbyknored' : 'thsmallbykno'}>{user.ratemor}</td>
                                            <td className={user.litermor == 0 ? 'thsmallbyknored' : 'thsmallbykno'}> {user.amtevn ? parseFloat(user.amtmor.replace(/,/g, '')).toFixed(2) : '0.00'}</td>
                                            <td className='thaction'><UilEditAlt onClick={() => { getoldentry(user.date, "1") }} className="icons" /><UilTrash style={{ margin: '1px', height: '18px', width: '18px' }} onClick={() => { handleDelete("सकाळ", user.date) }} v className="icons" /></td>
                                            <td className={user.literevn == 0 ? 'thsmallbyknored' : 'thsmallbykno'}>{user.literevn}</td>
                                            <td className={user.literevn == 0 ? 'thsmallbyknored' : 'thsmallbykno'}>{user.fatevn}</td>
                                            <td className={user.literevn == 0 ? 'thsmallbyknored' : 'thsmallbykno'}>{user.snfevn}</td>
                                            <td className={user.literevn == 0 ? 'thsmallbyknored' : 'thsmallbykno'}>{user.rateevn}</td>
                                            <td className={user.literevn == 0 ? 'thsmallbyknored' : 'thsmallbykno'}>
                                                {user.amtevn ? parseFloat(user.amtevn.replace(/,/g, '')).toFixed(2) : '0.00'}
                                            </td>


                                            <td className='thaction'><UilEditAlt onClick={() => { getoldentry(user.date, "2") }} className="icons" /><UilTrash style={{ margin: '1px', height: '18px', width: '18px' }} onClick={() => { handleDelete("संध्याकाळ", user.date) }} v className="icons" /></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                    </div>
                </div>

                <div className='below'>
                    <div className="vertikno">
                        <span className="subheadsank">सकाळ लिटर -  {tcust.tlitermor}</span>
                        <span className="subheadsank">सकाळ रक्कम -  {tcust.tamtmor}</span>
                    </div>
                    <div className="vertikno">
                        <span className="subheadsank">संध्याकाळ लिटर  -  {tcust.tliterevn}</span>
                        <span className="subheadsank">संध्याकाळ रक्कम -  {tcust.tamtevn}</span>
                    </div>
                    <div className="vertikno">
                        <span className="subheadsank">एकूण रक्कम -  {tcust.totalamount}</span>
                        <span className="subheadsank">-</span>
                    </div>

                    <div className="vertikno">
                        <div className="middbelow">
                            <span>|</span>
                        </div>
                        <div className="middbelow">
                            <span>|</span>
                        </div>
                    </div>

                    <div className="vertikno">
                        <span className="subheadsank">मागील एड्व्हांस -  {tcust.madv}</span>
                        <span className="subheadsank">चालू एड्व्हांस-  {tcust.cadv}</span>
                    </div>
                    <div className="vertikno">
                        <span className="subheadsank">मागील खाद्य -  {tcust.mkhadya}</span>
                        <span className="subheadsank">चालू खाद्य -  {tcust.ckhadya}</span>
                    </div>

                </div>

                <div hidden className="tboxbelow">
                    {tcust.tliter ? (
                        <span>
                            {tcust.tmh && parseFloat(tcust.tmh) > 0 && (
                                <span>
                                    <span className="subheadsank">म्हैस लिटर -</span>
                                    <span className="subheadsankgreen"> {tcust.tmh}</span>
                                    <span className="subheadsank">(सरासरी फॅट/SNF:</span>{" "}
                                    <span className="subheadsank">{tcust.mhavg + " / " + tcust.mhsnfavg}</span>
                                    <span className="subheadsank"> ,ए.रक्कम:</span>{" "}
                                    <span className="subheadsank">{tcust.tamtmh})</span>
                                </span>
                            )}

                            {tcust.tgay && parseFloat(tcust.tgay) > 0 && (
                                <span>
                                    <span className="subheadsank"> | गाय लिटर -</span>
                                    <span className="subheadsankgreen"> {tcust.tgay}</span>
                                    <span className="subheadsank"> (सरासरी फॅट/SNF:</span>{" "}
                                    <span className="subheadsank">{tcust.gayavg + " / " + tcust.gaysnfavg}</span>
                                    <span className="subheadsank"> ,ए.रक्कम:</span>{" "}
                                    <span className="subheadsank">{tcust.tamtgay})</span>
                                </span>
                            )}
                            <span className="subheadsank"> | एकूण लिटर:</span>{" "}
                            <span className="subheadsankgreen">{tcust.tliter}</span>
                            <span className="subheadsank"> (सरासरी फॅट/SNF:</span>{" "}
                            <span className="subheadsank">{tcust.tavg + " / " + tcust.tsnfavg}</span>
                            <span className="subheadsank"> | ए.रक्कम:</span>{" "}
                            <span className="subheadsankgreen">{tcust.tamt})</span>
                        </span>

                    ) : (
                        <>
                            <span className="subheadsankgreen">कोणत्याही एंट्रीज उपलब्ध नाहीत</span>
                        </>
                    )}

                </div>
                <ToastContainer />
            </div >
        </>
    )
}

export default Sanklan

import React, { useEffect, useRef, useState } from 'react'
import './Mobileuser.css'
import back from "../../imgs/backexam.png";
import milkcan from "../../imgs/milkcan.png";
import farmer from "../../imgs/farmer.png";
import khadya from "../../imgs/khadya.png";
import cash from "../../imgs/cash.png";
import time from "../../imgs/time.png";
import weight from "../../imgs/wightmachine.png";

import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';

const Mobileuser = (props) => {


    const inputRefs = useRef([]);
    const [newedit, setNewedit] = useState("new");
    const [input, setInput] = useState({ sr: "", name: "", address: "-", mobno: "-", password: "Auto", usertype: "", mhsnf: "9.0", gaysnf: "8.5" });
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [khnames, setKhnames] = useState([]);

    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [stock, setStock] = useState();
    const [sredit, setSredit] = useState('');


    const [literauto, setLiterauto] = useState('बंद');
    const [fatauto, setFatauto] = useState('बंद');

    const [sanknew, setSanknew] = useState('चालू');
    const [sankedit, setSankedit] = useState('चालू');
    const [sankdelete, setSankdelete] = useState('बंद');

    const [khsalenew, setkhSalenew] = useState('चालू');
    const [khsaleedit, setKhsaleedit] = useState('बंद');
    const [khsaledelete, setKhsaledelete] = useState('बंद');


    const [advnew, setAdvnew] = useState('चालू');
    const [advedit, setAdvedit] = useState('बंद');
    const [advdelete, setAdvdelete] = useState('बंद');

    const [ratibnew, setRatibnew] = useState('चालू');
    const [ratibedit, setRatibedit] = useState('बंद');
    const [ratibdelete, setRatibdelete] = useState('बंद');

    const [morningFrom, setMorningFrom] = useState('05:00');  // 5:00 AM
    const [morningTo, setMorningTo] = useState('12:00');      // 12:00 PM
    const [eveningFrom, setEveningFrom] = useState('17:00');  // 5:00 PM
    const [eveningTo, setEveningTo] = useState('23:00');      // 11:00 PM

    const [autosnf, setAutosnf] = useState('चालू');
    const [summ, setSumm] = useState('चालू');
    const [ekun, setEkun] = useState('चालू');

    // Function to handle time input changes
    const handleTimeChange = (setter) => (event) => {
        setter(event.target.value);
    };

    // Convert time to 12-hour format
    const convertTo12Hour = (time) => {
        if (!time) return '';
        let [hours, minutes] = time.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${period}`;
    };

    function handleEnterKey(event, currentIndex) {

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

    }



    useEffect(() => {

        if (input.kno === "") {
            setInput(prevInput => ({
                ...prevInput,
                kno: "",
                name: "",
                type: "",
                amt: "",
                amt: "",
                remark: "",
                mhcomm: "",
                gaycomm: "",

            }))

            setNewedit("new");
        }
        else {
            // getcustdata(input.kno);
            let custlisttemp = JSON.parse(localStorage.getItem("custlist"));
            for (let i = 0; i < custlisttemp.length; i++) {
                setInput(prevInput => ({
                    ...prevInput,
                    name: ''
                }));
                const user = custlisttemp[i];
                if (user.kno === input.kno) {
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.cname
                    }));
                    break; // Exit the loop once the condition is met
                }
            }

        }
    }, [input.kno])


    async function getmaxno() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getmaxnomobileuser.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {


            if (response.data) {
                setInput(prevInput => ({
                    ...prevInput,
                    sr: parseInt(response.data) + 1
                }));
            }
            else {
                setInput(prevInput => ({
                    ...prevInput,
                    sr: 1
                }));

            }
        }).catch(err => {
            console.log(err);
        });
    }



    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            event.preventDefault();
            saveratib();
            let nextIndex = null;
            if (currentIndex === 0) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        else if (event.key === "Backspace") {
            if (event.target.tagName === "BUTTON") {
                if (inputRefs.current[4]) {
                    inputRefs.current[4].focus();
                }
            }
        }
    }


    const saveratib = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.sr === "" || input.name === "" || input.address === "" || (input.mobno === "")) {
            notifyerror("अपूर्ण एंट्री माहिती!");
            return;
        }
        let rawData = {
            sr: input.sr,
            name: input.name,
            address: input.address,
            mobno: input.mobno,
            password: input.password,
            usertype: input.usertype,
            sanknew: sanknew,
            sankedit: sankedit,
            sankdelete: sankdelete,
            khsalenew: khsalenew,
            khsaleedit: khsaleedit,
            khsaledelete: khsaledelete,
            advnew: advnew,
            advedit: advedit,
            advdelete: advdelete,
            ratibnew: ratibnew,
            ratibedit: ratibedit,
            ratibdelete: ratibdelete,
            morningFrom: morningFrom,
            morningTo: morningTo,
            eveningFrom: eveningFrom,
            eveningTo: eveningTo,
            weight: literauto,
            fat: fatauto,
            autosnf: autosnf,
            ekun: ekun,
            mhsnf: input.mhsnf,
            gaysnf: input.gaysnf,
            flag: newedit,
            sredit: sredit,

            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,

        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/savemobileuser4.php', formData, {
            'Content-Type': 'multipart/form-data',
        }).then(function sample(response) {
            if (newedit === "new") {
                if (response.data.trim() === "Saved.") {
                    notify("सेव्ह झाले!");
                    setInput(prevInput => ({
                        ...prevInput,
                        sr: parseInt(input.sr) + 1,
                        name: "",
                        address: "-",
                        mobno: "-",
                        usertype: ""
                    }))
                    setSredit("");
                    setNewedit("new");
                    getsangh();
                    if (inputRefs.current[0]) {
                        inputRefs.current[0].focus();
                    }
                }
            }
            else {
                if (response.data.trim() === "updated.") {
                    notify("माहिती अपडेट झाली!")
                    setInput(prevInput => ({
                        ...prevInput,
                        sr: parseInt(input.sr) + 1,
                        name: "",
                        address: "-",
                        mobno: "-",
                        usertype: ""
                    }))
                    setSredit("");
                    setNewedit("new");
                    getsangh();
                    if (inputRefs.current[0]) {
                        inputRefs.current[0].focus();
                    }
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        getmaxno();
        getsangh();
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, [])

    async function getsangh() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getmobileusers.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                setDataSource(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handleToggle = () => {
        setLiterauto(literauto === 'चालू' ? 'बंद' : 'चालू');
    };

    const handleToggleekun = () => {
        setEkun(ekun === 'चालू' ? 'बंद' : 'चालू');
    };

    const handleTogglefat = () => {
        setFatauto(fatauto === 'चालू' ? 'बंद' : 'चालू');
    };

    const handleTogglesanknew = () => {
        setSanknew(sanknew === 'चालू' ? 'बंद' : 'चालू');
    };
    const handleTogglesankedit = () => {
        setSankedit(sankedit === 'चालू' ? 'बंद' : 'चालू');
    };
    const handleTogglesankdelete = () => {
        setSankdelete(sankdelete === 'चालू' ? 'बंद' : 'चालू');
    };


    const handleTogglekhsalenew = () => {
        setkhSalenew(khsalenew === 'चालू' ? 'बंद' : 'चालू');
    };
    const handleTogglekhsaleedit = () => {
        setKhsaleedit(khsaleedit === 'चालू' ? 'बंद' : 'चालू');
    };
    const handleTogglekhsaledelete = () => {
        setKhsaledelete(khsaledelete === 'चालू' ? 'बंद' : 'चालू');
    };

    const handleToggleadvnew = () => {
        setAdvnew(advnew === 'चालू' ? 'बंद' : 'चालू');
    };
    const handleToggleadvedit = () => {
        setAdvedit(advedit === 'चालू' ? 'बंद' : 'चालू');
    };
    const handleToggleadvdelete = () => {
        setAdvdelete(advdelete === 'चालू' ? 'बंद' : 'चालू');
    };


    const handleToggleratibnew = () => {
        setRatibnew(ratibnew === 'चालू' ? 'बंद' : 'चालू');
    };
    const handleToggleratibedit = () => {
        setRatibedit(ratibedit === 'चालू' ? 'बंद' : 'चालू');
    };
    const handleToggleratibdelete = () => {
        setRatibdelete(ratibdelete === 'चालू' ? 'बंद' : 'चालू');
    };

    const handleToggleautosnf = () => {
        setAutosnf(autosnf === 'चालू' ? 'बंद' : 'चालू');
    };


    // useEffect(()=>{
    //     console.log(literauto);
    // },[literauto])

    // useEffect(()=>{
    //     console.log(fatauto);
    // },[fatauto])


    const handleedit = (sr, name, address, mobno, password, usertype, weight, fat, sanknew, sankedit, sankdelete, khsalenew, khsaleedit, khsaledelete, advnew, advedit, advdelete, ratibnew, ratibedit, ratibdelete, morningFrom, morningTo, eveningFrom, eveningTo,autosnf,mhsnf,gaysnf,summ,ekun) => {
        setInput(prevInput => ({
            ...prevInput,
            sr: sr, name: name, address: address, mobno: mobno, password: password, usertype: usertype, mhsnf: mhsnf,gaysnf: gaysnf
        }));

        setLiterauto(weight);
        setFatauto(fat);

        setSanknew(sanknew);
        setSankedit(sankedit);
        setSankdelete(sankdelete);

        setkhSalenew(khsalenew);
        setKhsaleedit(khsaleedit);
        setKhsaledelete(khsaledelete);

        setAdvnew(advnew);
        setAdvedit(advedit);
        setAdvdelete(advdelete);

        setRatibnew(ratibnew);
        setRatibedit(ratibedit);
        setRatibdelete(ratibdelete);

        setMorningFrom(morningFrom);
        setMorningTo(morningTo);
        setEveningFrom(eveningFrom);
        setEveningTo(eveningTo);

        setAutosnf(autosnf);
        setSumm(summ);
        setEkun(ekun);

        setNewedit("edit");
        setSredit(sr);
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };


    return (
        <div className="khadyamain">
            <div className="firstrowkhadya">
                <img onClick={props.visibility} className='back' src={back} alt="" />
                <div className="colmainkhadya">
                    <div className="firstrow2">
                        <div className="rowsank">
                            <div className="mb-2">
                                <span className='subheadsank'>मोबाईल यूजर आयडी</span>
                                <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="number" className={newedit === "new" ? "form-control inputfontkh" : "form-control inputfontkh editmode"} autoComplete='off' id="sr" name="sr" value={input.sr} onChange={handleChange} ></input>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='linekh' />
            <div className="row1kh">


            </div>
            <div className="row1mobile">
                <div className="mb-2">
                    <span className='subheadsank'>यूज़र चे नाव</span>
                    <input type="text" onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="name" name="name" value={input.name} onChange={handleChange} ></input>
                </div>

                <div className="mb-2">
                    <span className='subheadsank'>यूजर प्रकार</span>
                    <select onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} list="encodings" autoComplete='off' className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} id="usertype" name="usertype" value={input.usertype} onChange={handleChange}>
                        <option key={0} value="प्रकार निवडा">प्रकार निवडा</option>
                        <option key={1} value="Regular">Regular</option>
                        <option key={2} value="Owner">Owner</option>
                    </select>
                </div>

                <div hidden className="mb-2">
                    <span className='subheadsank'>मोबाइल</span>
                    <input type="text" className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="mobno" name="mobno" value={input.mobno} onChange={handleChange} ></input>
                </div>

                <div hidden className="mb-2">
                    <span className='subheadsank'>पत्ता</span>
                    <input type="text" className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="address" name="address" value={input.address} onChange={handleChange} ></input>
                </div>

                <div className="mb-2">
                    <span className='subheadsank'>पासवर्ड</span>
                    <input type="text" disabled={newedit === 'new' ? false : false} onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="password" name="password" value={input.password} onChange={handleChange} ></input>
                </div>



            </div>
            <div className='linekh' />

            <div className="row1kh">
                <div className="mb-2">
                    <button onClick={saveratib} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[3] = ref)} type="submit" className="btn btn-primary mx-1 sanksmall">
                        {newedit === "new" ?
                            "सेव्ह" :
                            "अपडेट"
                        }
                    </button>
                    <button type="submit" className="btn btn-primary mx-1 sanksmall">रिपोर्ट</button>
                </div>
            </div>
            <div className="rowsettings">
                <div class="containermobileuser">
                    <table id="myTablemobileuser" className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='thsmall' scope="col">आयडी.</th>
                                <th className='thsmall' scope="col">यूजर नाव</th>
                                <th className='thsmall' scope="col">यूजर प्रकार</th>
                                <th className='thsmall' scope="col">पासवर्ड</th>
                                <th className='thsmall' scope="col">एक्शन</th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.length > 0 ? tableFilter.map((user, index) =>
                                <tr key={index}>
                                    <td className='thsmall'>{user.kno}</td>
                                    <td className='thsmall'>{user.name}</td>
                                    <td className='thsmall'>{user.usertype}</td>
                                    <td className='thsmall'>{user.password}</td>
                                    {/* <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.kno, user.name, user.khname, user.quin, user.rate, user.amt) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.sr) }} v className="icsank" /></td> */}
                                </tr>
                            )
                                :
                                DataSource.map((user, index) =>
                                    <tr key={index}>
                                        <td className='thsmall'>{user.kno}</td>
                                        <td className='thsmall'>{user.name}</td>
                                        <td className='thsmall'>{user.usertype}</td>
                                        <td className='thsmall'>{user.password}</td>
                                        <td><UilEditAlt onClick={() => { handleedit(user.kno, user.name, user.address, user.mobno, user.password, user.usertype, user.weight, user.fat, user.sanknew, user.sankedit, user.sankdelete, user.khsalenew, user.khsaleedit, user.khsaledelete, user.advnew, user.advedit, user.advdelete, user.ratibnew, user.ratibedit, user.ratibdelete, user.morningFrom, user.morningTo, user.eveningFrom, user.eveningTo,user.autosnf,user.mhsnf,user.gaysnf,user.summ,user.ekun) }} className="icsank" /><UilTrash className="icsank" /></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                <div class="containermobileuser">
                    <div className="row1mobile">
                        <div className="mobileverti">
                            <div className="settingsboxselected">
                                <img className='boximgmobileuser' src={milkcan} alt="" />
                                <span className='subheadmobileuser'>संकलन</span>
                            </div>
                            <div className="line"></div>

                            <div className="row1mobile">
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>नवीन संकलन</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={sanknew === 'चालू'} onChange={handleTogglesanknew} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>संकलन बदल</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={sankedit === 'चालू'} onChange={handleTogglesankedit} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>संकलन डिलीट</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={sankdelete === 'चालू'} onChange={handleTogglesankdelete} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="line"></div>

                            <div className="settingsboxselected">
                                <img className='boximgmobileuser' src={khadya} alt="" />
                                <span className='subheadmobileuser'>खाद्य विक्री</span>
                            </div>


                            <div className="row1mobile">
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>नवीन खाद्य विक्री</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={khsalenew === 'चालू'} onChange={handleTogglekhsalenew} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>खाद्य विक्री बदल</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={khsaleedit === 'चालू'} onChange={handleTogglekhsaleedit} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>खाद्य विक्री डिलीट</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={khsaledelete === 'चालू'} onChange={handleTogglekhsaledelete} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="line"></div>

                            <div className="settingsboxselected">
                                <img className='boximgmobileuser' src={cash} alt="" />
                                <span className='subheadmobileuser'>एड्व्हांस</span>
                            </div>


                            <div className="row1mobile">
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>नवीन एड्व्हांस</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={advnew === 'चालू'} onChange={handleToggleadvnew} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>एड्व्हांस बदल</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={advedit === 'चालू'} onChange={handleToggleadvedit} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>एड्व्हांस डिलीट</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={advdelete === 'चालू'} onChange={handleToggleadvdelete} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="line"></div>
                            <div className="settingsboxselected">
                                <img className='boximgmobileuser' src={farmer} alt="" />
                                <span className='subheadmobileuser'>रतीब</span>
                            </div>


                            <div className="row1mobile">
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>नवीन रतीब</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={ratibnew === 'चालू'} onChange={handleToggleratibnew} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>रतीब बदल</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={ratibedit === 'चालू'} onChange={handleToggleratibedit} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>रतीब डिलीट</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={ratibdelete === 'चालू'} onChange={handleToggleratibdelete} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="line"></div>

                            <div className="settingsboxselected">
                                <img className='boximgmobileuser' src={weight} alt="" />
                                <span className='subheadmobileuser'>ऑटोमायझेशन</span>
                            </div>


                            <div className="row1mobile">
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>लिटर</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={literauto === 'चालू'} onChange={handleToggle} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>फॅट</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={fatauto === 'चालू'} onChange={handleTogglefat} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>

                            <div className="settingsboxselected">
                                <img className='boximgmobileuser' src={weight} alt="" />
                                <span className='subheadmobileuser'>एकूण विभाग</span>
                            </div>


                            <div className="row1mobile">
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>एकूण विभाग</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={ekun === 'चालू'} onChange={handleToggleekun} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                              
                            </div>

                            <div className="line"></div>

                            <div className="settingsboxselected">
                                <img className='boximgmobileuser' src={weight} alt="" />
                                <span className='subheadmobileuser'>ऑटो SNF</span>
                            </div>


                            <div className="row1mobile">
                                <div className="autobox">
                                    <span className='subheadmobileuser2'>ऑटो SNF</span>
                                    <label className="switch">
                                        <input type="checkbox" checked={autosnf === 'चालू'} onChange={handleToggleautosnf} />
                                        <span className="slider round"></span>
                                    </label>


                                    <div className="rowmobileuser">
                                        <div className="autobox">
                                            <span className='subheadmobileuser2'>म्हैस SNF</span>
                                            <div className="mb-2">
                                                <input disabled={autosnf === "बंद"} type="text" autoComplete='off' id="mhsnf" name="mhsnf" value={input.mhsnf} onChange={handleChange} ></input>
                                            </div>
                                        </div>
                                        <div className="autobox">
                                            <span className='subheadmobileuser2'>गाय SNF</span>
                                            <div className="mb-2">
                                                <input disabled={autosnf === "बंद"} type="text" autoComplete='off' id="gaysnf" name="gaysnf" value={input.gaysnf} onChange={handleChange} ></input>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="settingsboxselected">
                                <img className='boximgmobileuser' src={time} alt="" />
                                <span className='subheadmobileuser'>शिफ्ट वेळ</span>
                            </div>


                            <div className="row1mobile3">
                                <div className="autoboxtime">
                                    <span className='subheadmobileuser2'>सकाळ शिफ्ट</span>

                                    <div className="row1mobile2">
                                        <input
                                            type="time"
                                            id='timefrom'
                                            value={morningFrom}
                                            onChange={handleTimeChange(setMorningFrom)}
                                        />

                                        <span className='subheadmobileuser2'>ते</span>

                                        <input
                                            type="time"
                                            id='timeto'
                                            value={morningTo}
                                            onChange={handleTimeChange(setMorningTo)}
                                        />
                                    </div>
                                </div>

                                <span className='subheadmobileuser2'>|</span>

                                <div className="autoboxtime">
                                    <span className='subheadmobileuser2'>संध्याकाळ शिफ्ट</span>

                                    <div className="row1mobile2">
                                        <input
                                            type="time"
                                            id='evntimefrom'
                                            value={eveningFrom}
                                            onChange={handleTimeChange(setEveningFrom)}
                                        />

                                        <span className='subheadmobileuser2'>ते</span>

                                        <input
                                            type="time"
                                            id='evntimeto'
                                            value={eveningTo}
                                            onChange={handleTimeChange(setEveningTo)}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Mobileuser

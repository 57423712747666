import React, { useContext, useEffect, useState } from 'react'
import './Login.css'
import Cowsmall from "../../imgs/cowsmall.gif";
import Grass from "../../imgs/grass.png";
import Dairylogo from "../../imgs/dairylogo.png";
import bcrypt from 'bcryptjs'
import axios from 'axios';
import Phone from "../../imgs/next.png";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Globalcontext from '../../Context/Globalcontext';
const Login = (props) => {

    const [input, setInput] = useState({ contact: "", password: "" });
    const navigate = useNavigate();
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [DataSource, setDataSource] = useState([]);
    const { custlist, setCustlist } = useContext(Globalcontext);

    const handleChange = (event) => {
        const name = event.target.name;
        const valuecurrent = event.target.value;

        setInput(value => ({ ...value, [name]: valuecurrent }))
    }

    async function getmykendra() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getmykendra2.php', { mainuser: input.contact, mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {

                if (response.data.length > 0) {
                    setDataSource(response.data);
                }
                else {
                  
                    localStorage.setItem("status", "true");
                    localStorage.setItem("contact", input.contact);
                    localStorage.setItem("kendra", "Not available");
                    localStorage.setItem("city", "Not available");
                    localStorage.setItem("lastbilldate", "Not available");
                    navigate("/dashboard");
                    notify("Welcome to IDairy");
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (DataSource.length > 0) {
            props.setProgress(50);
            setTimeout(() => {
                props.setProgress(100);
            }, 200);
            {
                DataSource.map((user, index) => (
                  
                    localStorage.setItem("kendra", user.name),
                    localStorage.setItem("city", user.city),
                    localStorage.setItem("kmobno", user.mobno),
                    localStorage.setItem("contact", user.mobno),
                    localStorage.setItem("ksr", user.sr),
                    localStorage.setItem("lastbilldate", user.maxdate))
                )
            
            }
          
            getcustomers();
        }
    }, [DataSource])

  

    async function getcustomers() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getaccoounttemp.php', { mainuser: input.contact, mainuser2: mainuser2 }).then(function sample(response) {
            // console.log("result login cust- ",response.data);
            if (response.data.length > 0) {
              
                localStorage.setItem("custlist", JSON.stringify(response.data));
              
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
            else {
                setInput({ kno: 1 })
            }
            getratetablemh();

        }).catch(err => {
            console.log(err);
        });
    }

    async function getratetablemh() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        let typetemp = "म्हैस";
        let typeid = "1";

        try {
            const response = await axios.post('https://idairy.co.in/dairy/php/cust/getkratevibhag.php', { vibhag: "दरपत्रक विभाग-१", typeid: typeid, type: typetemp, mainuser: input.contact, mainuser2: mainuser2 });

            if (response.data.length > 0) {
                localStorage.setItem("mhrates", JSON.stringify(response.data));
            }

            getratetablegay();


        } catch (error) {
            console.log(error);

        }
    }

    async function getratetablegay() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        let typetemp = "गाय";
        let typeid = "2";

        try {
            const response = await axios.post('https://idairy.co.in/dairy/php/cust/getkratevibhag.php', { vibhag: "दरपत्रक विभाग-१", typeid: typeid, type: typetemp, mainuser: input.contact, mainuser2: mainuser2 });

            if (response.data.length > 0) {
                localStorage.setItem("gayrates", JSON.stringify(response.data));
            }
          gettreport();

        } catch (error) {
            console.log(error);
        }
    }

    async function gettreport() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/gettreport.php', { mainuser: input.contact, mainuser2: mainuser2 }).then(function sample(response) {
    
            if (response.data) {

                if (response.data.length > 0) {
                    localStorage.setItem("treport", JSON.stringify(response.data));
                }
            }
          
            getratetablemh9()

        }).catch(err => {
            console.log(err);
        });
    }

  
    async function getratetablemh9() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        let typetemp = "म्हैस";
        let typeid = "1";

        try {
            const response = await axios.post('https://idairy.co.in/dairy/php/cust/getkratevibhag.php', { vibhag: "दरपत्रक विभाग-९ (M)", typeid: typeid, type: typetemp, mainuser: input.contact, mainuser2: mainuser2 });

            if (response.data.length > 0) {
                localStorage.setItem("mhrates9", JSON.stringify(response.data));
            }

            getratetablegay9();

        } catch (error) {
            console.log(error);

        }
    }

    async function getratetablegay9() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        let typetemp = "गाय";
        let typeid = "2";

        try {
            const response = await axios.post('https://idairy.co.in/dairy/php/cust/getkratevibhag.php', { vibhag: "दरपत्रक विभाग-९ (M)", typeid: typeid, type: typetemp, mainuser: input.contact, mainuser2: mainuser2 });
            if (response.data.length > 0) {
                localStorage.setItem("gayrates9", JSON.stringify(response.data));
            }
            // localStorage.setItem("status", "true");
            // localStorage.setItem("contact", input.contact);
            // navigate("/dashboard");
            // notify("Welcome to IDairy");
            localStorage.setItem("status", "true");
            navigate("/dashboard");
            notify("Welcome to IDairy");

        } catch (error) {
            console.log(error);
        }
    }

    const handlelogin = (event) => {
        event.preventDefault();

        var passsend = jsEncode.encode(input.password, 125);

        let rawData = {
            contact: input.contact,
            password: passsend,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)

        axios.post('https://idairy.co.in/dairy/php/cust/login.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            // console.log("Login- ",response.data);
            if (response.data === "Invalid Password!!") {
                props.setProgress(50);
                notifyerror("पासवर्ड/मोबाईल क्रमांक तपासा!");
                localStorage.setItem("status", "true");
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
                setTimeout(() => {
                    props.setProgress(0);
                }, 200);
            }
            else if (response.data.trim() === "Welcome to Account!") {
                props.setIsLoggedIn(true);
                getmyinfo();
            }
            props.setProgress(30);
            setTimeout(() => {
                props.setProgress(100);
            }, 200);
        }).catch(err => {
            console.log(err);
        });
    }

    async function getmyinfo() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getmyinfo.php', { mainuser: input.contact, mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                if (response.data.length > 0) {
                    response.data.map((user, index) => (
                        localStorage.setItem("name", user.dairy),
                        localStorage.setItem("city", user.city),
                        localStorage.setItem("tal", user.tal),
                        localStorage.setItem("dist", user.dist),
                        localStorage.setItem("username", user.username),
                        localStorage.setItem("mobile", user.mobile),
                        localStorage.setItem("status", user.status),
                        localStorage.setItem("vdate", user.vdate),
                        localStorage.setItem("bill10type", user.billtype),
                        localStorage.setItem("plant", user.plant),
                        localStorage.setItem("parent", user.parent),
                        localStorage.setItem("suchana", user.suchana),
                        localStorage.setItem("thevper", user.thevper),
                        localStorage.setItem("dur", user.dur),
                        localStorage.setItem("digri", user.digri)
                    ))
                }
            }

            // console.log("status- ",localStorage.getItem("status"));
            if (localStorage.getItem("status") === "Expired") {
                notify("आपले सॉफ्टवेअर आजच रि-रजिस्ट्रेशन करुन घ्या,कॉल करा : 800-788-9200")
                localStorage.setItem("status", "");
                localStorage.setItem("vdate", "");
            }
            else if (localStorage.getItem("status") === "Activated") {
                localStorage.setItem("contactplant", input.contact);
              
             
                getmykendra();
            }
        }).catch(err => {
            console.log(err);
        });
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <div className='mainbox'>
            <section className="vh-100">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="firstrowlogin">
                                <div className="cowimg">
                                    <img className='leftimg' src={Cowsmall} alt="" />
                                    <img className='grass' src={Grass} alt="" />
                                </div>
                                <div className="secondlogo">
                                    <img className='logologin' src={Dairylogo} alt="" />
                                    <span className='verfont'>Ver 1.7.6</span>
                                </div>
 
                            </div>
                            <div className="card shadow-2-strong">
                                <div className="card-body p-5 text-center">
                                <span className='kharedi'>सॉफ्टवेअर खरेदीसाठी संपर्क - (+91) 800-788-9200</span>
                                    <h3 className="heading">लॉगीन</h3>
                                    <div className="form-outline mb-4">
                                        <label className="form-label" htmlFor="contact">मोबाईल क्रमांक</label>
                                        <input type="text" onChange={handleChange} id="contact" name="contact" className="form-control form-control-lg" />
                                    </div>
                                    <div className="form-outline mb-4">
                                        <label className="password" htmlFor="password">पासवर्ड</label>
                                        <input type="password" onChange={handleChange} id="password" name="password" className="form-control form-control-lg" />
                                    </div>
                                    <button onClick={handlelogin} className="btn btn-primary btn-lg btn-block" type="submit">लॉगीन करा</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    )
}

export default Login

import React, { useContext, useEffect, useRef, useState } from 'react'
import './Ratibvikri.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import cash from "../../imgs/cash.png";
import close from "../../imgs/close.png";
import { Face6TwoTone, FaxTwoTone, Preview } from '@mui/icons-material';
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';
import { compareSync } from 'bcryptjs';
import Ratibjma from '../Ratibjma/Ratibjma';

const Ratibvikri = (props) => {

    const [newedit, setNewedit] = useState("new");
    const [input, setInput] = useState({ date: new Date().toISOString().substr(0, 10), time: "", grtype: "", rno: "", rname: "", type: "", liter: "", rate: "", amt: "", mhrate: "", gayrate: "" });
    const [comm, setComm] = useState({ mhcomm: "", gaycomm: "" });
    const inputRefs = useRef([]);
    const [tcust, setTcust] = useState({ tcust: "", tcompleted: "", tliter: 0, tavg: 0, tmh: 0, mhavg: 0, tgay: 0, gayavg: 0 });
    const [sredit, setSredit] = useState('');
    const [DataSource, setDataSource] = useState([]);
    const { sanksource, setSanksource, setHeadname } = useContext(Globalcontext);
    const [Prevdata, setPrevdata] = useState([]);
    const [kopen, setkopen] = useState(0);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const [Prev, setPrev] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [value, setValue] = useState('');
    const [custdata, setCustdata] = useState([]);
    const { submenu, setsubmenu, newcustsource, setNewcustsource, custlist, setCustlist } = useContext(Globalcontext);
    const [tableFilter2, setTableFilter2] = useState([]);
    const [sendMsg, setSendmsg] = useState(true);
    const [custSank, SetCustsank] = useState(false);
    const [mastermenu, setMastermenu] = useState(true);

    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const refdelete = useRef(null);
    const refdeleteclose = useRef(null);
    const [flag, setFlag] = useState("");

    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    const visibility = () => {
        localStorage.setItem("glcustsr", "");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setsubmenu("");
        setHeadname("दैनंदिन व्यवहार");
    }

    const openratibjma = (flag) => {
        setsubmenu("ratibjma");
        setHeadname("रतीब जमा");
     
    };
    useEffect(() => {
        if (expanded === true) {
            if (flag === "total") {
                getcustlist(flag);
            }
        }
    }, [expanded])

    async function getcustlist(flag) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getcustlisttemp.php', { flag: flag, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data.length > 0) {
                setCustdata(response.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }


    const handleedit = (sr, date, time, grtype, rno, rname, type, liter, rate, amt) => {

        setNewedit("edit");

        setInput({ date: date, time: time, grtype: grtype, rno: rno, rname: rname, type: type, liter: liter, rate: rate, amt: amt })

        setSredit(sr);

        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }

    async function getratibcustomer() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getratibcustomer.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                //console.log(response.data);
                localStorage.setItem("sanghlist", JSON.stringify(response.data));
            }
            else {
                setInput({ rno: 1 })
            }

        }).catch(err => {
            console.log(err);
        });
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

    }



    const handleChangefs = e => {
        const { name, value } = e.target;

        if (value === "") {
            setInput(prevInput => ({
                ...prevInput,
                [name]: value
            }));
        } else if (/^\d*(\.\d{0,1})?$/.test(value)) {
            setInput(prevInput => ({
                ...prevInput,
                [name]: value
            }));
        }
    };

    useEffect(() => {
        if (localStorage.getItem("sankdate") && localStorage.getItem("sanktime")) {
            setInput(prevInput => ({
                ...prevInput,
                date: localStorage.getItem("sankdate"),
                time: localStorage.getItem("sanktime")
            }))

            localStorage.setItem("sankdate", "");
            localStorage.setItem("sanktime", "")

        }
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
        getratibcustomer();

    }, [])
    useEffect(() => {

        if (input.rno === "") {
            setInput(prevInput => ({
                ...prevInput,
                rno: "",
                rname: "",
                type: "",
                liter: "",
                rate: "",
                amt: ""
            }))
            setSredit("");
            setNewedit("new");
        }
        else {

            if (newedit === 'new') {

                let custlisttemp = "";


                if (input.grtype === "रतीब") {

                   // console.log(localStorage.getItem("sanghlist"));
                    custlisttemp = JSON.parse(localStorage.getItem("sanghlist"));

                    for (let i = 0; i < custlisttemp.length; i++) {
                        setInput(prevInput => ({
                            ...prevInput,
                            rname: '',

                        }));
                        const user = custlisttemp[i];
                        if (user.rno === input.rno) {
                            setInput(prevInput => ({
                                ...prevInput,
                                rname: user.rname,
                                mhrate: user.mhrate,
                                gayrate: user.gayrate

                            }));

                            break; // Exit the loop once the condition is met
                        }
                    }

                }
                else if (input.grtype === "सभासद") {

                    custlisttemp = JSON.parse(localStorage.getItem("custlist"));

                    for (let i = 0; i < custlisttemp.length; i++) {
                        setInput(prevInput => ({
                            ...prevInput,
                            rname: '',

                        }));
                        const user = custlisttemp[i];
                        if (user.kno === input.rno) {
                            setInput(prevInput => ({
                                ...prevInput,
                                rname: user.cname,
                                mhrate: user.mhrate,
                                gayrate: user.gayrate

                            }));

                            break; // Exit the loop once the condition is met
                        }
                    }
                }


            }
        }
    }, [input.rno])


    const getratefromdata = () => {
        let rates = null;
        if (input.type === "म्हैस") {
            rates = JSON.parse(localStorage.getItem("mhrates"));
        }
        else if (input.type === "गाय") {
            rates = JSON.parse(localStorage.getItem("gayrates"));
        }

        for (let i = 0; i < rates.length; i++) {
            const user = rates[i];
            if (user.fat === input.fat && user.snf === input.snf) {
                setInput(prevInput => ({
                    ...prevInput,
                    rate: user.rate,
                    amt: parseFloat(parseFloat(user.rate) * parseFloat(input.liter)).toFixed(2)
                }))
                break; // Exit the loop once the condition is met
            }
        }
    }
    useEffect(() => {
        if (newedit === "new") {
            if (input.type !== "प्रकार निवडा") {
                setInput(prevInput => ({
                    ...prevInput,
                    rate: "",
                    amt: "",
                    type: input.type
                }))
            }
            if (input.type === "म्हैस" && newedit === "new") {
                setInput(prevInput => ({
                    ...prevInput,
                    rate: input.mhrate,
                }))
            }
            else if (input.type === "गाय" && newedit === "new") {
                setInput(prevInput => ({
                    ...prevInput,
                    rate: input.gayrate,
                }))
            }
        }

    }, [input.type])

    useEffect(() => {
        if (input.liter === "") {
            setInput(prevInput => ({
                ...prevInput,
                amt: "0",
                comm: "0"
            }))
        }
        else if (input.liter !== "") {


            if (input.type === "म्हैस") {
                if (comm.mhcomm !== "") {
                    setInput(prevInput => ({
                        ...prevInput,
                        comm: parseFloat(parseFloat(comm.mhcomm) * parseFloat(input.liter)).toFixed(2)
                    }))
                }

            }
            else if (input.type === "गाय") {
                if (comm.gaycomm !== "") {
                    setInput(prevInput => ({
                        ...prevInput,
                        comm: parseFloat(parseFloat(comm.gaycomm) * parseFloat(input.liter)).toFixed(2)
                    }))
                }

            }

            if (input.rate !== "") {
                setInput(prevInput => ({
                    ...prevInput,
                    amt: parseFloat(parseFloat(input.rate) * parseFloat(input.liter)).toFixed(2),
                }))
            }
        }

    }, [input.liter])

    useEffect(() => {
        setExpanded(false);
        if (input.time) {
            if (input.date) {
                getsanghvikri();
            }
        }
        else {
            setTcust({ tcust: "", tcompleted: "" });
        }
    }, [input.time])

    useEffect(() => {
        if (input.date && input.time) {
            getsanghvikri();
        }

        const selectedDate = new Date(input.date);

        // Get the current date
        const currentDate = new Date();

        // Check if the selected date is equal to the current date
        const isToday = selectedDate.toDateString() === currentDate.toDateString();

        // Set the sendMsg state based on whether it's today or not
        setSendmsg(isToday);

        setPrev([]);
        setInput(prevInput => ({
            ...prevInput,
            time: "वेळ निवडा"
        }))
        setTcust({ tcust: "", tcompleted: "", tliter: 0, tavg: 0, tmh: 0, mhavg: 0, tgay: 0, gayavg: 0 });
    }, [input.date])

    async function getoldentry() {
        DataSource
            .filter(data => data.rno === input.rno && data.type === input.type && data.grtype === input.grtype)
            .map((user, index) => (
                // handleedit(user.sr, user.date, user.time, user.sno, user.sname, user.type, user.liter, user.fat, user.snf, user.rate, user.amt)
                handleedit(user.sr, user.date, user.time, user.grtype, user.rno, user.grtype === 'रतीब' ? user.rname : user.grtype === "सभासद" ? user.sname : '', user.type, user.liter, user.rate, user.amt)
            ));

        //getprevfatsnf();
        getprevfatsnf2()
    }

    const getprevfatsnf2 = () => {
        // console.log(Prevdata);
        for (let i = 0; i < Prevdata.length; i++) {
            const user = Prevdata[i];
            if (user.kno === input.rno && user.type === input.type && user.time === input.time) {
                //  console.log("in");
                setPrev((prevInputSettings) => ({
                    ...prevInputSettings,
                    liter: user.liter,
                    fat: user.fat,
                    snf: user.snf,
                }));
                break; // Exit the loop once the condition is met
            }
        }
    }

    // useEffect(() => {
    //   console.log(Prev.liter)
    // }, [Prev])


    async function getsanghvikri() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getratibvikri.php', { date: input.date, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                setDataSource(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
            //getprevsanklan();

        }).catch(err => {
            console.log(err);
        });
    }

    async function getprevsanklan() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getprevsanklan.php', { date: input.date, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                setPrevdata(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
            else if (event.target.name === "grtype") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    grtype: '',
                }));
            }
        }


        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा') ||
                (event.target.name === 'time' && event.target.value === 'वेळ निवडा') ||
                (event.target.name === 'grtype' && event.target.value === 'प्रकार निवडा')
            ))
        )) {

            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            if (currentIndex === 3) {
                if (input.rname !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            event.preventDefault();
            saveratibvikri();
            if (custSank) {
                if (inputRefs.current[1]) {
                    inputRefs.current[1].focus();
                }
            }
            else {
                let nextIndex = null;
                if (currentIndex === 0) {
                    if (input.rname !== "") {
                        nextIndex = currentIndex + 1;
                    }
                    else {
                        nextIndex = currentIndex;
                    }
                }
                else {
                    nextIndex = currentIndex + 1;
                }
                if (inputRefs.current[nextIndex]) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
        else if (event.key === "Backspace") {
            if (event.target.tagName === "BUTTON") {
                if (inputRefs.current[5]) {
                    inputRefs.current[5].focus();
                }
            }
        }
    }

    async function getcustdata(kno) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        setTimeout(() => {
            props.setProgress(70);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getcustomer.php', { kno: kno, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data.length > 0) {
                response.data.map((user, index) =>
                (
                    user.type !== "दोन्ही" ?
                        setInput(prevInput => ({
                            ...prevInput,
                            name: user.cname,
                            type: user.type
                        }))
                        :
                        setInput(prevInput => ({
                            ...prevInput,
                            name: user.cname,
                            type: "प्रकार निवडा"
                        }))
                )
                )
            }
            setTimeout(() => {
                props.setProgress(100);
            }, 200);
        }).catch(err => {
            console.log(err);
        });
    }

    async function getratesdf(fat, snf, type) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let typeid = null;
        if (input.type === "म्हैस") {
            typeid = "1";
        }
        else {
            typeid = "2";
        }
        axios.post('https://idairy.co.in/dairy/php/cust/getrate.php', { typeid: typeid, fat: fat, snf: snf, type: type, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            //console.log(response.data);
            if (response.data) {
                setInput(prevInput => ({
                    ...prevInput,
                    rate: response.data,
                    amt: parseFloat(parseFloat(response.data) * parseFloat(input.liter)).toFixed(2)
                }))
                // if (inputRefs.current[4]) {
                //   inputRefs.current[4].focus();
                // }
            }

        }).catch(err => {
            console.log(err);
        });
    }

    async function getprevfatsnf() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let typeid = null;
        if (input.type === "म्हैस") {
            typeid = "1";
        }
        else {
            typeid = "2";
        }
        axios.post('https://idairy.co.in/dairy/php/cust/getprev.php', { kno: input.rno, typeid: typeid, type: input.type, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2, date: input.date }).then(function sample(response) {
            if (response.data) {
                //  console.log(response.data);
                setPrev(response.data);
            }


        }).catch(err => {
            console.log(err);
        });
    }

    async function deletesanklan(sr, date, time, kno, type, grtype) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/deleteratibvikri.php', { sr: sr, date: date, time: time, kno: kno, type: type, grtype: grtype, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                notify(response.data);
                setInput(prevInput => ({
                    ...prevInput,
                    kno: "",
                    sname: "",
                    type: "",
                    liter: "",
                    fat: "",
                    snf: "",
                    rate: "",
                    amt: ""
                }))
                setSredit("");
                setNewedit("new");
                //getsanklan();
                removeentry(date, time, kno, type, grtype);
            }

        }).catch(err => {
            console.log(err);
        });
    }

    async function sendnotification(date, time, liter, fat, snf, rate, amt) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            date: date,
            time: time,
            liter: liter,
            fat: fat,
            snf: snf,
            rate: rate,
            amt: amt,
            kendra: localStorage.getItem("kendra"),
            topic: localStorage.getItem("contact") + input.rno,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }

        axios.post('https://idairy.co.in/dairy/php/cust/sendnoti.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            notify(response.data);
        }).catch(err => {
            console.log(err);
        });

    }

    const saveratibvikri = () => {

        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.date === "" || input.time === "वेळ निवडा" || input.grtype === "प्रकार निवडा" || input.time === "" || input.rno === "" || input.rname === "" || input.type === "" || input.liter === "" || input.rate === "" || input.amt === "") {
            notifyerror("अपूर्ण एंट्री माहिती!");
            return;
        }
        let rawData = {
            date: input.date,
            time: input.time,
            grtype: input.grtype,
            rno: input.rno,
            type: input.type,
            liter: input.liter,
            rate: input.rate,
            amt: input.amt,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            flag: newedit,
            sredit: sredit,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/saveratibvikri.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (response.data.trim() === "Successfully saved.".trim()) {
                if (sendMsg === true) {
                    //  sendnotification(input.date, input.time, input.liter, input.fat, input.snf, input.rate, input.amt);
                }

                if (custSank) {
                    notify("एंट्री सेव्ह झाली!");
                    setNewedit("new");
                    insertnewentry();
                    setPrev([]);
                    setInput(prevInput => ({
                        ...prevInput,
                        time: "",
                        grtype: "",
                        type: "",
                        liter: "",
                        fat: "",
                        snf: "",
                        comm: "",
                        rate: "",
                        amt: ""
                    }))
                }
                else {
                    notify("एंट्री सेव्ह झाली!");
                    setSredit("");
                    setNewedit("new");
                    // getsanklan();
                    insertnewentry();
                    setPrev([]);
                    setInput(prevInput => ({
                        ...prevInput,
                        rno: "",
                        rname: "",
                        type: "",
                        liter: "",
                        rate: "",
                        amt: ""
                    }))
                }
            }
            else if (response.data.trim() === "Successfully updated.".trim()) {
                notify("अपडेट झाले!")
                updateentry();
                setSredit("");
                setNewedit("new");
                setPrev([]);
                setInput(prevInput => ({
                    ...prevInput,
                    rno: "",
                    rname: "",
                    type: "",
                    liter: "",
                    rate: "",
                    amt: ""
                }))
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const insertnewentry = () => {
        // Create a new item object
        const newItem = {
            sr: "new", // Replace with the appropriate value
            date: input.date, // Replace with the appropriate value
            time: input.time, // Replace with the appropriate value
            grtype: input.grtype, // Replace with the appropriate value
            rno: input.rno, // Replace with the appropriate value
            type: input.type, // Replace with the appropriate value
            liter: input.liter, // Replace with the appropriate value
            rate: input.rate, // Replace with the appropriate value
            amt: input.amt, // Replace with the appropriate value
            mainuser: localStorage.getItem("contact"),// Replace with the appropriate value
            rname: input.rname, // Replace with the appropriate value
            sname: input.rname,
            tcustomer: "0" // Replace with the appropriate value
        };

        // Update the state to include the new item
        //setDataSource((prevDataSource) => [...prevDataSource, newItem]);
        setDataSource((prevDataSource) => [newItem, ...prevDataSource]);
    }

    useEffect(() => {

        const totals = DataSource.reduce(
            (accumulator, user) => {
                const liter = parseFloat(user.liter);
                const fat = parseFloat(user.fat);

                accumulator.totalLiter += liter;
                accumulator.tfat += fat;

                if (user.type === "म्हैस") {
                    accumulator.totalLitermh += liter;
                    accumulator.tfatmh += fat;
                    accumulator.tentrymh += 1;
                } else if (user.type === "गाय") {
                    accumulator.totalLitergay += liter;
                    accumulator.tfatgay += fat;
                    accumulator.tentrygay += 1;
                }
                return accumulator;
            },
            {
                totalLiter: 0,
                tfat: 0,
                totalLitermh: 0,
                tfatmh: 0,
                tentrymh: 0,
                totalLitergay: 0,
                tfatgay: 0,
                tentrygay: 0,
            }
        );

        const {
            totalLiter,
            tfat,
            totalLitermh,
            tfatmh,
            tentrymh,
            tfatgay,
            tentrygay,
            totalLitergay,
        } = totals;

        DataSource.map((user, index) =>
        (
            setTcust(prevInput => ({
                ...prevInput,
                tliter: totalLiter.toFixed(2),
                tavg: parseFloat(parseFloat(tfat) / parseFloat(DataSource.length)).toFixed(1),
                tmh: totalLitermh.toFixed(2),
                mhavg: parseFloat(parseFloat(tfatmh) / parseFloat(tentrymh)).toFixed(1),
                tgay: totalLitergay.toFixed(2),
                gayavg: parseFloat(parseFloat(tfatgay) / parseFloat(tentrygay)).toFixed(1),

            }))
        ))
        setTcust(prevInput => ({
            ...prevInput,
            tcompleted: DataSource.length,
        }))
    }, [DataSource])

    const updateentry = () => {
        // Create a new item object
        const modifiedItem = {
            date: input.date, // Replace with the appropriate value
            time: input.time, // Replace with the appropriate value
            grtype: input.grtype, // Replace with the appropriate value
            rno: input.rno, // Replace with the appropriate value
            type: input.type, // Replace with the appropriate value
            liter: input.liter, // Replace with the appropriate value
            rate: input.rate, // Replace with the appropriate value
            amt: input.amt, // Replace with the appropriate value
            mainuser: localStorage.getItem("contact"),// Replace with the appropriate value
            rname: input.rname, // Replace with the appropriate value
            tcustomer: "0" // Replace with the appropriate value
        };

        const updatedDataSource = DataSource.map((item) => {
            if (item.rno === input.rno && item.type === input.type && item.grtype === input.grtype) {
                // If kno and type match the user input, update the item
                return { ...item, ...modifiedItem };
            } else {
                // If no match, return the item as is
                return item;
            }
        });

        // Update the state with the modified DataSource
        setDataSource(updatedDataSource);

    }

    const removeentry = (date, time, kno, type, grtype) => {
        // Create a new item object
        const shouldRemoveItem = (item) => {
            return item.rno === kno && item.type === type && item.grtype === grtype;
        };

        // Remove items from the DataSource based on user input criteria (kno and type)

        const updatedDataSource = DataSource.filter((item) => !shouldRemoveItem(item));

        // Update the state with the modified DataSource
        setDataSource(updatedDataSource);

    }

    const handleFocus = () => {
        if (newedit === "new") {
            if (input.type === 'म्हैस') {
                setInput({ ...input, snf: '9' });
            } else if (input.type === 'गाय') {
                setInput({ ...input, snf: '8.5' });
            }
        }
    };

    const handleBlur = () => {
        if (input.fat !== "") {
            setInput(prevInput => ({
                ...prevInput,
                fat: parseFloat(input.fat).toFixed(1),
                snf: parseFloat(input.snf).toFixed(1)  // Convert to one decimal place
            }));
        }
    };

    const offexp = () => {
        setExpanded(false);
    }

    async function opensr(sr) {
        localStorage.setItem("sankdate", input.date);
        localStorage.setItem("sanktime", input.time)
        localStorage.setItem("glcustsr", sr);
        navigate("/Dashboard");
        setsubmenu("customer");
        setNewcustsource("sank");
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    const handleChangeactive = (event) => {
        setSendmsg(!sendMsg);
    }

    const handleCustsank = (event) => {
        SetCustsank(!custSank);
    }

    useEffect(() => {
        setInput({ date: new Date().toISOString().substr(0, 10), time: "", kno: "", name: "", type: "", liter: "", fat: "", snf: "", rate: "", amt: "" });
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, [custSank])

    return (
        <div className="sanklanmain">
            <div className="firstrowsank">
                <img onClick={props.visibility} className='back' src={back} alt="" />
                <div className="colmain">
                    <div className="firstrow2">
                        <div className="rowsankratib">
                            <div className="rowsank">
                                <div className="mb-2">
                                    <span onClick={sendnotification} className='subheadsank'>दिनांक</span>
                                    <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="date" name="date" value={input.date} onChange={handleChange} ></input>
                                </div>
                                <div className="mb-2">
                                    <span className='subheadsank'>वेळ</span>
                                    <select onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} list="encodings" autoComplete='off' className={newedit === "new" ? "form-control" : "form-control editmode"} id="time" name="time" value={input.time} onChange={handleChange}>
                                        <option key={0} value="वेळ निवडा">वेळ निवडा</option>
                                        <option key={1} value="सकाळ">सकाळ</option>
                                        <option key={2} value="संध्याकाळ">संध्याकाळ</option>
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <span className='subheadsank'>ग्राहक प्रकार</span>
                                    <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} list="encodings" autoComplete='off' className={newedit === "new" ? "form-control" : "form-control editmode"} id="grtype" name="grtype" value={input.grtype} onChange={handleChange}>
                                        <option key={0} value="प्रकार निवडा">प्रकार निवडा</option>
                                        <option key={1} value="रतीब">रतीब</option>
                                    </select>
                                </div>
                            </div>

                            <div onClick={openratibjma} className="rightratib">

                                <img className='backratib' src={cash} alt="" />
                                <span className='subheadsankratib'>रतीब जमा</span>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='linesank' />

            <div className="row1sank">


                <div className="row1sank">
                    <div className="mb-2">
                        <span className='subheadsank'>ग्राहक क्रमांक</span>
                        <input onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="rno" name="rno" value={input.rno} onChange={handleChange} ></input>
                    </div>
                    <div className="mb-2">
                        <span className='subheadsank'>ग्राहक नाव</span>
                        <input type="text" disabled className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="rname" name="rname" value={input.rname} onChange={handleChange} ></input>
                    </div>
                </div>

                <div className="mb-2">
                    <span className='subheadsank'>प्रकार</span>
                    <select onBlur={getoldentry} onKeyDown={e => handleEnterKey(e, 4)} ref={ref => (inputRefs.current[4] = ref)} className={newedit === "new" ? "form-control" : "form-control editmode"} id="type" name="type" value={input.type} onChange={handleChange} >
                        <option key={0} value="प्रकार निवडा">प्रकार निवडा</option>
                        <option key={1} value="म्हैस">म्हैस</option>
                        <option key={2} value="गाय">गाय</option>
                    </select>
                </div>
                <div className="mb-2">
                    <span className='subheadsank'>लिटर <span className='prev'>{Prev.liter ? " (" + Prev.liter + ")" : ''}</span></span>
                    <input onKeyDown={e => handleEnterKey(e, 5)} ref={ref => (inputRefs.current[5] = ref)} type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="liter" name="liter" value={input.liter} onChange={handleChange} ></input>
                </div>
                <div className="mb-2">
                    <span className='subheadsank'>दर</span>
                    <input type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="rate" name="rate" value={input.rate} onChange={handleChange} ></input>
                </div>
                <div className="mb-2">
                    <span className='subheadsank'>रक्कम</span>
                    <input type="number" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="amt" name="amt" value={parseFloat(input.amt).toFixed(2)} onChange={handleChange} ></input>
                </div>
                <div className="mb-2">
                    <button onClick={saveratibvikri} onKeyDown={e => handleEnterKeybutton(e, 1)} ref={ref => (inputRefs.current[6] = ref)} type="submit" id='save' name='save' className="btn btn-primary mx-1 sanksmall">
                        {newedit === "new" ?
                            "सेव्ह" :
                            "अपडेट"
                        }

                    </button>
                </div>
                <div className="mb-2 lastimg">

                </div>
            </div>
            <div className="firstrow2">
                <div className="table-containersank">
                    <table id="myTable" className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='thsmall' scope="col">क्र.</th>
                                <th className='thsmall' scope="col">ग्राहक प्रकार</th>
                                <th className='thsmall' scope="col">ग्राहक क्रमांक</th>
                                <th className='thlarge' scope="col">ग्राहक नाव</th>
                                <th className='thsmall' scope="col">प्रकार</th>
                                <th className='thsmall' scope="col">लिटर</th>
                                <th className='thsmall' scope="col">दर</th>
                                <th className='thsmall' scope="col">रक्कम</th>
                                <th className='thsmall' scope="col">एक्शन</th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.length > 0 ? tableFilter.map((user, index) =>
                                <tr key={index}>
                                    <td className='thsmall'>{index + 1}</td>
                                    <td className='thsmall'>{user.grtype}</td>
                                    <td className='thsmall'>{user.rno}</td>
                                    <td className='thsmall'>{user.rname}</td>
                                    <td className='thsmall2'>{user.type}</td>
                                    <td className='thsmall'>{user.liter}</td>
                                    <td className='thsmall'>{user.rate}</td>
                                    <td className='thsmall'>{parseFloat(user.amt).toFixed(2)}</td>
                                    <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.time, user.rno, user.rname, user.type, user.liter, user.fat, user.snf, user.comm, user.rate, user.amt, user.mhcomm, user.gaycomm) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.sr, user.date, user.time, user.sno, user.type) }} v className="icsank" /></td>

                                </tr>
                            )
                                :
                                DataSource.map((user, index) =>
                                    <tr key={index}>

                                        <td className='thsmall'>{index + 1}</td>
                                        <td className='thsmall'>{user.grtype}</td>
                                        <td className='thsmall'>{user.rno}</td>
                                        <td className='thsmall'>{user.grtype === "रतीब" ? user.rname : user.grtype === "सभासद" ? user.sname : ''}</td>
                                        <td className='thsmall2'>{user.type}</td>
                                        <td className='thsmall'>{user.liter}</td>
                                        <td className='thsmall'>{user.rate}</td>
                                        <td className='thsmall'>{parseFloat(user.amt).toFixed(2)}</td>
                                        <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.time, user.grtype, user.rno, user.grtype === 'रतीब' ? user.rname : user.grtype === "सभासद" ? user.sname : '', user.type, user.liter, user.rate, user.amt) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.sr, user.date, user.time, user.rno, user.type, user.grtype) }} className="icsank" /></td>

                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                </div>
            </div>
            <div hidden className="tboxbelow">
                {tcust.tliter ? (
                    <span>
                        {tcust.tmh && parseFloat(tcust.tmh) > 0 && (
                            <span>
                                <span className="subheadsank">म्हैस लिटर -</span>
                                <span className="subheadsankgreen"> {tcust.tmh}</span>
                                <span className="subheadsank"> (सरासरी फॅट:</span>{" "}
                                <span className="subheadsank">{tcust.mhavg})</span>
                            </span>
                        )}

                        {tcust.tgay && parseFloat(tcust.tgay) > 0 && (
                            <span>
                                <span className="subheadsank"> | गाय लिटर -</span>
                                <span className="subheadsankgreen"> {tcust.tgay}</span>
                                <span className="subheadsank"> (सरासरी फॅट:</span>{" "}
                                <span className="subheadsank">{tcust.gayavg})</span>
                            </span>
                        )}
                        <span className="subheadsank"> | एकूण लिटर:</span>{" "}
                        <span className="subheadsankgreen">{tcust.tliter}</span>
                        <span className="subheadsank"> (सरासरी फॅट:</span>{" "}
                        <span className="subheadsank">{tcust.tavg})</span>
                    </span>

                ) : (
                    <span className="subheadsankgreen">कोणत्याही एंट्रीज उपलब्ध नाहीत</span>
                )}

            </div>
            <ToastContainer />
        </div >
    )
}

export default Ratibvikri

import React, { useContext, useEffect, useRef, useState } from 'react'
import './Newkendra.css'
import { useLocation, useNavigate } from 'react-router-dom';
import back from "../../imgs/backexam.png";
import user from "../../imgs/userlogo.png";
import location from "../../imgs/location.png";
import mobile from "../../imgs/mobile.png";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UilEditAlt } from '@iconscout/react-unicons'
import { Modal } from "bootstrap";
import Globalcontext from '../../Context/Globalcontext';

const Newkendra = (props) => {

    const refdelete = useRef(null);
    const refdeleteclose = useRef(null);

    const refnewkendra = useRef(null);
    const refnewkedraclose = useRef(null);
    const [showreport, setShowreport] = useState(true);
    const [firstornot, setfirstornot] = useState("Yes");
    const [showModal, setShowModal] = useState(false);
    const { submenu, setsubmenu, newcustsource, setNewcustsource, kendrasource, setKendrasource } = useContext(Globalcontext);
    const navigate = useNavigate();
    const inputRefs = useRef([]);

    const closeModal = () => {
        setShowModal(false);
    };

    const [imageurl, setImageUrl] = useState();
    const [input, setInput] = useState({ sr: "", cname: "", city: "", mobno: localStorage.getItem("contact"), type: "", status: "चालू", kendracount: "", citynew: "" });
    const [mastermenu, setMastermenu] = useState(true);
    const [newedit, setNewedit] = useState("new");
    const [value, setValue] = useState('');
    const [DataSource, setDataSource] = useState([]);
    const [DataSource2, setDataSource2] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [type, setType] = useState({});
    const [contactdelete, setContactdelete] = useState({});

    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);

    const clearAll = () => {
        setInput({ sr: "", cname: "", mobno: "", city: "" });
    }

    const handleReport = () => {

        setShowreport(true);
        getkendralist();

    }

    const closereport = () => {
        setShowreport(false);
    }

    useEffect(() => {
        if (showreport) {
            getkendralist();
        }

    }, [showreport])

    async function getkendralist() {

        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getkendra2.php', { mainuser: localStorage.getItem("contactplant"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                setDataSource(response.data);

                response.data.map((user, index) => {
                    if (user.status === "चालू") {
                        localStorage.setItem("contact", user.mobno);
                    }
                });

                setTimeout(() => {
                    props.setProgress(100);
                }, 200);

                // notify(localStorage.getItem("contact"));
                // notify(localStorage.getItem("contactplant"));
            }
        }).catch(err => {
            console.log(err);
        });
    }

    async function getkendracount() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getkendracount.php', { mainuser: localStorage.getItem("contactplant"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
              //  console.log(response.data);
                response.data.map((user, index) => {

                    setInput(prevInput => ({
                        ...prevInput,
                        kendracount: user.count,
                    }));

                    if (localStorage.getItem("plant") === "No") {
                        if (user.count === "1") {
                            setShowreport(false);
                        }
                    }
                    else if (localStorage.getItem("plant") === "Yes") {

                        if (user.count === "1") {
                            if (user.kendracount === "0") {
                                setShowreport(false);
                            }
                            else {
                                refnewkendra.current.click();
                            }
                            return;
                        }
                        else{
                            refnewkendra.current.click();
                        }
                    }
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }


    async function getfirstkendra() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getfirstkendra.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                response.data.map((user, index) => {
                    // Store 'user.sr' in localStorage
                    localStorage.setItem('ksr', user.sr);

                    // Update state with user's name
                    setInput(prevInput => ({
                        ...prevInput,
                        sr: user.sr,
                        cname: user.name
                    }));
                });

                setfirstornot("No");
            }
        }).catch(err => {
            console.log(err);
        });
    }

    async function getfirstkendrasingle() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getfirstkendra.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                response.data.map((user, index) => {
                    setInput(prevInput => ({
                        ...prevInput,
                        sr: user.sr,
                        cname: user.name,
                        city: user.city,
                        mobno: user.mobno,
                        status: user.status
                    }));
                });
                // setShowreport(false);
                setNewedit("edit");

            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handledeleteclose = () => {
        refdeleteclose.current.click();
    }

    const handledelete = (contact) => {

        refdelete.current.click();

        const name = "contact";
        const valuecurrent = contact;

        setContactdelete(value => ({ ...value, [name]: valuecurrent }));
    }

    const deleteAccount = () => {

        axios.post('https://idairy.co.in/iniqtest/cust/deletlator.php', contactdelete).then(function sample(response) {

            if (response.data) {
                if (response.data === "Deleted") {
                    notify("Account Deleted Successfully!");
                    refdeleteclose.current.click();
                }

            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handlenewkendraclose = () => {
        refnewkedraclose.current.click();
    }

    const handlenewkendra = (contact) => {
        if (localStorage.getItem("contact") && localStorage.getItem("contact").includes("-")) {
            notifyerror("कृपया प्रथम मेन केंद्र चालू करा!");
            return;
        }

        getkendracount();
      

    }

    const handlesubmit = async (event) => {

        var mainuser2 = jsEncode.encode("mamapr", 125);
        event.preventDefault();

        let rawData = {
            sredit: input.sr,
            name: input.cname,
            mobno: input.mobno,
            city: input.city,
            status: input.status,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            flag: newedit
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/createkendra2.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            //  console.log(response.data);
            if (response.data === "Successfully saved.") {
                notify("केंद्र तयार झाले!");
                localStorage.setItem('kendra', input.cname);
                localStorage.setItem('city', input.city);
                localStorage.setItem("kmobno", input.mobno);
                props.setKendraValue(localStorage.getItem("kendra") + "," + localStorage.getItem("city"));
                clearAll();
                setNewedit("new");
                getfirstkendrasingle();

            }
            else if (response.data === "Mobile Number Exists") {
                notifyerror("Mobile Number Already Exist!");
                clearAll();
                setNewedit("new");
                getfirstkendrasingle();

            }
            else if (response.data === "Successfully Updated.") {
                notify("केंद्र माहिती अपडेट झाली!");
                if (input.status === "चालू") {
                    notify(input.city + " केंद्र सेट झाले!");
                    localStorage.setItem("contact", input.mobno);
                    localStorage.setItem('kendra', input.cname);
                    localStorage.setItem('city', input.city);
                    localStorage.setItem("kmobno", input.mobno);
                    props.setKendraValue(localStorage.getItem("kendra") + "," + localStorage.getItem("city"));
                    clearAll();
                    setNewedit("new");
                    //  

                    getmyinfo();
                }


            }
            else if (response.data === "Limit Finished!") {
                notifyerror("आपले केंद्र लिमिट संपले आहे.");
                setInput({ cname: "", mobno: "", city: "" });
                setNewedit("new");
                clearAll();
                getfirstkendrasingle();

            }
            else if (response.data === "Incomplete information!") {
                notifyerror("अपूर्ण माहिती!");

            }
        }).catch(err => {
            console.log(err);
        });
    }

    async function getmyinfo() {

        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getmyinfo.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                if (response.data.length > 0) {
                    response.data.map((user, index) => (
                        localStorage.setItem("name", user.dairy),
                        //localStorage.setItem("city", user.city),
                        localStorage.setItem("tal", user.tal),
                        localStorage.setItem("dist", user.dist),
                        localStorage.setItem("username", user.username),
                        localStorage.setItem("mobile", user.mobile),
                        localStorage.setItem("status", user.status),
                        localStorage.setItem("vdate", user.vdate),
                        localStorage.setItem("bill10type", user.billtype),
                        // localStorage.setItem("plant", user.plant),
                        localStorage.setItem("parent", user.parent)
                    ))
                }
            }

            // console.log("status- ",localStorage.getItem("status"));
            if (localStorage.getItem("status") === "Expired") {
                notify("आपले सॉफ्टवेअर आजच रि-रजिस्ट्रेशन करुन घ्या,कॉल करा : 800-788-9200")
                localStorage.setItem("status", "");
                localStorage.setItem("vdate", "");
            }
            else if (localStorage.getItem("status") === "Activated") {
                getmykendra();
            }
        }).catch(err => {
            console.log(err);
        });
    }

    async function getmykendra() {

        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getmykendra2.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {

                if (response.data.length > 0) {

                    setDataSource(response.data);
                    getcustomers();
                }
                else {

                    localStorage.setItem("status", "true");
                    localStorage.setItem("contact", input.contact);
                    localStorage.setItem("kendra", "Not available");
                    localStorage.setItem("city", "Not available");
                    navigate("/dashboard");
                    notify("Welcome to IDairy");
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (DataSource.length > 0) {
            props.setProgress(50);
            setTimeout(() => {
                props.setProgress(100);
            }, 200);
            {
                DataSource.map((user, index) => (
                    localStorage.setItem("kendra", user.name),
                    localStorage.setItem("city", user.city),
                    localStorage.setItem("kmobno", user.mobno),
                    localStorage.setItem("ksr", user.sr))
                )

            }


        }
    }, [DataSource2])


    async function getcustomers() {
        localStorage.setItem("custlist", JSON.stringify([]));
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getaccoounttemp.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            // console.log("result login cust- ",response.data);
            if (response.data.length > 0) {

                localStorage.setItem("custlist", JSON.stringify(response.data));
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
            else {
                setInput({ kno: 1 })
            }

            getratetablemh();

        }).catch(err => {
            console.log(err);
        });
    }

    async function getratetablemh() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        let typetemp = "म्हैस";
        let typeid = "1";

        try {
            const response = await axios.post('https://idairy.co.in/dairy/php/cust/getkratevibhag.php', { vibhag: "दरपत्रक विभाग-१", typeid: typeid, type: typetemp, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 });

            if (response.data.length > 0) {
                localStorage.setItem("mhrates", JSON.stringify(response.data));
            }


            getratetablegay();


        } catch (error) {
            console.log(error);

        }
    }


    async function getratetablegay() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        let typetemp = "गाय";
        let typeid = "2";

        try {
            const response = await axios.post('https://idairy.co.in/dairy/php/cust/getkratevibhag.php', { vibhag: "दरपत्रक विभाग-१", typeid: typeid, type: typetemp, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 });

            if (response.data.length > 0) {
                localStorage.setItem("gayrates", JSON.stringify(response.data));
            }

            getfirstkendrasingle();

            localStorage.setItem("status", "true");

        } catch (error) {
            console.log(error);
        }
    }



    const createnewuserandkendra = async (event) => {
    
        if (input.citynew === undefined || input.citynew === "") {
            notifyerror("गाव (शाखा) भरा!");
            return;
        }

        var mainuser2 = jsEncode.encode("mamapr", 125);
        event.preventDefault();

        let rawData = {
            newmainuser: localStorage.getItem("contact") + "-" + (parseFloat(input.kendracount) + 1),
            kendraname: localStorage.getItem("kendra"),
            kendramobno: localStorage.getItem("contact") + "-" + (parseFloat(input.kendracount) + 1),
            kendracity: input.citynew,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            flag: newedit
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/createuserandkendra.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            if (response.data === "Successfully saved.") {
                notify("नवीन केंद्र तयार झाले!");

                setInput(prevInput => ({
                    ...prevInput,
                    citynew: "",
                }))
                getkendralist();
                handlenewkendraclose();


            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handleedit = (sr, name, mobno, city, status) => {

        setInput({ sr: sr, cname: name, mobno: mobno, city: city, status: status });
        setShowreport(false);
        setNewedit("edit");

    }

    function getRandomNumber() {
        let min = 1000;
        let max = 9999;
        return Math.round(Math.random() * (max - min) + min);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        if (name === "contact") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                valuecurrent = event.target.value;
                setInput(value => ({ ...value, [name]: valuecurrent }))

            }
        }
        else {
            if (name === "img") {
                valuecurrent = event.target.files[0].name;
            }
            else {
                valuecurrent = event.target.value;
            }
            setInput(value => ({ ...value, [name]: valuecurrent }))

        }
    }

    const filteData = (e) => {
        if (e.target.value != "") {
            setValue(e.target.value);
            const filtertable = DataSource.filter(o => Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
            ));

            setTableFilter([...filtertable]);
        }
        else {
            setValue(e.target.value);
            setTableFilter([...DataSource]);
        }
    }

    const handleBack = () => {

        if (kendrasource === "Dashboard") {
            navigate("/Dashboard");
            setNewcustsource("");
            setKendrasource("");
            props.visibility();
        }
        else {
            props.visibility();
        }
    };

    const handleBacktokendra = () => {
        setShowreport(true);
    };


    useEffect(() => {
        getfirstkendrasingle();
        // if(localStorage.getItem("plant") === "Yes")
        // {
        //     getfirstkendra();
        // }
        // else if(localStorage.getItem("plant") === "No")
        // {

        //     getfirstkendrasingle();
        // }

        const modalElement = refdelete.current;
        const modal = new Modal(modalElement);

        const handleModalHide = () => {
            modal.show();
        };

        modalElement.addEventListener("hide.bs.modal", handleModalHide);

        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }

        return () => {
            modalElement.removeEventListener("hide.bs.modal", handleModalHide);
        };
    }, []);

    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            handlesubmit();
            let nextIndex = null;
            if (currentIndex === 0) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        else if (event.key === "Backspace") {
            if (event.target.tagName === "BUTTON") {
                if (inputRefs.current[1]) {
                    inputRefs.current[1].focus();
                }
            }
        }
    }

    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा') ||
                (event.target.name === 'time' && event.target.value === 'वेळ निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };
    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden={true}
                ref={refdelete}
                data-bs-target="#exampleModaldelete"
            >
            </button>

            <div
                className="modal fade"
                id="exampleModaldelete"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static" // Disable clicks outside the modal
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                ref={refdeleteclose}
                                aria-label="Close"
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel">केंद्र डिलीट करायचे?</h4>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            // onClick={handledeleteclose}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={deleteAccount}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden={true}
                ref={refnewkendra}
                data-bs-target="#newkendra"
            >
            </button>

            <div
                className="modal fade"
                id="newkendra"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static" // Disable clicks outside the modal
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                ref={refnewkedraclose}
                                aria-label="Close"
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel">नवीन केंद्र तयार करायचे?</h4>
                        </div>

                        <line />

                        <div className="boxnewkenra">
                            <div className='rownewkenra0top'>
                                <img className='iconnewkenra' src={user} alt="" />
                                <span>{localStorage.getItem("kendra")}</span>
                            </div>
                            <div className='rownewkenra'>
                                <img className='iconnewkenra' src={mobile} alt="" />
                                <span>{localStorage.getItem("contact") + "-" + (parseFloat(input.kendracount) + 1)}</span>
                            </div>
                            <div className='rownewkenra'>
                                <img className='iconnewkenra' src={location} alt="" />
                                <input onKeyDown={e => handleEnterKey(e, 49)} ref={ref => (inputRefs.current[49] = ref)} className={"form-control inputfont"} autoComplete='off' id="citynew" name="citynew" placeholder='गावाचे (शाखा) नाव' value={input.citynew} onChange={handleChange}></input>
                            </div>
                        </div>


                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={handlenewkendraclose}
                            >
                                बाहेर
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onKeyDown={e => handleEnterKey(e, 50)} ref={ref => (inputRefs.current[50] = ref)}
                                onClick={createnewuserandkendra}
                            >
                                होय
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`report ${showreport ? '' : 'hidden'}`}>
                <div className="buttonsreport">
                    <img onClick={handleBack} className='back' src={back} alt="" />
                    <h5 className="modal-title" id="exampleModalLabel">
                        {props.type} केंद्र यादी
                    </h5>

                </div>

                <table id="table-to-xls" className="table table-bordered">
                    <thead>
                        <tr>
                            <th className='thsmall'>केंद्र क्रमांक</th>
                            <th className='thsmall'>केंद्र नाव</th>
                            <th className='thsmall'>मोबाईल</th>
                            <th className='thsmall'>गाव(शाखा)</th>
                            <th className='thsmall'>प्रकार</th>
                            <th className='thsmall'>एक्शन</th>

                        </tr>
                    </thead>
                    <tbody>
                        {value.length > 0 ? tableFilter.map((user, index) =>
                            <tr key={index}>
                                <td className='thsmall'>{index + 1}</td>
                                <td className='thsmall'>{user.name}</td>
                                <td className='thsmall'>{user.mobno}</td>
                                <td className='thsmall'>{user.city}</td>
                                <td className='thsmall'>{user.status === "चालू" ? "सध्या चालू" : "बंद"}</td>
                                <td className='thsmall'><UilEditAlt onClick={() => { handleedit(user.sr, user.name, user.mobno, user.city, user.status) }} className="ic" /></td>
                            </tr>
                        )
                            :
                            DataSource.map((user, index) =>
                                <tr key={index}>
                                    <td className={user.status === "चालू" ? "thsmallgreen" : "thsmall"}>{index + 1 + "-" + user.sr}</td>
                                    <td className={user.status === "चालू" ? "thsmallgreen" : "thsmall"}>{user.name}</td>
                                    <td className={user.status === "चालू" ? "thsmallgreen" : "thsmall"}>{user.mobno}</td>
                                    <td className={user.status === "चालू" ? "thsmallgreen" : "thsmall"}>{user.city}</td>
                                    <td className={user.status === "चालू" ? "thsmallgreen" : "thsmall"}>{user.status === "चालू" ? "सध्या चालू" : "बंद"}</td>
                                    <td className={user.status === "चालू" ? "thsmallgreen" : "thsmall"}><UilEditAlt onClick={() => { handleedit(user.sr, user.name, user.mobno, user.city, user.status) }} className="ic" /></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <div className='row1formcust'>
                    {
                        localStorage.getItem("plant") === "Yes"
                            ?
                            <button onClick={handlenewkendra} type="submit" className="btn btn-primary my-3 mx-1">
                                नवीन केंद्र तयार करा
                            </button>
                            :
                            <button onClick={handlenewkendra} type="submit" className="btn btn-primary my-3 mx-1">
                                केंद्र माहिती भरा
                            </button>

                    }
                </div>

            </div>
            <div className={`backmain ${showreport ? 'hidden' : ''}`}>
                <img onClick={handleBacktokendra} className='back' src={back} alt="" />
                <div className="custbox">
                    <form>
                        <div className="mb-2 kendra">
                            <span className='subhead'>केंद्राचे नाव</span>
                            <input disabled={newedit === "edit" ? localStorage.getItem("plant") === "Yes" : ''} onChange={handleChange} onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="text" className={newedit === "new" ? "form-control" : "form-control editmode"} id="cname" name="cname" value={input.cname} autoComplete='off' />
                        </div>
                        <div className="mb-2">
                            <span className='subhead'>गाव (शाखा)</span>
                            <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="text" className={newedit === "new" ? "form-control" : "form-control editmode"} id="city" name="city" value={input.city} autoComplete='off' />
                        </div>
                        <div className="mb-2">
                            <span className='subhead'>मोबाईल क्रमांक</span>
                            <input disabled onChange={handleChange} onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className={newedit === "new" ? "form-control" : "form-control editmode"} id="mobno" name="mobno" value={input.mobno} autoComplete='off' />
                        </div>
                        <div className="mb-2">
                            <span className='subheadsank'>प्रकार</span>
                            {
                                firstornot === "Yes"
                                    ?
                                    <select disabled={localStorage.getItem("plant") === "No"} onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} value={input.status} name='status' id='status' onChange={handleChange} className={newedit === "new" ? "form-control" : "form-control editmode"} >
                                        <option key={0} value="चालू">चालू</option>
                                        <option key={1} value="बंद">बंद</option>
                                    </select>
                                    :
                                    <select disabled={localStorage.getItem("plant") === "No"} onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} value={input.status} name='status' id='status' onChange={handleChange} className={newedit === "new" ? "form-control" : "form-control editmode"} >
                                        <option key={0} value="चालू">चालू</option>
                                        <option key={1} value="बंद">बंद</option>
                                    </select>
                            }
                        </div>


                        <div className='row1form'>
                            <button onClick={handlesubmit} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[4] = ref)} type="submit" className="btn btn-primary my-3 mx-1">

                                {newedit === "new" ?
                                    "सेव्ह" :
                                    "अपडेट"
                                }

                            </button>

                            {
                                localStorage.getItem("plant") === "Yes"
                                    ?
                                    <button type='button' onClick={handleReport} className="btn btn-primary my-3 mx-1">केंद्र यादी</button>
                                    : ''
                            }


                        </div>

                    </form>
                </div>


            </div>
            <ToastContainer />
        </>
    );
};

export default Newkendra;
